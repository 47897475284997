import { FilterPanelModule } from './../../ca-shared/filter-panel/filter-panel.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSplitModule } from 'angular-split';
import { NgbDatepickerModule, NgbAlertModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConversationRoutingModule } from './conversation-routing.module';
import { ConversationMainComponent } from './conversation-main/conversation-main.component';
import { SharedModule } from '../shared/shared.module';
import { ConversationListToolbarComponent } from './conversation-list-toolbar/conversation-list-toolbar.component';
import { ConversationMixedListComponent } from './mixed/conversation-mixed-list/conversation-mixed-list.component';
import { CallListComponent } from './call/call-list/call-list.component';
import { ChatListComponent } from './chat/chat-list/chat-list.component';
// tslint:disable-next-line: max-line-length
import { ConversationCommonFilterPanelComponent } from './shared/conversation-common-filter-panel/conversation-common-filter-panel.component';
import { CallFilterPanelComponent } from './call/call-filter-panel/call-filter-panel.component';
import { ChatFilterPanelComponent } from './chat/chat-filter-panel/chat-filter-panel.component';
import { ConversationMainFilterPanelComponent } from './conversation-main-filter-panel/conversation-main-filter-panel.component';
import { ConversationFilterPanelToolbarComponent } from './conversation-filter-panel-toolbar/conversation-filter-panel-toolbar.component';
// tslint:disable-next-line: max-line-length
import { ConversationStandardMixedListComponent } from './mixed/conversation-mixed-list/conversation-standard-mixed-list/conversation-standard-mixed-list.component';
// tslint:disable-next-line: max-line-length
import { ConversationCardMixedListComponent } from './mixed/conversation-mixed-list/conversation-card-mixed-list/conversation-card-mixed-list.component';
import { StandardCallListComponent } from './call/call-list/standard-call-list/standard-call-list.component';
import { StandardChatListComponent } from './chat/chat-list/standard-chat-list/standard-chat-list.component';
import { ConversationBaseListComponent } from './shared/conversation-base-list/conversation-base-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConversationMainFormComponent } from './conversation-main-form/conversation-main-form.component';
import { CoreModule } from 'src/core/core.module';
import { ChatFormComponent } from './chat/chat-form/chat-form.component';
import { CallAnalysisPanelComponent } from './call/call-analysis-panel/call-analysis-panel.component';
import { CallFormComponent } from './call/call-form/call-form.component';
import { CaSharedModule } from 'src/ca-shared/ca-shared.module';
import { PlayerModule } from 'src/ca-shared/player/player.module';
import { SelectorModule } from 'src/ca-shared/selector/selector.module';
import { DropdownTreeModule } from 'src/ca-shared/dropdown-tree/dropdown-tree.module';
import { DatetimeRangePickerModule } from 'src/ca-shared/datetime-range-picker/datetime-range-picker.module';
import { UserPhotoModule } from 'src/ca-shared/user-photo/user-photo.module';
import { ConversationDetailMainComponent } from './conversation-detail/conversation-detail-main/conversation-detail-main.component';
import { ConversationMainFilterPanelCollapsedComponent } from './conversation-main-filter-panel-collapsed/conversation-main-filter-panel-collapsed.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AttachedDataPanelComponent } from './call/attached-data-panel/attached-data-panel.component';
import { ConversationCommentComponentPanel } from './conversation-comment-panel/conversation-comment-panel.component';
import { CallTranscriptPanelComponent } from './conversation-transcript/call-transcript-panel/call-transcript-panel.component';
import { CallCategoryDetailPanelComponent } from './call/call-category-detail-panel/call-category-detail-panel.component';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';
import { ConversationTranscriptEditorComponent } from './conversation-transcript-editor/conversation-transcript-editor.component';
import { ConversationTranscriptEditorListComponent } from './conversation-transcript-editor-list/conversation-transcript-editor-list.component';
import { ConversationTranscriptFormComponent } from './conversation-transcript-form/conversation-transcript-form.component';
import { CallDetailTabPanelComponent } from './conversation-detail/call-detail-tab-panel/call-detail-tab-panel.component';
import { ChatDetailTabPanelComponent } from './conversation-detail/chat-detail-tab-panel/chat-detail-tab-panel.component';
import { ChatTranscriptPanelComponent } from './conversation-transcript/chat-transcript-panel/chat-transcript-panel.component';
import { ConversationTranscriptEditorInputComponent } from './conversation-transcript-editor-input/conversation-transcript-editor-input.component';
import { ConversationListFilterPanelComponent } from './conversation-list-filter-panel/conversation-list-filter-panel.component';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { ConversationTransferTabPanelComponent } from './conversation-transfer/conversation-transfer-tab-panel.component';
import { BlockUIModule } from 'ng-block-ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatCategoryDetailPanelComponent } from './chat/chat-category-detail-panel/chat-category-detail-panel.component';
import { CallSummaryModule } from 'src/ca-shared/conversation/call-summary/call-summary.module';
import { ChatSummaryModule } from 'src/ca-shared/conversation/chat-summary/chat-summary.module';
import { ConversationCategoryPanelModule } from 'src/ca-shared/conversation/conversation-category-panel/conversation-category-panel.module';
import { ReasonSelectorModule } from 'src/ca-shared/reason-selector/reason-selector.module';
import { EvaluationSelectorComponent } from './evaluation/evaluation-selector/evaluation-selector.component';
import { ConversationEvaluationListPanelComponent } from './evaluation/conversation-evaluation-list-panel/conversation-evaluation-list-panel.component';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { TextareaAutoresizeDirective } from 'src/core/directives/text-area-auto-resize.directive';
import { IconsModule } from 'src/ca-shared/icons/icons.module';
import { ConversationImportCommunicationService } from './conversation-import/conversation-import-communication.service';
import { ConversationImportWizardService } from './conversation-import/conversation-import-wizard.service';
import { ConversationImportComponent } from './conversation-import/conversation-import.component';
import { CallImportStepOne } from './conversation-import/steps/call-steps/step-one/call-import-step-one.component';
import { CallImportStepThree } from './conversation-import/steps/call-steps/step-three/call-import-step-three.component';
import { CallImportStepTwo } from './conversation-import/steps/call-steps/step-two/call-import-step-two.component';
import { ChatImportStepOne } from './conversation-import/steps/chat-steps/step-one/chat-import-step-one.component';
import { ChatImportStepThree } from './conversation-import/steps/chat-steps/step-three/chat-import-step-three.component';
import { ChatImportStepTwo } from './conversation-import/steps/chat-steps/step-two/chat-import-step-two.component';
import { ConversationImportStepZero } from './conversation-import/steps/step-zero/conversation-import-step-zero.component';
import { MeetingDetailTabPanelComponent } from './conversation-detail/meeting-detail-tab-panel/meeting-detail-tab-panel.component';
import { MeetingSummaryModule } from 'src/ca-shared/conversation/meeting-summary/meeting-summary.module';
import { MeetingTranscriptPanelComponent } from './conversation-transcript/meeting-transcript-panel/meeting-transcript-panel.component';
import { ConversationMeetingListComponent } from './meeting/conversation-meeting-list/conversation-meeting-list.component';
import { StandardMeetingListComponent } from './meeting/standard-meeting-list/standard-meeting-list.component';
import { ConversationExportComponent } from './conversation-export/conversation-export.component';
import { ConversationReportDownloadComponent } from './conversation-report-download/conversation-report-download.component';
import { ConversationHistoryPanelComponent } from './conversation-history-panel/conversation-history-panel.component';
import { ConversationListExportReadyNotificationComponent } from './notifications/conversation-list-export-ready-notification/conversation-list-export-ready-notification.component';
import { ConversationListExportFailedNotificationComponent } from './notifications/conversation-list-export-failed-notification/conversation-list-export-failed-notification.component';
import { ConversationCleanerComponent } from './conversation-cleaner/conversation-cleaner.component';
import { StepOneComponent } from './conversation-cleaner/steps/step-one/step-one.component';
import { StepTwoComponent } from './conversation-cleaner/steps/step-two/step-two.component';
import { TranscriptEditorInputComponent } from './transcript-editor-input/transcript-editor-input.component';
import { ConversationMarkAsComponent } from './conversation-mark-as/conversation-mark-as/conversation-mark-as.component';
import { ConversationMarkAsDetailComponent } from './conversation-mark-as/conversation-mark-as-detail/conversation-mark-as-detail.component';
import { ConversationMarkAsCommunicationService } from './conversation-mark-as/conversation-mark-as-communication.service';
import { ConversationDeleteCompletedNotificationComponent } from './notifications/conversation-delete-completed-notification/conversation-delete-completed-notification.component';
import { ConversationEvaluationDetailPanel } from './evaluation/conversation-evaluation-list-panel/conversation-evaluation-detail-panel/conversation-evaluation-detail-panel.component';
import { VideoCallListComponent } from './video-call/video-call-list/video-call-list.component';
import { StandartVideoCallListComponent } from './video-call/video-call-list/standart-video-call-list/standart-video-call-list.component';
import { VideoCallDetailTabPanelComponent } from './conversation-detail/video-call-detail-tab-panel/video-call-detail-tab-panel.component';
import { VideoCallSummaryModule } from 'src/ca-shared/conversation/video-call-summary/video-call-summary.module';
import { ConversationCleanerCommunicationService } from './conversation-cleaner/conversation-cleaner-communication.service';
import { ConversationChatTranscriptEditorInputComponent } from './conversation-chat-transcript-editor-input/conversation-chat-transcript-editor-input.component';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { ConversationIconContainerModule } from 'src/ca-shared/conversation-icon-container/conversation-icon-container.module';
import { ConversationBatchTaggingCommunicationService } from './conversation-batch-tagging/conversation-batch-tagging-communication.service';
import { ConversationBatchTaggingComponent } from './conversation-batch-tagging/conversation-batch-tagging.component';
import { ConversationBatchTaggingStepTwo } from './conversation-batch-tagging/steps/step-two/conversation-batch-tagging-step-two.component';
import { ConversationBatchTaggingStepOne } from './conversation-batch-tagging/steps/step-one/conversation-batch-tagging-step-one.component';
import { BatchTaggingCompletedNotificationComponent } from './notifications/batch-tagging-completed-notification/batch-tagging-completed-notification.component';
import { BatchTaggingFailedNotificationComponent } from './notifications/batch-tagging-failed-notification/batch-tagging-failed-notification.component';
import { BatchTaggingStartedNotificationComponent } from './notifications/batch-tagging-started-notification/batch-tagging-started-notification.component';
import { EmailListComponent } from './email/email-list/email-list.component';
import { StandartEmailListComponent } from './email/email-list/standart-email-list/standart-email-list.component';
import { EmailDetailTabPanelComponent } from './conversation-detail/email-detail-tab-panel/email-detail-tab-panel.component';
import { EmailSummaryModule } from 'src/ca-shared/conversation/email-summary/email-summary.module';
import { AttachedDataSelectorModule } from 'src/ui/attached-data-selector/attached-data-selector.module';
import { ColumnConfiguratorModule } from 'src/ui/column-configurator/column-configurator.module';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    ConversationMainComponent,
    ConversationListToolbarComponent,
    ConversationMixedListComponent,
    CallListComponent,
    ChatListComponent,
    ConversationCommonFilterPanelComponent,
    CallFilterPanelComponent,
    ChatFilterPanelComponent,
    ConversationMainFilterPanelComponent,
    ConversationFilterPanelToolbarComponent,
    ConversationStandardMixedListComponent,
    ConversationCardMixedListComponent,
    StandardCallListComponent,
    StandardChatListComponent,
    ConversationBaseListComponent,
    ConversationMainFormComponent,
    ChatFormComponent,
    CallAnalysisPanelComponent,
    CallFormComponent,
    ConversationDetailMainComponent,
    CallDetailTabPanelComponent,
    ConversationTransferTabPanelComponent,
    ConversationMainFilterPanelCollapsedComponent,
    AttachedDataPanelComponent,
    ConversationCommentComponentPanel,
    ConversationImportComponent,
    CallImportStepOne,
    CallImportStepTwo,
    CallImportStepThree,
    CallTranscriptPanelComponent,
    CallCategoryDetailPanelComponent,
    ChatCategoryDetailPanelComponent,
    ConversationTranscriptEditorComponent,
    ConversationTranscriptEditorListComponent,
    ConversationTranscriptFormComponent,
    ConversationMarkAsComponent,
    ConversationMarkAsDetailComponent,
    TranscriptEditorInputComponent,
    EvaluationSelectorComponent,
    ChatDetailTabPanelComponent,
    ChatTranscriptPanelComponent,
    ConversationTranscriptEditorInputComponent,
    ConversationListFilterPanelComponent,
    ConversationEvaluationListPanelComponent,
    ConversationHistoryPanelComponent,
    TextareaAutoresizeDirective,
    ConversationImportStepZero,
    ChatImportStepOne,
    ChatImportStepTwo,
    ChatImportStepThree,
    MeetingDetailTabPanelComponent,
    MeetingTranscriptPanelComponent,
    ConversationMeetingListComponent,
    StandardMeetingListComponent,
    ConversationExportComponent,
    ConversationReportDownloadComponent,
    ConversationListExportReadyNotificationComponent,
    ConversationListExportFailedNotificationComponent,
    ConversationCleanerComponent,
    StepOneComponent,
    StepTwoComponent,
    ConversationDeleteCompletedNotificationComponent,
    ConversationEvaluationDetailPanel,
    VideoCallListComponent,
    StandartVideoCallListComponent,
    VideoCallDetailTabPanelComponent,
    ConversationChatTranscriptEditorInputComponent,
    ConversationBatchTaggingComponent,
    ConversationBatchTaggingStepOne,
    ConversationBatchTaggingStepTwo,
    BatchTaggingCompletedNotificationComponent,
    BatchTaggingFailedNotificationComponent,
    BatchTaggingStartedNotificationComponent,
    EmailListComponent,
    StandartEmailListComponent,
    EmailDetailTabPanelComponent,
  ],
  imports: [
    CaSharedModule,
    SharedModule,
    CommonModule,
    CoreModule,
    ConversationRoutingModule,
    BlockUIModule,
    AngularSplitModule,
    NgbDatepickerModule,
    NgbNavModule,
    ClipboardModule,
    NgbAlertModule,
    PerfectScrollbarModule,
    InfiniteScrollModule,
    MatTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSliderModule,
    MatProgressBarModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSortModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    PlayerModule,
    SelectorModule,
    DropdownTreeModule,
    DatetimeRangePickerModule,
    UserPhotoModule,
    NgbModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    NgWizardModule.forRoot(ngWizardConfig),
    FilterPanelModule,
    NgSelectModule,
    ReasonSelectorModule,
    CallSummaryModule,
    ChatSummaryModule,
    MeetingSummaryModule,
    VideoCallSummaryModule,
    ConversationCategoryPanelModule,
    IconsModule,
    ConversationIconContainerModule,
    EmailSummaryModule,
    AttachedDataSelectorModule,
    ColumnConfiguratorModule,
  ],
  exports: [CallTranscriptPanelComponent, TranscriptEditorInputComponent],
  bootstrap: [
    ConversationListExportReadyNotificationComponent,
    ConversationListExportFailedNotificationComponent,
    ConversationDeleteCompletedNotificationComponent,
    BatchTaggingCompletedNotificationComponent,
    BatchTaggingFailedNotificationComponent,
    BatchTaggingStartedNotificationComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ConversationMarkAsCommunicationService,
    ConversationImportWizardService,
    CADatePipe,
    CATimePipe,
    ConversationImportCommunicationService,
    ConversationBatchTaggingCommunicationService,
    ConversationCleanerCommunicationService,
  ],
})
export class ConversationModule {}
