<div class="filter-container">
  <div [class]="filterByStatus ? 'col-9 p-0' : 'col-12 p-0'">
    <ca-select
      *ngIf="!hideFilterMode"
      [(ngModel)]="value.filterModeId"
      (change)="onFilterModeChange()"
      [items]="filterModeItems"
      ><ca-option *ngFor="let filterMode of filterModes" [value]="filterMode.id">
        {{ 'GenericLookup::' + filterMode.code | abpLocalization }}
      </ca-option></ca-select
    >
  </div>
  <div *ngIf="filterByStatus" [class]="filterByStatus ? 'col-3 pe-0' : ''">
    <ng-select
      [clearable]="false"
      [searchable]="false"
      [items]="statusItems"
      [ngModel]="currentStatus"
      [ngbTooltip]="currentStatus"
      [disableTooltip]="disableTooltip"
      (open)="disableTooltip = true"
      (close)="disableTooltip = false"
      (change)="changeStatusFilter($event)"
      appendTo="body">
      <ng-template ng-label-tmp let-item="item">
        <div placement="top" [ngClass]="item.class"></div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="d-flex flex-row align-items-center">
          <span [ngClass]="item.class"></span>
          <span class="ms-2">{{ item.label }}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

<div class="pt-2">
  <ca-auto-complete-selector
    #autoCompleteSelector
    style="flex: 1"
    [url]="autoCompleteSelectorOptions.url"
    [queryField]="autoCompleteSelectorOptions.queryField"
    [emptyText]="autoCompleteSelectorOptions.emptyText"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [showEmail]="showEmail"
    [disabled]="disabled"
    [selectionEmailTemplate]="selectionEmailTemplate"
    [(ngModel)]="value.selectedUsers"
    [selectionStyle]="{ 'max-height': '125px', 'overflow-y': 'auto' }"
    [filters]="filters"
    [selectionText]="autoCompleteSelectorOptions.selectionText"
    [disabledItemList]="disabledItemList"
    [disabledItemMessage]="disabledItemMessage"
    [noSelectionText]="noUserSelected"
    [nullElement]="nullElement"
    [nullElementTerm]="nullElementTerm"
    [appendToBody]="appendToBody"
    [filterByStatus]="filterByStatus"
    (ngModelChange)="onSelectionChange()"></ca-auto-complete-selector>
</div>
<ng-template #itemTemplate let-item="item">
  <div class="user-label-container">
    <div *ngIf="filterByStatus">
      <i
        [ngClass]="
          item.isActive
            ? 'fas fa-circle me-2 ms-2 active-item-icon'
            : 'fas fa-circle me-2 ms-2 passive-item-icon'
        "></i>
    </div>
    <ca-user-photo
      class="list-photo"
      [styles]="userPhotoComponentStyles"
      [useCurrentUser]="false"
      [userId]="item.id"
      [nameAndSurname]="[item.name, item.surname]">
    </ca-user-photo>
    <div class="filter-name">
      {{ item.fullName }}
    </div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <ca-user-identity
    [id]="item.id"
    [name]="item.fullName"
    [surname]="item.surname"></ca-user-identity>
</ng-template>

<ng-template #selectionEmailTemplate let-item="item">
  <div class="filter-name">{{ item.email }}</div>
</ng-template>
