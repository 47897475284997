import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';

export class CallCategoryMarkerDto {
  color: string;
  text: string;
  startMillisecond: number;
  endMillisecond: number;
  channel: number;
  categoryName: string;
  isSearchMarker?: boolean;
  isTopicMarker?: boolean;
  isCommentMarker?: boolean;
  words: TranscriptWord[];
}
