import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../../shared/dto-has-api.model';

@Api('api/app/evaluation')
export class EvaluationObjectionDto extends DtoHasApi {
  id: number;
  reason: string;
  evaluationResultId: number;
  statusId: number;
  rejectionReason?: string | null;
  creationTime: Date;
  creatorId: string;
}
