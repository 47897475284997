import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ConversationLayoutService } from 'src/core/services/conversation/conversation-layout.service';
import { Select, Store } from '@ngxs/store';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { ConversationListType } from 'src/core/models/generic-lookup-type/conversation/conversation-list-type.glt';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { ConversationsResultInputDto } from 'src/core/models/conversation/conversations-result-input.dto';
import { ConversationResultType } from 'src/core/models/generic-lookup-type/conversation/conversation-result-type.glt';
import { PermissionService } from '@abp/ng.core';

@Component({
  selector: 'ca-conversation-list-toolbar',
  templateUrl: './conversation-list-toolbar.component.html',
  styleUrls: ['./conversation-list-toolbar.component.scss'],
})
export class ConversationListToolbarComponent implements OnInit, AfterViewInit {
  @Select(ConversationModuleState.getCurrentLayout)
  layout$: Observable<LayoutConfig>;

  @Select(ConversationModuleState.getViewOptions)
  viewOptions$: Observable<GenericLookupDto[]>;

  @Select(ConversationModuleState.getActiveList)
  activeList$: Observable<number>;

  @Select(ConversationModuleState.getSorterFormValues)
  sorterFormValues$: Observable<object>;

  @Select(ConversationModuleState.getData)
  data$: Observable<ConversationListResponseDto>;

  @Select(ConversationModuleState.dataRequested)
  dataRequested$: Observable<boolean>;

  listTypes$: Observable<GenericLookupDto[]>;

  @Input()
  conversationResult: ConversationsResultInputDto;

  disabled = false;
  isEmptyResultSet = false;
  selectedListType: GenericLookupDto;
  conversationResultType = ConversationResultType;
  canAssignBatchTagging: boolean = false;
  activeGridId: string;

  constructor(
    private store: Store,
    private conversationLayoutService: ConversationLayoutService,
    private permissionService: PermissionService
  ) {
    this.canAssignBatchTagging = this.permissionService.getGrantedPolicy(
      'Conversation.Tag.AssignBatchTagging'
    );
  }

  ngOnInit() {
    this.listTypes$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationListType)));

    this.dataRequested$.subscribe(requested => {
      this.disabled = requested;
    });

    this.data$.subscribe(result => {
      this.isEmptyResultSet = result.items.length === 0;
    });

    this.activeList$.subscribe(activeList => {
      this.listTypes$.subscribe(listType => {
        this.selectedListType = listType.find(f => f.id === activeList);
        this.activeGridId = `conversation-list-${this.selectedListType.id}`;
      });
    });
  }

  ngAfterViewInit() {}

  getConversationResultValues() {
    return this.conversationResult ? this.conversationResult.values?.join(', ') : '';
  }

  getConversationResultLabel() {
    var label = '';
    if (this.conversationResult?.conversationResultType === this.conversationResultType.phrase) {
      label = 'Conversation::ConversationResultType:Phrase';
    } else if (
      this.conversationResult?.conversationResultType === this.conversationResultType.query
    ) {
      label = 'Conversation::ConversationResultType:Category';
    } else if (
      this.conversationResult?.conversationResultType === this.conversationResultType.topic
    ) {
      label = 'Conversation::ConversationResultType:Topic';
    }
    return label;
  }

  changeVisibilityForFilterPanel(visible: boolean): void {
    this.conversationLayoutService.changeVisibilityForFilterPanel(visible);
  }
}
