import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { CrudService } from 'src/core/services/crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class AttachedDataService {
  constructor(private operators: Operators, private service: CrudService) {}

  getList(
    searchTerm: string,
    pageIndex: number,
    itemsPerPage: number
  ): Observable<ListResponseDto<AttachedDataDto>> {
    const filters: FilterItemDto[] = [
      {
        field: 'visible',
        value: true,
        operator: this.operators.Equals,
      },
    ];

    if (searchTerm && searchTerm.length > 0) {
      filters.push({
        field: 'quickSearchTerm',
        value: searchTerm,
        operator: this.operators.Contains,
      });
    }

    return this.service.get<AttachedDataDto>(AttachedDataDto, {
      skipCount: pageIndex * itemsPerPage,
      maxResultCount: itemsPerPage,
      filters: filters,
      sorters: [],
    });
  }
}
