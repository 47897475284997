import { VALIDATION_BLUEPRINTS } from '@ngx-validate/core';
import { LocalizationService, ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ValidationMessageService {
  required: string;
  dataKeyExists: string;
  lookupValueExists: string;
  usernameInUse: string;
  userNameInvalidPattern: string;
  emailInUse: string;
  pbxAgentIdInUse: string;
  deviceIdInUse: string;
  queryCategoryInUse: string;
  queryNameInUse: string;
  scenarioNameInUse: string;
  filterNameInUse: string;
  keyExists: string;
  invalidConfiguration: string;
  passwordsNotMatched: string;
  queryTimeRangeNotValid: string;
  transcritTimeRangeNotValid: string;
  pattern: string;
  invalidPassword: string;
  minlength: string;
  maxlength: string;
  whiteSpaceNotAllowed: string;
  email: string;
  queryTermMultipleAsteriks: string;
  queryTermWrongAsteriksPosition: string;
  queryTermInvalidPattern: string;
  queryTermQuotesNotRequired: string;
  wordExists: string;
  automaticAssignmentNameInUse: string;
  wordOrPhraseWrongAsteriksPosition: string;
  wordOrPhraseMultipleAsteriks: string;
  wordOrPhraseInvalidPattern: string;
  wordOrPhraseQuotesNotRequired: string;
  multipleChoiceAttachedDataWithNoLookupValues: string;
  alphaNumericStringInvalidPattern: string;
  alphaNumericAndDotsStringInvalidPattern: string;
  formNameInUse: string;
  genAIProjectNameInUse: string;
  userGroupNameInUse: string;
  userGroupInvalidInteraction: string;
  reasonTextAlreadyInUse: string;
  topicNameAlreadyInUse: string;
  assignmentAlreadyName: string;
  noDepartmentsOtherThanSelectedDepartments: string;
  queryTextIsNotCorrect: string;
  logicalOperatorsNotValid: string;
  logicalOperatorsNotValidInSameLevel: string;
  sentenceNotValid: string;
  tagNameAlreadyInUse: string;
  deviceGroupNameAlreadyInUse: string;
  deviceNameAlreadyInUse: string;
  deviceIpAlreadyInUse: string;
  deviceIpNotValid: string;
  allowOnlyActiveUsers: string;
  maxPhraseLimit: string;
  reportNameAlreadyInUse: string;
  passwordRequiresUpper: string;
  passwordRequiresDigit: string;
  passwordRequiresLower: string;
  passwordRequiresNonAlphanumeric: string;

  constructor(
    private configStateService: ConfigStateService,
    public localizationService: LocalizationService
  ) {
    let currentLanguage$: Observable<any>;
    currentLanguage$ = configStateService.getDeep$('localization.currentCulture');
    currentLanguage$.subscribe(language => {
      this.dataKeyExists = this.localizationService.instant('AttachedData::AlreadyAttached');
      this.keyExists = this.localizationService.instant('Source::AlreadyHaveSource');
      this.invalidConfiguration = this.localizationService.instant(
        'Validation::InvalidConfiguration'
      );
      this.usernameInUse = this.localizationService.instant('Validation::User:UsernameInUse');
      this.emailInUse = this.localizationService.instant('Validation::User:EmailInUse');
      this.pbxAgentIdInUse = this.localizationService.instant('Validation::User:PbxAgentIdInUse');
      this.deviceIdInUse = this.localizationService.instant('Validation::User:DeviceIdInUse');
      this.queryCategoryInUse = this.localizationService.instant('Query::CategoryNameAlreadyInUse');
      this.queryNameInUse = this.localizationService.instant('Query::NameAlreadyInUse');
      this.scenarioNameInUse = this.localizationService.instant('RealTime::NameAlreadyInUse');
      this.filterNameInUse = this.localizationService.instant(
        'Analysis::StatisticComparison:NameAlreadyInUse'
      );
      this.wordExists = this.localizationService.instant('Validation::IgnoredWord:AlreadyExists');

      this.passwordsNotMatched = this.localizationService.instant('User::PasswordsNotMatched');
      this.queryTimeRangeNotValid = this.localizationService.instant(
        'Validation::Query:TimeRangeNotValid'
      );
      this.transcritTimeRangeNotValid = this.localizationService.instant(
        'Validation::Transcript:TimeRangeNotValid'
      );
      this.required = this.localizationService.instant('::ThisFieldIsRequired');
      this.pattern = this.localizationService.instant('Validation::InvalidPattern');
      this.invalidPassword = this.localizationService.instant('User::InvalidPassword');
      this.minlength = this.localizationService.instant('Validation::MinLength');
      this.maxlength = this.localizationService.instant('Validation::MaxLength');
      this.whiteSpaceNotAllowed = this.localizationService.instant(
        'Validation::WhiteSpaceNotAllowed'
      );
      this.email = this.localizationService.instant('Validation::InvalidEmail');

      this.queryTermMultipleAsteriks = this.localizationService.instant(
        'Validation::Query:QueryTermMultipleAsteriks'
      );

      this.queryTermWrongAsteriksPosition = this.localizationService.instant(
        'Validation::Query:QueryTermWrongAsteriksPosition'
      );

      this.queryTermInvalidPattern = this.localizationService.instant(
        'Validation::Query:QueryTermInvalidPattern'
      );

      this.userNameInvalidPattern = this.localizationService.instant('Validation::InvalidPattern');

      this.queryTermQuotesNotRequired = this.localizationService.instant(
        'Validation::Query:QueryTermQuotesNotRequired'
      );

      this.wordOrPhraseWrongAsteriksPosition = this.localizationService.instant(
        'Validation::IgnoredWord:WordOrPhraseWrongAsteriksPosition'
      );

      this.wordOrPhraseMultipleAsteriks = this.localizationService.instant(
        'Validation::IgnoredWord:WordOrPhraseMultipleAsteriks'
      );

      this.wordOrPhraseInvalidPattern = this.localizationService.instant(
        'Validation::IgnoredWord:WordOrPhraseInvalidPattern'
      );

      this.wordOrPhraseQuotesNotRequired = this.localizationService.instant(
        'Validation::IgnoredWord:WordOrPhraseQuotesNotRequired'
      );

      this.automaticAssignmentNameInUse = this.localizationService.instant(
        'QualityManagement::NameAlreadyInUse'
      );

      this.lookupValueExists = this.localizationService.instant(
        'Validation::AttachedData:LookupValueExists'
      );

      this.multipleChoiceAttachedDataWithNoLookupValues = this.localizationService.instant(
        'Validation::AttachedData:MultipleChoiceAttachedDataWithNoLookupValues'
      );

      this.alphaNumericStringInvalidPattern = this.localizationService.instant(
        'Validation::AlphaNumericStringInvalidPattern'
      );

      this.alphaNumericAndDotsStringInvalidPattern = this.localizationService.instant(
        'Validation::AlphaNumericAndDotsStringInvalidPattern'
      );

      this.formNameInUse = this.localizationService.instant('QualityManagement::NameAlreadyInUse');

      this.genAIProjectNameInUse = this.localizationService.instant(
        'Validation::GenAIProject:NameAlreadyInUse'
      );

      this.userGroupNameInUse = this.localizationService.instant(
        'Validation::UserGroup:NameAlreadyInUse'
      );

      this.userGroupInvalidInteraction = this.localizationService.instant(
        'Validation::UserGroup:InvalidInteraction'
      );

      this.reasonTextAlreadyInUse = this.localizationService.instant(
        'Validation::Reason:ReasonTextAlreadyInUse'
      );

      this.topicNameAlreadyInUse = this.localizationService.instant(
        'Validation::Topic:TopicNameAlreadyInUse'
      );

      this.queryTextIsNotCorrect = this.localizationService.instant(
        'Validation::Query:QueryTextIsNotCorrect'
      );

      this.logicalOperatorsNotValid = this.localizationService.instant(
        'Validation::Query:LogicalOperatorsNotValid'
      );

      this.logicalOperatorsNotValidInSameLevel = this.localizationService.instant(
        'Validation::Query:LogicalOperatorsNotValidInSameLevel'
      );

      this.assignmentAlreadyName = this.localizationService.instant(
        'Validation::QualityManagement:AssignmentAlreadyName'
      );

      this.sentenceNotValid = this.localizationService.instant(
        'Validation::Query:SentenceNotValid'
      );

      this.noDepartmentsOtherThanSelectedDepartments = this.localizationService.instant(
        'Validation::Department:NoDepartmentsOtherThanSelectedDepartments'
      );

      this.tagNameAlreadyInUse = this.localizationService.instant(
        'Validation::Conversation:TagNameAlreadyInUse'
      );

      this.deviceGroupNameAlreadyInUse = this.localizationService.instant(
        'Validation::CallRecorderManagement:DeviceGroupNameAlreadyInUse'
      );

      this.deviceNameAlreadyInUse = this.localizationService.instant(
        'Validation::CallRecorderManagement:DeviceNameAlreadyInUse'
      );

      this.deviceIpAlreadyInUse = this.localizationService.instant(
        'Validation::CallRecorderManagement:DeviceIpAlreadyInUse'
      );

      this.deviceIpNotValid = this.localizationService.instant(
        'Validation::CallRecorderManagement:DeviceIpNotValid'
      );

      this.allowOnlyActiveUsers = this.localizationService.instant(
        'Validation::User:AllowOnlyActiveUsers'
      );

      this.maxPhraseLimit = this.localizationService.instant('Validation::RealTime:MaxPhraseLimit');

      this.reportNameAlreadyInUse = this.localizationService.instant(
        'Validation::EmailReport:ReportNameAlreadyInUse'
      );

      this.passwordRequiresUpper = this.localizationService.instant(
        'AbpIdentity::Volo.Abp.Identity:PasswordRequiresUpper'
      );

      this.passwordRequiresLower = this.localizationService.instant(
        'AbpIdentity::Volo.Abp.Identity:PasswordRequiresLower'
      );

      this.passwordRequiresDigit = this.localizationService.instant(
        'AbpIdentity::Volo.Abp.Identity:PasswordRequiresDigit'
      );

      this.passwordRequiresNonAlphanumeric = this.localizationService.instant(
        'AbpIdentity::Volo.Abp.Identity:PasswordRequiresNonAlphanumeric'
      );
    });
  }
}

export const VALIDATION_MESSAGE_PROVIDER = [
  {
    provide: VALIDATION_BLUEPRINTS,
    useClass: ValidationMessageService,
  },
];
