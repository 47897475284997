import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PhraseSuggestionRequestDto } from 'src/core/models/phrase-suggestion/phrase-suggestion-request.dto';
import { PhraseSuggestionResultDto } from 'src/core/models/phrase-suggestion/phrase-suggestion-result.dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhraseSuggestionService {
  constructor(private http: HttpClient) {}

  getPhraseSuggestions(
    request: PhraseSuggestionRequestDto | null
  ): Observable<PhraseSuggestionResultDto[]> {
    const url = this.getApiUrl() + '/suggestions';

    return this.http.post(url, request) as Observable<PhraseSuggestionResultDto[]>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + PhraseSuggestionRequestDto.apiUrl;
  }
}
