<ng-select
  [class]="class"
  [style]="style"
  [clearable]="false"
  [searchable]="false"
  [disabled]="disabled"
  [(ngModel)]="value"
  (change)="onChanged($event)"
  [placeholder]="placeholder"
  [ngClass]="classList"
  [appendTo]="appendTo">
  <ng-option *ngFor="let item of items" [value]="item.data">
    <div
      [ngClass]="'truncate-text'"
      [ngbTooltip]="item.label"
      showTooltipIfTruncated
      container="body">
      {{ item.label }}
    </div>
  </ng-option>
</ng-select>