<ca-user-list-toolbar
  (isActiveFilterChangeRequest)="onIsActiveFilterChangeRequest($event)"
  (addNewClick)="onAddNewClick($event)"
></ca-user-list-toolbar>
<div class="row split-panel-container full-height">
  <as-split
    [unit]="splitConfig.unit"
    [direction]="splitConfig.direction"
    [useTransition]="splitConfig.useTransition"
    [gutterSize]="splitConfig.gutterSize"
    (dragEnd)="onDragEnd($event)"
    [disabled]="true"
    #split="asSplit"
  >
    <as-split-area
      [size]="(layout$ | async).config.west.size"
      [minSize]="(layout$ | async).config.west.minSize"
      [maxSize]="(layout$ | async).config.west.maxSize"
      [visible]="(layout$ | async).config.west.visible"
      [order]="0"
    >
      <ca-user-list-filter-panel
        (collapsed)="onFilterPanelCollapsed($event)"
        (expanded)="onFilterPanelExpanded($event)"
        (valueChanges)="onFilterValueChanges($event)"
      ></ca-user-list-filter-panel>
    </as-split-area>
    <as-split-area
      [size]="(layout$ | async).config.center.size"
      [minSize]="(layout$ | async).config.center.minSize"
      [maxSize]="(layout$ | async).config.center.maxSize"
      [visible]="(layout$ | async).config.center.visible"
      [order]="1"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="false"
      class="center-panel"
    >
      <ca-user-list #list (showDetails)="onOpenDetails($event)"></ca-user-list>
    </as-split-area>
    <as-split-area
      [size]="(layout$ | async).config.east.size"
      [minSize]="(layout$ | async).config.east.minSize"
      [maxSize]="(layout$ | async).config.east.maxSize"
      [visible]="(layout$ | async).config.east.visible"
      [order]="2"
      style="display: flex; flex-direction: column; justify-content: stretch; align-items: stretch"
    >
    </as-split-area>
  </as-split>
</div>
