import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.AutoDeletionOption',
})
export class ConversationAutoDeletionOption {
  @GenericLookup({
    code: 'Conversation.AutoDeletionOption.OlderThanThreeMonths',
  })
  static olderThanThreeMonth: number;

  @GenericLookup({
    code: 'Conversation.AutoDeletionOption.OlderThanSixMonths',
  })
  static olderThanSixMonth: number;

  @GenericLookup({
    code: 'Conversation.AutoDeletionOption.OlderThanTwelveMonths',
  })
  static olderThanTwelveMonth: number;
}
