import { isArray } from '@abp/ng.core';
import { HttpParams } from '@angular/common/http';
import { Component, forwardRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-auto-complete-selector',
  templateUrl: './auto-complete-selector.component.html',
  styleUrls: ['./auto-complete-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutoCompleteSelectorComponent),
    },
  ],
})
export class AutoCompleteSelectorComponent implements OnInit, ControlValueAccessor {
  @ViewChild(NgSelectComponent) selector: NgSelectComponent;

  private _emptyText: string;
  private _filters: FilterItemDto[] | string;
  private _sorters: SorterItemDto[] | string;
  private _disabled: boolean = false;
  // tslint:disable-next-line: variable-name
  private _disabledItemMessage: string;
  // tslint:disable-next-line: variable-name
  private _disabledItemList: any[] = [];

  itemInput$ = new Subject<string>();
  items$: Observable<any[]>;
  selectedRecords: any[] = [];
  dummyModel: any[];
  itemsLoading = false;
  queryValue = '';
  minTermLength = 2;

  @Input()
  selectionStyle: { [klass: string]: any };

  @Input()
  selectionText: string;

  @Input()
  queryOperator: number;

  @Input()
  idProperty: string = 'id';

  @Input()
  queryField: string;

  @Input()
  url: string;

  @Input()
  filterProperty = 'filters';

  @Input()
  sorterProperty = 'sorters';

  @Input()
  skipCountProperty = 'skipCount';

  @Input()
  maxResultCountProperty = 'maxResultCount';

  @Input()
  noSelectionText = '';

  @Input()
  nullElement: any = null;

  @Input()
  nullElementTerm: string = '';

  @Input()
  singleSelection: boolean = false;

  @Input()
  filterByStatus: boolean = false;

  @Input()
  set disabledItemList(disabledItemList: any[]) {
    this._disabledItemList = disabledItemList;
  }

  get disabledItemList() {
    return this._disabledItemList;
  }

  @Input()
  set disabledItemMessage(message: string) {
    this._disabledItemMessage = message;
  }

  get disabledItemMessage() {
    return this._disabledItemMessage;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set emptyText(value: string) {
    this._emptyText = value;
  }

  get emptyText(): string {
    return this._emptyText;
  }

  @Input()
  set filters(value: FilterItemDto[] | string) {
    this._filters = value;
  }

  @Input()
  set sorters(value: SorterItemDto[] | string) {
    this._sorters = value;
  }

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  selectionTemplate: TemplateRef<any>;

  @Input()
  showEmail: boolean = false;

  @Input()
  selectionEmailTemplate: TemplateRef<any>;

  @Input()
  appendToBody: boolean = true;

  @Input()
  hideSelections: boolean = false;

  public getFilters(): FilterItemDto[] | string {
    if (typeof this._filters === 'string') {
      return this.queryValue;
    }

    let result: FilterItemDto[] = [];
    if (this._filters && this._filters.length > 0) {
      result = result.concat(this._filters);
    }

    if (this.queryValue && this.queryValue.trim().length > 0) {
      result.push({
        field: this.queryField,
        operator: this.queryOperator,
        value: this.queryValue,
      });
    }

    return result;
  }

  private getSorters(): SorterItemDto[] | string {
    if (typeof this._sorters === 'string') {
      return this._sorters;
    }

    let result: SorterItemDto[] = [];
    if (this._sorters && this._sorters.length > 0) {
      result = result.concat(this._sorters);
    }

    return result;
  }

  constructor(private crudService: CrudService) { }

  ngOnInit(): void {
    this.initializeTypeahead();
    window.addEventListener('scroll', this.onScroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  onSearch(event) {
    if (this.minTermLength > event.term.length) {
      this.clear();
    }
  }

  onClose() {
    this.clear();
  }

  onItemSelect($event) {
    if (isArray($event)) {
      if (this.singleSelection) {
        this.selectedRecords = [];
      }
      if (this.isSelected($event[0][this.idProperty])) {
        this.selectedRecords = this.selectedRecords.filter(
          x => x.id !== $event[0][this.idProperty]
        );
      } else {
        this.selectedRecords.push($event[0]);
      }

      this.dummyModel = [];
      this.applyChanges();
    }
  }

  private initializeTypeahead() {
    this.items$ = concat(
      of([]),
      this.itemInput$.pipe(
        debounceTime(500),
        tap(() => (this.itemsLoading = true)),
        switchMap(term =>
          this.loadData(term).pipe(
            catchError(() => of([])),
            tap(() => (this.itemsLoading = false))
          )
        )
      )
    );
  }

  loadData(term): Observable<any[]> {
    this.queryValue = term;
    const filters = this.getFilters();
    const sorters = this.getSorters();

    let params = new HttpParams();

    if (typeof filters === 'string') {
      params = params.append(this.filterProperty, filters);
    } else {
      params = params.append(this.filterProperty, JSON.stringify(filters));
    }
    if (typeof sorters === 'string') {
      params = params.append(this.sorterProperty, sorters);
    } else {
      params = params.append(this.sorterProperty, JSON.stringify(sorters));
    }

    params = params.append(this.skipCountProperty, '0');
    params = params.append(this.maxResultCountProperty, '99999');
    const req = this.crudService.http.get(this.url, {
      params,
    }) as Observable<ListResponseDto<any>>;
    return req.pipe(
      map(response => {
        if (this.minTermLength > this.selector.searchTerm.length) {
          return [];
        } else {
          let result = [];
          if (
            this.nullElement &&
            response &&
            response.items &&
            this.nullElementTerm
              .toLocaleLowerCase()
              .includes(this.selector.searchTerm.toLocaleLowerCase())
          ) {
            result.push(this.nullElement);
          }
          response?.items.forEach(element => {
            element['disabled'] = this.isDisabled(element['id']);
            result.push(element);
          });
          return result;
        }
      })
    );
  }

  isSelected(id: any) {
    var selected = this.selectedRecords.filter(x => x.id === id);
    if (selected.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isDisabled(id: any) {
    return this.disabledItemList.find(x => x.id == id) !== undefined;
  }

  writeValue(obj: any): void {
    obj = obj ? obj : [];
    this.selectedRecords = obj;
    if (this.selectedRecords.length === 0) {
      this.clearSelection();
    }
  }

  onChange(selection: any[]) { }

  onTouched() { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  clearSelection() {
    this.selectedRecords = [];
  }

  searchFunction(term, item) {
    // anything returning true makes it into the filtered list
    return item.name.includes(term);
  }

  onClickRemoveItem(item) {
    const idx = this.selectedRecords.indexOf(item);

    this.selectedRecords.splice(idx, 1);

    this.applyChanges();
  }

  applyChanges() {
    this.onChange(ObjectHelper.deepCopy(this.selectedRecords));
  }

  clear() {
    this.selector.items = [];
    this.selector.itemsList.setItems([]);
  }

  onEnterKeyUp(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private onScroll = (event: any) => {
    let targetClass = event?.target?.getAttribute('class');
    let dropdownPanelClass = 'ng-dropdown-panel-items scroll-host';
    if (this.selector && this.selector.isOpen && targetClass != dropdownPanelClass) {
      this.selector.close();
    }
  };
}
