import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { CallLanguageCodeDto } from 'src/core/models/call/call-language-code.dto';
import { catchError, map } from 'rxjs/operators';
import { UserStateSettingsDto } from 'src/core/models/configuration-setting/user-state-settings.dto';
import { InitialSettingsDto } from 'src/core/models/configuration-setting/initial-settings.dto';
import { CAConfigStateService } from '../config/ca-config-state.service';
import { ConfigStateService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationSettingsService {
  private apiBase = environment.apis.default.url;

  constructor(private http: HttpClient, private configStateService: CAConfigStateService, private config: ConfigStateService) { }

  saveSetting(settings: ConfigurationSettingsDto[]): Observable<any> {

    let tenantId = this.config.getDeep(['currentUser', 'tenantId']);
    let isHost = tenantId == null;
    const address = isHost ? `${this.apiBase}/api/app/configuration-settings/host-settings/settings` : `${this.apiBase}/api/app/configuration-settings/settings`;

    return this.http.post(address, settings).pipe(
      map(
        response => {
          this.configStateService.refreshAppState().subscribe();

          return response;
        },
        catchError(() => {
          return null;
        })
      )
    ) as Observable<any>;
  }

  saveInitialSettings(initialSettings: InitialSettingsDto): Observable<any> {
    return this.http
      .post(`${this.apiBase}/api/app/configuration-settings/initial-configuration`, initialSettings)
      .pipe(
        map(
          response => {
            this.configStateService.refreshAppState().subscribe();

            return response;
          },
          catchError(() => {
            return null;
          })
        )
      ) as Observable<any>;
  }

  getLanguageCodeList(): Observable<CallLanguageCodeDto[]> {
    return this.http.get(`${this.apiBase}/` + CallLanguageCodeDto.apiUrl) as Observable<
      CallLanguageCodeDto[]
    >;
  }

  initializeUserStateSettings(): Observable<any[]> {
    return this.http.get<UserStateSettingsDto[]>(`${this.apiBase}/` + UserStateSettingsDto.apiUrl);
  }

  saveAndRestartNonFcr(settings: ConfigurationSettingsDto[]): Observable<any> {
    return this.http
      .post(`${this.apiBase}/api/app/configuration-settings/settings/restart-nonfcr`, settings)
      .pipe(
        map(
          response => {
            this.configStateService.refreshAppState().subscribe();

            return response;
          },
          catchError(() => {
            return null;
          })
        )
      ) as Observable<any>;
  }
}
