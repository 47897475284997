import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { ConfigStateService, PermissionService } from '@abp/ng.core';
import { LocalizationService } from '@abp/ng.core';
import { combineLatest } from 'rxjs';
import { StringService } from 'src/core/services/helper/string.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@abp/ng.theme.shared';
import { CallDetailTabPanelComponent } from '../call-detail-tab-panel/call-detail-tab-panel.component';
import { ConversationReferrer } from 'src/core/constants/conversation-referrer.constant';
import { Store } from '@ngxs/store';
import { CacheOptionChange } from 'src/core/actions/conversation/conversation-module.actions';
import { CallAnalysisStatus } from 'src/core/models/generic-lookup-type/call/call-analysis-status.glt';
import { ConversationTopicDto } from 'src/core/models/conversation/ai-topic/conversation-topic.dto';
import { TopicTermItemDto } from 'src/core/models/query/topic-term-item.dto';
import { ChatDetailTabPanelComponent } from '../chat-detail-tab-panel/chat-detail-tab-panel.component';
import { ConversationModuleStateModel } from 'src/core/models/conversation/conversation-module.state-model';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NonFcrState } from 'src/core/states/analysis/non-fcr.state';
import { NonFcrStateModel } from 'src/core/models/analysis/non-fcr/non-fcr.state-model';
import { MeetingDetailTabPanelComponent } from '../meeting-detail-tab-panel/meeting-detail-tab-panel.component';
import { CrudService } from 'src/core/services/crud/crud.service';
import { VideoCallDetailTabPanelComponent } from '../video-call-detail-tab-panel/video-call-detail-tab-panel.component';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';
import { ConversationCommentInputDto } from 'src/core/models/comment/comment-input.dto';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { CommentTermItemDto } from 'src/core/models/query/comment-term-item.dto';
import { Title } from '@angular/platform-browser';
import { EmailDetailTabPanelComponent } from '../email-detail-tab-panel/email-detail-tab-panel.component';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';

@Component({
  selector: 'ca-conversation-detail-main',
  templateUrl: './conversation-detail-main.component.html',
  styleUrls: ['./conversation-detail-main.component.scss'],
})
@AutoUnsubscribe()
export class ConversationDetailMainComponent extends HasSubscription implements OnInit {
  @ViewChild('conversationDeleteModal') conversationDeleteModal: any;

  @ViewChild('callDetailTabPanel', { static: false })
  callDetailTabPanel: CallDetailTabPanelComponent;

  @ViewChild('chatDetailTabPanel', { static: false })
  chatDetailTabPanel: ChatDetailTabPanelComponent;

  @ViewChild('meetingDetailTabPanel', { static: false })
  meetingDetailTabPanel: MeetingDetailTabPanelComponent;

  @ViewChild('videoCallDetailTabPanel', { static: false })
  videoCallDetailTabPanel: VideoCallDetailTabPanelComponent;

  @ViewChild('emailDetailTabPanel', { static: false })
  emailDetailTabPanel: EmailDetailTabPanelComponent;

  conversationDeleteModalObject: NgbModalRef;
  id: number;
  conversation: ConversationDto;
  categories: ConversationCategoryDto[] = [];
  canDeleteConversation: boolean = false;
  quickSearchFilter: string[];
  queryId: number = 0;
  deleteConfirmationText: string = '';
  totalCount = 0;
  skipCount = 0;
  pageSize = 30;
  processing = false;
  canEvaluateConversation: boolean = false;
  enableQuickNavigation = false;
  topicIds = [];

  topicColorList = [
    '#FF6000',
    '#0D4A78',
    '#A46050',
    '#366442',
    '#FF66C0',
    '#66A0CC',
    '#F9F871',
    '#00FFFF',
    '#F5F5DC',
    '#000000',
    '#BD8089',
    '#0000FF',
    '#008080',
    '#00008B',
    '#A9A9A9',
    '#BDB76B',
    '#8B008B',
    '#556B2F',
    '#FF8C00',
    '#8B0000',
    '#E9967A',
    '#9400D3',
    '#FFD700',
    '#008000',
    '#4B0082',
    '#F0E68C',
    '#ADD8E6',
    '#E0FFFF',
    '#90EE90',
    '#D3D3D3',
    '#FFB6C1',
    '#FFFFE0',
    '#00FF00',
    '#FF00FF',
    '#FFC0CB',
    '#800080',
    '#FF0000',
    '#C0C0C0',
    '#FFFFFF',
    '#FFFF00',
  ];

  private referrer: string;

  get conversationDeleteDisabled(): boolean {
    const deleteMessage = this.localizationService.instant('AbpUi::Delete').toLocaleUpperCase();
    return this.deleteConfirmationText !== deleteMessage;
  }

  get deleteConversationInformationText(): string {
    const deleteMessage = this.localizationService.instant('AbpUi::Delete').toLocaleUpperCase();
    let message = this.localizationService.instant(
      'Conversation::DeleteConversationWarning1',
      this.conversation?.id.toString()
    );
    message +=
      '<br>' + this.localizationService.instant('Conversation::DeleteConversationWarning2');

    message +=
      '<br>' +
      this.localizationService.instant('Conversation::DeleteConversationWarning3', deleteMessage);
    return message;
  }

  get backActionText(): string {
    let result = '';

    switch (this.referrer) {
      case ConversationReferrer.nonFcr:
        result = this.localizationService.instant('::BackToList');
        break;
      case ConversationReferrer.evaluationResult:
        result = this.localizationService.instant('QualityManagement::BackToEvaluationResults');
        break;
      default:
        result = this.localizationService.instant('::BackToList');
        break;
    }

    return result;
  }

  get isCall(): boolean {
    if (this.conversation?.typeId === ConversationType.call) {
      return true;
    } else {
      return false;
    }
  }

  get isChat(): boolean {
    if (this.conversation?.typeId === ConversationType.chat) {
      return true;
    } else {
      return false;
    }
  }

  get isMeeting(): boolean {
    if (this.conversation?.typeId === ConversationType.meeting) {
      return true;
    } else {
      return false;
    }
  }

  get isVideoCall(): boolean {
    if (this.conversation?.typeId === ConversationType.videoCall) {
      return true;
    } else {
      return false;
    }
  }

  get isEmail(): boolean {
    if (this.conversation?.typeId === ConversationType.email) {
      return true;
    } else {
      return false;
    }
  }

  get isAnalysed(): boolean {
    if (
      !this.isCall ||
      this.conversation?.call?.callAnalysis.status.id == CallAnalysisStatus.analyzed
    ) {
      return true;
    } else {
      return false;
    }
  }

  getPlayer() {
    if (this.isCall) {
      return this.callDetailTabPanel?.player;
    } else if (this.isMeeting) {
      return this.meetingDetailTabPanel?.player;
    } else if (this.isVideoCall) {
      return this.videoCallDetailTabPanel?.player;
    }
  }

  getActiveTab() {
    if (this.isCall) {
      return this.callDetailTabPanel?.activeTab;
    } else if (this.isChat) {
      return this.chatDetailTabPanel?.activeTab;
    } else if (this.isMeeting) {
      return this.meetingDetailTabPanel?.activeTab;
    } else if (this.isVideoCall) {
      return this.videoCallDetailTabPanel?.activeTab;
    } else if (this.isEmail) {
      return this.emailDetailTabPanel?.activeTab;
    }
  }

  goBack() {
    const routeSnapshot = this.route.snapshot;
    const queryParams = routeSnapshot.queryParams;

    // Set cache option to true, when going back to list so that
    // conversation service can return last cached conversation list.
    const action = new CacheOptionChange(true);
    this.store.dispatch(action);

    if (this.referrer === ConversationReferrer.nonFcr) {
      this.router.navigate(['/analysis/non-fcr'], {
        queryParams: {
          continue: true,
        },
      });
    } else if (this.referrer === ConversationReferrer.evaluationResult) {
      this.router.navigate(['/quality/evaluation-result']);
    } else {
      if (queryParams.resultId != null) {
        this.router.navigate(['/conversation', 'result', queryParams.resultId]);
      } else {
        this.router.navigate(['/conversation']);
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private conversationService: ConversationService,
    private localizationService: LocalizationService,
    private permissionService: PermissionService,
    private stringService: StringService,
    private modalService: NgbModal,
    private toastr: ToasterService,
    private store: Store,
    private service: CrudService,
    private configStateService: ConfigStateService,
    private videoPlayerService: VideoPlayerService,
    private titleService: Title
  ) {
    super();

    this.referrer = this.route.snapshot.queryParams.referrer;

    this.canDeleteConversation = this.permissionService.getGrantedPolicy('Conversation._Delete');
    this.canEvaluateConversation = this.permissionService.getGrantedPolicy(
      'Conversation.CanEvaluateConversation'
    );

    this.router.events
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(e => {
        const navigationState = this.router.getCurrentNavigation().extras.state;

        this.enableQuickNavigation =
          navigationState && navigationState.enableQuickNavigation
            ? navigationState.enableQuickNavigation
            : false;
        this.topicIds =
          navigationState && navigationState.topicIds && navigationState.topicIds.length > 0
            ? navigationState.topicIds
            : [];
      });
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(params => {
      this.checkQueryParams(this.route.queryParams);
      this.load();
    });
  }

  load() {
    this.categories = [];
    this.id = this.route.snapshot.params.id;
    this.getConversation(this.id);
  }

  checkQueryParams(params: any) {
    if (params.value.quickSearchTerm) {
      this.quickSearchFilter = params.value.quickSearchTerm;
    }

    if (params.value.queryId) {
      this.queryId = params.value.queryId as number;
    }
  }

  setQuickSearchCategory(terms: string[]) {
    terms?.forEach(t => {
      let category: ConversationCategoryDto = {
        categoryColor: getComputedStyle(document.body).getPropertyValue('--info'),
        id: -1,
        queryVersionId: 0,
        categoryName: this.localizationService.instant('Conversation::SearchResults'),
        searchTermItem: {
          term: this.stringService.toLowerForCurrentCulture(t),
        },
        isSearchMarker: true,
      };
      this.categories.push(category);
    });
  }

  setTopicCategories(conversationTopics: ConversationTopicDto[]) {
    let id = -1;
    let index = 0;
    let colorCount = this.topicColorList.length;
    conversationTopics?.forEach(conversationTopic => {
      id--;
      let isSearchTopic = false;
      const idx = this.topicIds.findIndex(item => item === conversationTopic.topicId);
      if (idx > -1) {
        isSearchTopic = true;
      }
      let category: ConversationCategoryDto = {
        categoryColor: this.topicColorList[index++ % colorCount],
        id: id,
        queryVersionId: 0,
        categoryName: conversationTopic.topicName,
        isSearchTopic: isSearchTopic,
        topicTermItems: [],
      };
      conversationTopic.conversationTopicDetails.forEach(topicDetail => {
        let term: TopicTermItemDto = {
          term: topicDetail.token,
        };
        category.topicTermItems.push(term);
      });
      this.categories.push(category);
    });
  }

  setQueryResultCategory(queryCategory: ConversationCategoryDto) {
    if (queryCategory) {
      queryCategory.categoryColor = getComputedStyle(document.body).getPropertyValue('--ngu-blue');
      queryCategory.categoryName = this.localizationService.instant('Conversation::QueryResults');
      queryCategory.isSearchMarker = true;

      this.categories.push(queryCategory);
    }
  }

  setCommentCategory(conversationComments: ConversationCommentDto[]) {
    if (conversationComments.length > 0) {
      let category: ConversationCategoryDto = {
        categoryColor: '#94acce',
        id: -99,
        queryVersionId: 0,
        categoryName: this.localizationService.instant('::Comment'),
        commentTermItems: [],
        isComment: true,
      };
      conversationComments.forEach(conversationComment => {
        let term: CommentTermItemDto = {
          term: conversationComment.comment,
          startTime: conversationComment.startTime,
          endTime: conversationComment.endTime,
        };
        category.commentTermItems.push(term);
      });
      this.categories.push(category);
    }
  }

  getConversation(id: number) {
    const conversationRequest = this.conversationService.getById(id);
    const categoriesRequest = this.conversationService.getCategoriesByConversationId(id);
    const topicsRequest = this.conversationService.getTopicsByConversationId(id);
    const comments = this.conversationService.getCommentsByConversationId(id);
    const queryCategoryRequest = this.conversationService.getQueryByConversationId(
      id,
      this.queryId
    );

    combineLatest([
      conversationRequest,
      categoriesRequest,
      queryCategoryRequest,
      topicsRequest,
      comments,
    ])
      .pipe(take(1))
      .subscribe(([conversation, categories, queryCategory, topics, comments]) => {
        this.conversation = conversation;
        if (this.canEvaluateConversation) {
          let userId = this.configStateService.getDeep('currentUser.id');
          if (this.conversation?.userId == userId) {
            this.canEvaluateConversation = this.permissionService.getGrantedPolicy(
              'Conversation.CanEvaluateTheirOwnConversations'
            );
          }
        }
        this.setQuickSearchCategory(this.quickSearchFilter);
        this.setQueryResultCategory(queryCategory);
        this.categories = this.categories.concat(categories);
        this.setTopicCategories(topics);
        if (conversation.chat != null) {
          comments = [];
        }
        this.setCommentCategory(comments);
        let title = this.localizationService.instant('Conversation::Conversations');
        title = this.conversation.id + ' - ' + title;
        this.titleService.setTitle(title);
      });
  }

  onClickPreviousConversation(): void {
    const fromNonFcr = this.referrer === ConversationReferrer.nonFcr;
    const state = this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);
    const nonFcrState = this.store.selectSnapshot<NonFcrStateModel>(NonFcrState);
    const listData = fromNonFcr
      ? nonFcrState.conversations[nonFcrState.nonFcrMasterConversationId]?.data
      : state.data?.items;

    if (!listData) {
      this.toastr.warn('Conversation::ErrorOnFetchingPreviousConversation');

      return;
    }

    const idx = listData.findIndex(item => item.id === this.conversation.id);

    if (idx > -1) {
      let newIdx = idx - 1;

      newIdx = newIdx < 0 ? newIdx + listData.length : newIdx;

      const newConversation = listData[newIdx];
      const navigationExtras: NavigationExtras = {
        state: { enableQuickNavigation: true },
      };

      if (fromNonFcr) {
        navigationExtras.queryParams = { referrer: ConversationReferrer.nonFcr };
      }

      this.addDrillDownAnalysisResultParam(navigationExtras);

      this.videoPlayerService.close();

      this.router.navigate(['/conversation', newConversation.id], navigationExtras);
    } else {
      this.toastr.warn('Conversation::ErrorOnFetchingPreviousConversation');

      return;
    }
  }

  onClickNextConversation(): void {
    const fromNonFcr = this.referrer === ConversationReferrer.nonFcr;
    const state = this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);
    const nonFcrState = this.store.selectSnapshot<NonFcrStateModel>(NonFcrState);
    const listData = fromNonFcr
      ? nonFcrState.conversations[nonFcrState.nonFcrMasterConversationId]?.data
      : state.data?.items;

    if (!listData) {
      this.toastr.warn('Conversation::ErrorOnFetchingNextConversation');

      return;
    }

    const idx = listData.findIndex(item => item.id === this.conversation.id);

    if (idx > -1) {
      const newIdx = (idx + 1) % listData.length;
      const newConversation = listData[newIdx];
      const navigationExtras: NavigationExtras = {
        state: { enableQuickNavigation: true },
      };

      if (fromNonFcr) {
        navigationExtras.queryParams = { referrer: ConversationReferrer.nonFcr };
      }

      this.addDrillDownAnalysisResultParam(navigationExtras);

      this.videoPlayerService.close();

      this.router.navigate(['/conversation', newConversation.id], navigationExtras);
    } else {
      this.toastr.warn('Conversation::ErrorOnFetchingNextConversation');

      return;
    }
  }

  openDeleteConversationModal() {
    this.conversationDeleteModalObject = this.modalService.open(this.conversationDeleteModal, {
      size: 'md',
      scrollable: false,
      backdrop: 'static',
      keyboard: false,
    });
  }

  deleteConversation() {
    this.processing = true;
    this.service
      .delete<ConversationDto>(ConversationDto, this.conversation.id)
      .pipe(take(1))
      .subscribe({
        next: result => {
          const state =
            this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);
          state.data.items = state.data.items.filter(x => x.id != this.conversation.id);
          state.data.totalCount = state.data.items.length;
          this.processing = false;
          this.deleteConfirmationText = '';
          this.conversationDeleteModalObject.close();
          this.goBack();
        },
        error: () => {
          this.processing = false;
        },
      });
  }

  cancelDeleteConversation() {
    this.deleteConfirmationText = '';
    this.conversationDeleteModalObject.close();
  }

  private addDrillDownAnalysisResultParam(navigationExtras: NavigationExtras) {
    const resultId = this.route.snapshot.queryParams.resultId;

    if (resultId) {
      navigationExtras.queryParams = {
        ...navigationExtras.queryParams,
        resultId: resultId,
      };
    }
  }

  onAddCommentRequest(eventArgs) {
    let conversationComment = new ConversationCommentInputDto();
    conversationComment.conversationId = this.conversation.id;
    conversationComment.comment = eventArgs.comment;
    conversationComment.startTime = this.convertTimeString(eventArgs.startTime);
    conversationComment.endTime = this.convertTimeString(eventArgs.endTime);
    this.conversationService
      .addComment(conversationComment)
      .pipe(take(1))
      .subscribe(data => {
        if (this.isCall && this.callDetailTabPanel) {
          this.callDetailTabPanel.commentCount++;
          this.callDetailTabPanel.commentPanel.comments.unshift(data);
          this.callDetailTabPanel.onCommentCountChanged({
            comments: this.callDetailTabPanel.commentPanel.comments,
          });
          this.callDetailTabPanel.player.play();
        } else if (this.isChat && this.chatDetailTabPanel) {
          this.chatDetailTabPanel.commentCount++;
          this.chatDetailTabPanel.commentPanel?.comments.unshift(data);
        } else if (this.isVideoCall && this.videoCallDetailTabPanel) {
          this.videoCallDetailTabPanel.commentCount++;
          this.videoCallDetailTabPanel.commentPanel?.comments.unshift(data);
          this.videoCallDetailTabPanel.player.play();
        }

        this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
      });
  }

  convertTimeString(time) {
    if (time) {
      return time.split(':').length - 1 == 1 ? '00:' + time : time;
    }
    return null;
  }
}
