import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { NearQueryItemDto } from 'src/core/models/query/near-query-item.dto';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { first, map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntegerValidator } from '../../../../core/validators/shared/integer.validator';
import { SimpleTermValidator } from 'src/core/validators/query/simple-term.validator';
import { Operators } from 'src/core/models/request/operator.enum';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { QueryConstants } from 'src/core/constants/query-constant';
import { NearWithRangeQueryItemDto } from 'src/core/models/query/near-with-range-query-item.dto';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { QueryRangeUnit } from 'src/core/models/generic-lookup-type/query/query-range-unit.glt';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';

export enum NearQueryType {
  Basic = 1,
  Interval,
}

export enum NearQueryIntervalType {
  Opening = '1',
  Closing = '2',
}

@Component({
  selector: 'ca-query-tree-node-near',
  templateUrl: './query-tree-node-near.component.html',
  styleUrls: ['./query-tree-node-near.component.scss'],
})
export class QueryTreeNodeNearComponent extends QueryEditorBase implements OnInit {
  payload: any;
  conversationSides$: Observable<GenericLookupDto[]>;
  queryForm: FormGroup = null;
  min = 1;
  max = 10;
  currentConversationSide: string;
  firstTermInternal: string;
  secondTermInternal: string;
  conversationSideAny: number = ConversationSide.any;
  conversationType = ConversationType;

  inputPhrases$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  rangeUnits$: Observable<GenericLookupDto[]>;
  rangeUnitSecondsId: number;
  NearQueryType = NearQueryType;
  NearQueryIntervalType = NearQueryIntervalType;
  private currentNearQueryTypeInternal: NearQueryType = NearQueryType.Basic;
  private currentNearQueryIntervalTypeInternal = NearQueryIntervalType.Opening;
  readonly RANGE_SECONDS = QueryConstants.RANGE_OPENING_END_SECOND.toString();

  formRangeUnitId: number;
  formStartTime: number;
  formEndTime: number;
  formSideId: any;

  intervalAgentQueryFirstNWords: string = '';
  intervalAgentQueryLastNWords: string = '';
  intervalCustomerQueryFirstNWords: string = '';
  intervalCustomerQueryLastNWords: string = '';

  get currentNearQueryType(): NearQueryType {
    return this.currentNearQueryTypeInternal;
  }

  get currentNearQueryIntervalType(): NearQueryIntervalType {
    return this.currentNearQueryIntervalTypeInternal;
  }

  constructor(
    private store: Store,
    private fb: FormBuilder,
    public operators: Operators,
    private genericLookupService: GenericLookupTypeService,
    private queryFieldDataType: QueryFieldDataType,
    private localizationService: LocalizationService,
    private config: ConfigStateService,
  ) {
    super();

    this.intervalAgentQueryFirstNWords = this.config.getSetting('UserDefinedCategory.AgentBeginningWordCount');
    this.intervalAgentQueryLastNWords = this.config.getSetting('UserDefinedCategory.AgentEndingWordCount');
    this.intervalCustomerQueryFirstNWords = this.config.getSetting('UserDefinedCategory.CustomerBeginningWordCount');
    this.intervalCustomerQueryLastNWords = this.config.getSetting('UserDefinedCategory.CustomerEndingWordCount');

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.rangeUnits$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(QueryRangeUnit)));

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.rangeUnits$
      .pipe(
        first(),
        mergeMap(x => x.filter(y => y.code == QueryConstants.RANGE_UNIT_SECONDS_CODE))
      )
      .subscribe(data => {
        this.rangeUnitSecondsId = data.id;
      });

    this.queryForm = this.fb.group(
      {
        firstTerm: [
          null,
          {
            validators: [Validators.required, SimpleTermValidator.Validator],
          },
        ],
        secondTerm: [
          null,
          {
            validators: [Validators.required, SimpleTermValidator.Validator],
          },
        ],
        firstOperator: [null],
        secondOperator: [null],
        maximumDistance: [
          null,
          {
            validators: [Validators.required, IntegerValidator.minMax(this.min, this.max)],
          },
        ],
        conversationSide: [null],
        not: [null],
        searchInOrder: [null],
        intervalType: [NearQueryIntervalType.Opening],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('firstTerm').valueChanges.subscribe(value => {
      this.queryForm.updateValueAndValidity();
      if (this.queryForm.valid) {
        this.setPayloadData();
      }
    });

    this.queryForm.get('secondTerm').valueChanges.subscribe(value => {
      this.queryForm.updateValueAndValidity();
      if (this.queryForm.valid) {
        this.setPayloadData();
      }
    });

    this.queryForm.get('conversationSide').valueChanges.subscribe(value => {
      this.formSideId = this.queryForm.get('conversationSide').value;
      this.payload.sideId = this.formSideId;
      this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
        this.payload.sideId
      )?.code;
    });

    this.queryForm.get('intervalType').valueChanges.subscribe(value => {
      const intervalType: NearQueryIntervalType = this.queryForm.get('intervalType').value;
      this.formRangeUnitId = this.rangeUnitSecondsId;
      this.payload.rangeUnitId = this.formRangeUnitId;

      if (intervalType === NearQueryIntervalType.Opening) {
        this.formStartTime = QueryConstants.RANGE_OPENING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_OPENING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      } else {
        this.formStartTime = QueryConstants.RANGE_CLOSING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_CLOSING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      }
    });
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload;
    let dtoType = this.node.categoryItem.dataType;

    this.firstTermInternal =
      this.payload.firstOperator === this.operators.Contains
        ? this.payload.firstTerm + '*'
        : this.payload.firstTerm.trim();
    this.secondTermInternal =
      this.payload.secondOperator === this.operators.Contains
        ? this.payload.secondTerm + '*'
        : this.payload.secondTerm.trim();
    this.formSideId = this.payload.sideId;

    switch (dtoType) {
      case this.queryFieldDataType.NearQuery:
        this.currentNearQueryTypeInternal = NearQueryType.Basic;
        this.queryForm.get('firstTerm').patchValue(this.firstTermInternal);
        this.queryForm.get('secondTerm').patchValue(this.secondTermInternal);
        this.changeQueryType(NearQueryType.Basic, true);
        break;
      case this.queryFieldDataType.NearQueryWithRange:
        this.currentNearQueryTypeInternal = NearQueryType.Interval;
        this.queryForm.get('firstTerm').patchValue(this.firstTermInternal);
        this.queryForm.get('secondTerm').patchValue(this.secondTermInternal);
        if (
          this.payload.startTime === QueryConstants.RANGE_OPENING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_OPENING_END_SECOND
        ) {
          this.currentNearQueryIntervalTypeInternal = NearQueryIntervalType.Opening;
          this.queryForm.get('intervalType').patchValue(NearQueryIntervalType.Opening);
        } else if (
          this.payload.startTime === QueryConstants.RANGE_CLOSING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_CLOSING_END_SECOND
        ) {
          this.currentNearQueryIntervalTypeInternal = NearQueryIntervalType.Closing;
          this.queryForm.get('intervalType').patchValue(NearQueryIntervalType.Closing);
        }
        this.changeQueryType(NearQueryType.Interval, true);
        break;
    }

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      const conversationSide = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationSide = conversationSide.name;
      this.payload.sideCode = conversationSide.code;
    });

    this.updateInputPhrases();
  }

  getSiblingNodePhrases(): string[] {
    return this.queryBuilder.getSiblingNodePhrasesOfNode(this.node);
  }

  getOpeningIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
    }
    else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryFirstNWords);
    }
    else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideFirstQueryNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryFirstNWords);
    }
    return tooltip;
  }

  getClosingIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
    }
    else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryLastNWords);
    }
    else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryLastNWords);
    }
    return tooltip;
  }

  updateInputPhrases() {
    this.inputPhrases$.next(this.getSiblingNodePhrases());
  }

  setPayloadData() {
    const newValueFirst = this.queryForm.get('firstTerm').value;
    this.payload.firstOperator = newValueFirst.endsWith('*')
      ? this.operators.Contains
      : this.operators.Equals;
    this.payload.firstTerm = newValueFirst.endsWith('*')
      ? newValueFirst.substring(0, newValueFirst.length - 1)
      : newValueFirst;

    const newValueSecond = this.queryForm.get('secondTerm').value;
    this.payload.secondOperator = newValueSecond.endsWith('*')
      ? this.operators.Contains
      : this.operators.Equals;
    this.payload.secondTerm = newValueSecond.endsWith('*')
      ? newValueSecond.substring(0, newValueSecond.length - 1)
      : newValueSecond;
  }

  onQueryTypeMenuItemClicked(queryType: NearQueryType) {
    this.changeQueryType(queryType, false);
  }

  private changeQueryType(queryType: NearQueryType, initial: boolean) {
    this.currentNearQueryTypeInternal = queryType;
    let sideCode = initial
      ? this.genericLookupService.findGenericLookupWithId(this.payload.sideId)?.code
      : this.payload.sideCode;
    switch (queryType) {
      case NearQueryType.Basic:
        let payloadBasic = new NearQueryItemDto();
        payloadBasic.internalId = this.payload.internalId;
        payloadBasic.parentInternalId = this.payload.parentInternalId;
        payloadBasic.categoryId = this.payload.categoryId;
        payloadBasic.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_NEAR;
        payloadBasic.not = this.payload.not;
        payloadBasic.firstTerm = this.payload.firstTerm;
        payloadBasic.firstOperator = this.payload.firstOperator;
        payloadBasic.secondTerm = this.payload.secondTerm;
        payloadBasic.secondOperator = this.payload.secondOperator;
        payloadBasic.maximumDistance = this.payload.maximumDistance;
        payloadBasic.searchInOrder = this.payload.searchInOrder;
        payloadBasic.id = initial ? this.payload.id : 0;
        payloadBasic.queryId = this.payload.queryId;
        payloadBasic.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadBasic.sideCode = sideCode;

        this.payload = payloadBasic;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.NearQuery;
        break;
      case NearQueryType.Interval:
        if (initial == false) {
          this.queryForm.get('intervalType').patchValue(NearQueryIntervalType.Opening);
        }
        let payloadInterval = new NearWithRangeQueryItemDto();

        payloadInterval.internalId = this.payload.internalId;
        payloadInterval.parentInternalId = this.payload.parentInternalId;
        payloadInterval.categoryId = this.payload.categoryId;
        payloadInterval.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_NEAR_INTERVAL;
        payloadInterval.not = this.payload.not;
        payloadInterval.firstOperator = this.payload.firstOperator;
        payloadInterval.firstTerm = this.payload.firstTerm;
        payloadInterval.secondOperator = this.payload.secondOperator;
        payloadInterval.secondTerm = this.payload.secondTerm;
        payloadInterval.maximumDistance = this.payload.maximumDistance;
        payloadInterval.searchInOrder = this.payload.searchInOrder;
        payloadInterval.id = initial ? this.payload.id : 0;
        payloadInterval.queryId = this.payload.queryId;
        payloadInterval.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadInterval.sideCode = sideCode;

        payloadInterval.startTime = initial ? this.payload.startTime : this.formStartTime;
        payloadInterval.endTime = initial ? this.payload.endTime : this.formEndTime;
        payloadInterval.rangeUnitId = this.formRangeUnitId ?? this.rangeUnitSecondsId;

        this.payload = payloadInterval;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.NearQueryWithRange;
        break;
    }

    if (!initial) {
      this.node.isDirty = true;
    }
  }
}
