import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { MergedQueryItemDto } from 'src/core/models/query/merged-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { QueryDto } from 'src/core/models/query/query.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-query-tree-node-merge',
  templateUrl: './query-tree-node-merge.component.html',
  styleUrls: ['./query-tree-node-merge.component.scss'],
})
export class QueryTreeNodeMergeComponent extends QueryEditorBase implements OnInit {
  payload: MergedQueryItemDto;
  queryForm: FormGroup;
  currentQueryName: string;
  currentQueryId: number;
  isInitialLoad = true;
  headerInfo: string = this.localizationService.instant('Query::MergeSelectorInformation');
  headerInfoStyle: unknown = {
    color: 'red',
    'font-style': 'italic',
    'font-size': '0.8em',
  };

  constructor(
    private operators: Operators,
    private localizationService: LocalizationService,
    private fb: FormBuilder,
    private service: CrudService
  ) {
    super();

    this.queryForm = fb.group(
      {
        query: [null, { validators: [Validators.required], updateOn: 'change' }],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.get('query').valueChanges.subscribe((selectedCategory: QueryDto) => {
      this.onChange(selectedCategory);
    });
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as MergedQueryItemDto;
    this.payload.operator = this.operators.Equals;
  }

  ngAfterViewInit() {
    if (this.payload.mergedQueryId) {
      this.service.getById<QueryDto>(QueryDto, this.payload.mergedQueryId).subscribe(response => {
        this.currentQueryName = response.name;
        this.queryForm.get('query').setValue(response);
      });
    }
  }

  onChange(selectedCategory: QueryDto) {
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
    } else {
      this.node.isDirty = true;
    }

    if (selectedCategory) {
      this.payload.mergedQueryId = selectedCategory.id;
    }
  }

  disableFn(category: QueryDto): boolean {
    return !category.mergeable;
  }
}
