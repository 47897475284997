import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { ExcelService } from 'src/core/services/excel/excel.service';

@Component({
  selector: 'ca-evaluation-result-export-ready-notification',
  templateUrl: './evaluation-result-export-ready-notification.component.html',
  styleUrls: ['./evaluation-result-export-ready-notification.component.scss'],
})
@NotificationType(
  'EvaluationResultExportReadyNotification',
  EvaluationResultExportReadyNotificationComponent
)
export class EvaluationResultExportReadyNotificationComponent implements OnInit {
  data: NotificationDto;

  constructor(
    private excelService: ExcelService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'QualityManagement::ClickHereToDownloadEvaluationResultsReport'
    );

    var toastrId = toastr.success(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(
        injector,
        notificationService,
        notificationId,
        parsedPayload.fileId,
        localizationService
      );
    });
  }

  static toasterClickedHandler(
    injector: Injector,
    notificationService: NotificationService,
    notificationId: string,
    fileId: string,
    localizationService: LocalizationService
  ) {
    const fileName = localizationService.instant('QualityManagement::ExcelExport.FileName');
    injector.get(ExcelService).downloadExcelReport(fileId, fileName);
    notificationService.markAsRead(notificationId).pipe(take(1)).subscribe();
  }

  downloadFile() {
    const parsedPayload = JSON.parse(this.data.payload);
    this.downloadFileById(parsedPayload.fileId);
    this.notificationService
      .getReadStatus(this.data.id)
      .pipe(take(1))
      .subscribe(result => {
        if (!result) {
          this.notificationService.markAsRead(this.data.id).pipe(take(1)).subscribe();
        }
      });
  }

  downloadFileById(fileId: string) {
    var fileName = this.localizationService.instant('QualityManagement::ExcelExport.FileName');
    this.excelService
      .getExcelReport(fileId, fileName)
      .pipe(take(1))
      .subscribe({
        next: result => {
          if (result.size > 0) {
            downloadBlob(
              result,
              this.localizationService.instant('QualityManagement::ExcelExport.FileName')
            );
          }
        },
        error: () => {
          this.toastr.error('Conversation::DownloadFileError');
        },
      });
  }
}
