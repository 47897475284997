import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CategoryOccurrenceElement } from 'src/core/models/conversation/category/category-occurrence-element';
import { CategoryGroupBy } from 'src/core/models/conversation/category/category-group-by';
import { ColorUtils } from 'src/core/helpers/color-utils';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ChatCategoryMarkerDto } from 'src/ca-shared/player/models/chat-category-marker.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { LocalizationService } from '@abp/ng.core';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';

@Component({
  selector: 'ca-chat-category-detail-panel',
  templateUrl: './chat-category-detail-panel.component.html',
  styleUrls: ['./chat-category-detail-panel.component.scss'],
})
export class ChatCategoryDetailPanelComponent implements OnInit {
  @BlockUI('table') blockUI: NgBlockUI;
  dataSource: any;
  displayedColumns: string[] = ['time', 'channel', 'word'];
  private _categoryMarks: ChatCategoryMarkerDto[];
  private _categories: ConversationCategoryDto[];
  @Output()
  categoryRowClick: EventEmitter<{
    markerInfo: ChatCategoryMarkerDto;
  }> = new EventEmitter();

  @Input()
  set categoryMarks(categoryMarks: ChatCategoryMarkerDto[]) {
    this.blockUI.stop();
    this.clearDataSource();
    this._categoryMarks = categoryMarks;
    this.loadCategoryData();
  }

  @Input()
  set categories(categories: ConversationCategoryDto[]) {
    this._categories = categories;
  }

  @Input()
  conversationId: number;

  get categoryMarks() {
    return this._categoryMarks;
  }

  get categories(): ConversationCategoryDto[] {
    return this._categories;
  }

  constructor(
    public colorUtils: ColorUtils,
    private caDatePipe: CADatePipe,
    private localizationService: LocalizationService
  ) {
    const ELEMENT_DATA: (CategoryOccurrenceElement | CategoryGroupBy)[] = [];

    this.dataSource = ELEMENT_DATA;
  }

  getMarkGroupByCategory() {
    return this._categoryMarks.reduce(function (rv, x) {
      (rv[x['categoryName']] = rv[x['categoryName']] || []).push(x);
      return rv;
    }, {});
  }

  getCategoryIconClass(category: ChatCategoryMarkerDto) {
    if (category.isSearchMarker) {
      return 'fas fa-search';
    } else if (category.isTopicMarker) {
      return 'far fa-circle';
    } else if (category.isCommentMarker) {
      return 'far fa-comment';
    } else {
      return 'fas fa-circle';
    }
  }

  loadCategoryData() {
    const categoriesGroupedList = this.getMarkGroupByCategory();

    let firstTopicRecord = true;
    let firstCategoryRecord = true;
    for (let category in categoriesGroupedList) {
      let groupHeader = '';
      let tempList: ChatCategoryMarkerDto[] = categoriesGroupedList[category].sort(
        (a, b) => a.startMillisecond - b.startMillisecond
      );
      if (firstTopicRecord && categoriesGroupedList[category][0].isTopicMarker === true) {
        groupHeader = this.localizationService.instant('::Topics');
        firstTopicRecord = false;
      } else if (
        firstCategoryRecord &&
        categoriesGroupedList[category][0].isTopicMarker != true &&
        categoriesGroupedList[category][0].isSearchMarker != true
      ) {
        groupHeader = this.localizationService.instant('Conversation::Categories');
        firstCategoryRecord = false;
      }
      const header = {
        groupHeader: groupHeader,
        initial: tempList[0].categoryName,
        isGroupBy: true,
        isSearchMarker: tempList[0].isSearchMarker,
        isTopicMarker: tempList[0].isTopicMarker,
        color: tempList[0].color,
      };
      this.dataSource.push(header);
      tempList.forEach(item => {
        let markModel = {
          word: item.text,
          categoryName: item.categoryName,
          time: this.caDatePipe.transform(new Date(item.timeStamp), DateDisplayType.Time),
          color: item.color,
          channel: item.channel,
          words: item.words,
          editable: false,
        };
        this.dataSource.push(markModel);
      });
    }
    this.dataSource = [...this.dataSource];
  }

  clearDataSource() {
    this.dataSource = [];
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  onRowClick(markerInfo: ChatCategoryMarkerDto) {
    this.categoryRowClick.emit({ markerInfo: markerInfo });
  }

  ngOnInit(): void {}
}
