import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { ConversationDetailTabStatus } from 'src/core/models/conversation/conversation-detail-tab-status.enum';
import { ConversationSummaryDto } from 'src/core/models/conversation/conversation-summary.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { EvaluationPanelStatus } from 'src/core/models/conversation/evaluation-panel-status.enum';
import { ConversationTranscriptDto } from 'src/core/models/conversation/transcript/conversation-transcript.dto';

export class ConversationChange {
  static readonly type = '[ConversationModule] ConversationChange';

  constructor(
    public id: number,
    public conversation: ConversationDto,
    public transcript: ConversationTranscriptDto,
    public comments: ConversationCommentDto[],
    public summary: ConversationSummaryDto,
    public preserveTab: boolean = false
  ) {}
}

export class ActiveTabChange {
  static readonly type = '[ConversationModule] ActiveTabChange';

  constructor(public activeTab: string) {}
}

export class EvaluationPanelStatusChange {
  static readonly type = '[ConversationModule] EvaluationPanelStatusChange';

  constructor(public newStatus: EvaluationPanelStatus) {}
}

export class EvaluationParametersChange {
  static readonly type = '[ConversationModule] EvaluationParametersChange';

  constructor(
    public formId: number | null = null,
    public formVersionId: number | null = null,
    public evaluationResultId: number | null = null,
    public evaluationMasterId: number | null = null,
    public evaluationType: number,
    public isEvaluating: boolean
  ) {}
}

export class CommentAdded {
  static readonly type = '[ConversationModule] CommendAdded';

  constructor() {}
}

export class CommentDeleted {
  static readonly type = '[ConversationModule] CommentDeleted';

  constructor() {}
}

export class CommentsLoaded {
  static readonly type = '[ConversationModule] CommentsLoaded';

  constructor(public comments: ConversationCommentDto[]) {}
}

export class TabStatusChange {
  static readonly type = '[ConversationModule] TabStatusChange';

  constructor(public tabId: string, public newStatus: ConversationDetailTabStatus) {}
}

export class SkipDeactivationCheckChange {
  static readonly type = '[ConversationModule] SkipDeactivationCheckChange';

  constructor(public value: boolean) {}
}

export class SummaryChange {
  static readonly type = '[ConversationModule] SummaryChange';

  constructor(public newSummary: ConversationSummaryDto) {}
}
