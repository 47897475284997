<ng-template #itemTemplate let-item="item">
  <div class="truncate">{{ item.name }}</div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="truncate">{{ item.name }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    #automaticAssignmentSelector
    formControlName="editor"
    [url]="automaticAssignmentSelectorOptions.url"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="automaticAssignmentSelectorOptions.queryOperator"
    [queryField]="automaticAssignmentSelectorOptions.queryField"
    [multiple]="automaticAssignmentSelectorOptions.multiple"
    [emptyText]="automaticAssignmentSelectorOptions.emptyText"
    [showSelections]="automaticAssignmentSelectorOptions.showSelections"
    [showClearSelections]="automaticAssignmentSelectorOptions.showClearSelections"
    [showClearSelectionLink]="automaticAssignmentSelectorOptions.showClearSelectionLink"
    [selectorPosition]="automaticAssignmentSelectorOptions.selectorPosition"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    [filters]="automaticAssignmentSelectorOptions.filters"
    [sorters]="automaticAssignmentSelectorOptions.sorters"
    [pageSize]="automaticAssignmentSelectorOptions.pageSize"
    [currentPage]="automaticAssignmentSelectorOptions.currentPage"
  ></ca-selector>
</ng-container>
