import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ColorUtils } from 'src/core/helpers/color-utils';
@Component({
  selector: 'ca-conversation-category-panel',
  templateUrl: './conversation-category-panel.component.html',
  styleUrls: ['./conversation-category-panel.component.scss'],
})
export class ConversationCategoryPanelComponent implements OnInit {
  private _categories: ConversationCategoryDto[] = [];
  private _selectedCategories: number[] = [];
  @Input()
  set conversationId(conversationId: number) {
    if (conversationId) {
      this.getCategoriesByConversationId(conversationId);
    }
  }
  @Input()
  set categories(categories: Array<ConversationCategoryDto>) {
    this._categories = categories;
    const hasSearch = categories.filter(
      category => category.isSearchMarker == true || category.isSearchTopic == true || category.isComment == true
    );
    if (hasSearch && hasSearch.length > 0) {
      hasSearch.forEach(category => {
        this._selectedCategories.push(category.id);
      });
    }
  }
  @Input()
  set selectedCategories(categories: Array<number>) {
    this._selectedCategories = categories;
  }

  @Output()
  categorySelectionChanged: EventEmitter<{
    selectedCategories: number[];
  }> = new EventEmitter();

  get categories(): ConversationCategoryDto[] {
    return this._categories.filter(x => x.rootQueryId == null);
  }
  constructor(private conversationService: ConversationService, public colorUtils: ColorUtils) { }

  ngOnInit(): void { }

  getCategoriesByConversationId(id: number) {
    return this.conversationService.getCategoriesByConversationId(id).subscribe(data => {
      this.categories = data;
    });
  }

  getCategoryToggleClass(categoryId: number) {
    const searchResult = this._selectedCategories.find(x => x === categoryId);
    if (searchResult) {
      return 'badge category-badge category-box';
    } else {
      return 'badge category-badge category-box disabled';
    }
  }

  getCategoryIconClass(category: ConversationCategoryDto) {
    if (category.isSearchMarker) {
      return 'fas fa-search';
    } else if (category.topicTermItems) {
      return 'far fa-circle';
    } else if (category.isComment) {
      return 'far fa-comment';
    } else {
      return 'fas fa-circle';
    }
  }

  onCategoryClick(category: ConversationCategoryDto) {
    const searchResult = this._selectedCategories.find(x => x === category.id);
    if (searchResult) {
      this._selectedCategories = this._selectedCategories.filter(x => x !== category.id);
    } else {
      this._selectedCategories.push(category.id);
    }
    this.categorySelectionChanged.emit({ selectedCategories: this._selectedCategories });
  }
}
