import { Component, OnInit } from '@angular/core';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FeatureService } from 'src/core/services/feature/feature.service';

@Component({
  selector: 'ca-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss'],
})
export class IntegrationSettingsComponent implements OnInit {
  activeTab = 1;
  isGenesysIntegrationActive: boolean = true;
  isZoomMeetingIntegrationActive: boolean = true;
  isZoomContactCenterIntegrationActive: boolean = true;
  isAloTechIntegrationActive: boolean = true;
  isPuzzelIntegrationActive: boolean = true;
  isZendeskIntegrationActive: boolean = true;
  isAmazonIntegrationActive: boolean = true;
  isTeamsIntegrationActive: boolean = true;
  isFive9IntegrationActive: boolean = true;

  constructor(
    private featureService: FeatureService,
  ) {
    if (
      !this.featureService.isEnabled(FeatureConstants.GenesysIntegration) &&
      !this.featureService.isEnabled(FeatureConstants.GenesysIntegrationOutOfMarketplace)
    ) {
      this.isGenesysIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.ZoomMeetingIntegration)) {
      this.isZoomMeetingIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.ZoomContactCenterIntegration)) {
      this.isZoomContactCenterIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.AloTechIntegration)) {
      this.isAloTechIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.PuzzelIntegration)) {
      this.isPuzzelIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.ZendeskIntegration)) {
      this.isZendeskIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.AmazonIntegration)) {
      this.isAmazonIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.TeamsIntegration)) {
      this.isTeamsIntegrationActive = false;
    }

    if (!this.featureService.isEnabled(FeatureConstants.Five9Integration)) {
      this.isFive9IntegrationActive = false;
    }

    if (this.isGenesysIntegrationActive) {
      this.activeTab = 1;
    } else if (this.isZoomMeetingIntegrationActive) {
      this.activeTab = 2
    } else if (this.isZoomContactCenterIntegrationActive) {
      this.activeTab = 3
    } else if (this.isAloTechIntegrationActive) {
      this.activeTab = 4
    } else if (this.isPuzzelIntegrationActive) {
      this.activeTab = 5
    } else if (this.isZendeskIntegrationActive) {
      this.activeTab = 6
    } else if (this.isAmazonIntegrationActive) {
      this.activeTab = 7
    } else if (this.isTeamsIntegrationActive) {
      this.activeTab = 8
    } else if (this.isFive9IntegrationActive) {
      this.activeTab = 9
    }
  }

  ngOnInit(): void {}
}
