import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { EvaluationType } from 'src/core/models/generic-lookup-type/quality/evaluation-type.glt';
import { ObjectionStatus } from 'src/core/models/generic-lookup-type/quality/objection-status.glt';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { EvaluationService } from 'src/core/services/evaluation/evaluation-service';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { EvaluationReferrer } from 'src/core/constants/evaluation-referrer.constant';

@Component({
  selector: 'ca-evaluation-objection-evaluator-notification',
  templateUrl: './evaluation-objection-evaluator-notification.component.html',
  styleUrls: ['./evaluation-objection-evaluator-notification.component.scss'],
})
@NotificationType(
  'EvaluationObjectionEvaluatorNotification',
  EvaluationObjectionEvaluatorNotificationComponent
)
export class EvaluationObjectionEvaluatorNotificationComponent implements OnInit {
  data: NotificationDto;
  isObjected: boolean = true;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  get recipientId(): string {
    return this.data?.recipientId;
  }

  evaluationType = EvaluationType;

  constructor(
    private notificationService: NotificationService,
    private evaluationService: EvaluationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'QualityManagement::EvaluationObjectionEvaluatorToastrAndNotificationMessage'
    );

    const configStateService = injector.get(ConfigStateService);

    const currentUserId = configStateService.getDeep('currentUser.id');

    var toastrId = toastr.info(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(
        notificationService,
        notificationId,
        parsedPayload,
        router,
        currentUserId
      );
    });
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    payload: any,
    router: Router,
    currentUserId: string
  ) {
    notificationService.markAsRead(notificationId).subscribe();
    this.navigateToEvaluation(payload, router, currentUserId);
  }

  static navigateToEvaluation(payloadData: any, router: Router, currentUserId: string) {
    const queryParams: any = {
      evaluationType:
        payloadData.evaluatorId == currentUserId
          ? EvaluationType.reEvaluation
          : EvaluationType.calibration,
      evaluationId: payloadData.evaluationId,
      evaluationResultId: payloadData.evaluationResultId,
      objectionId: payloadData.objectionId,
      referrer: EvaluationReferrer.evaluationResult,
    };

    router.navigate(
      [
        'quality',
        'evaluate',
        'conversation',
        payloadData.conversationId,
        'with-form-version',
        payloadData.formId,
        payloadData.formVersionId,
      ],
      {
        queryParams: queryParams,
      }
    );
  }

  onLinkClicked() {
    if (this.data.markedAsRead === false) {
      this.notificationService.markAsRead(this.data.id).subscribe();
    }

    if (this.payloadData && this.payloadData.objectionId) {
      this.evaluationService.getObjectionStatus(this.payloadData.objectionId).subscribe(status => {
        this.isObjected = status == ObjectionStatus.objected;
      });
    }

    if (this.isObjected) {
      const queryParams = {
        evaluationType:
          this.payloadData?.evaluatorId == this.recipientId
            ? this.evaluationType.reEvaluation
            : this.evaluationType.calibration,
        evaluationId: this.payloadData?.evaluationId,
        evaluationResultId: this.payloadData?.evaluationResultId,
        objectionId: this.payloadData?.objectionId,
        referrer: 'evaluation-result',
      };

      this.router.navigate(
        [
          '/quality/evaluate/conversation/',
          this.payloadData?.conversationId,
          'with-form-version',
          this.payloadData?.formId,
          this.payloadData?.formVersionId,
        ],
        {
          queryParams: queryParams,
        }
      );
    } else {
      const queryParams = {
        evaluationResultId: this.payloadData?.evaluationResultId,
        referrer: 'evaluation-result',
        objectionId: this.payloadData?.objectionId,
      };

      this.router.navigate(['/quality/evaluation/', this.payloadData?.evaluationId], {
        queryParams: queryParams,
      });
    }
  }
}
