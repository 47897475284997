import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { EvaluationType } from 'src/core/models/generic-lookup-type/quality/evaluation-type.glt';
import { ObjectionStatus } from 'src/core/models/generic-lookup-type/quality/objection-status.glt';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-evaluation-objection-agent-notification',
  templateUrl: './evaluation-objection-agent-notification.component.html',
  styleUrls: ['./evaluation-objection-agent-notification.component.scss'],
})
@NotificationType(
  'EvaluationObjectionAgentNotification',
  EvaluationObjectionAgentNotificationComponent
)
export class EvaluationObjectionAgentNotificationComponent implements OnInit {
  data: NotificationDto;
  message: string;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  evaluationType = EvaluationType;

  constructor(
    private notificationService: NotificationService,
    private localizationService: LocalizationService
  ) { }

  ngOnInit(): void {
    this.message = this.localizationService.instant(
      'QualityManagement::AcceptedObjectionAgentToastrAndNotificationMessage',
      this.payloadData.evaluationResultId
    );

    if (this.payloadData.statusId == ObjectionStatus.rejected) {
      this.message = this.localizationService.instant(
        'QualityManagement::RejectedObjectionAgentToastrAndNotificationMessage',
        this.payloadData.evaluationResultId
      );
    }
  }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    let message = localizationService.instant(
      'QualityManagement::AcceptedObjectionAgentToastrAndNotificationMessage',
      parsedPayload.evaluationResultId
    );

    if (parsedPayload.statusId == ObjectionStatus.rejected) {
      message = localizationService.instant(
        'QualityManagement::RejectedObjectionAgentToastrAndNotificationMessage',
        parsedPayload.evaluationResultId
      );
    }

    var toastrId = toastr.info(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(
        notificationService,
        notificationId,
        parsedPayload,
        router
      );
    });
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    payload: any,
    router: Router
  ) {
    notificationService.markAsRead(notificationId).subscribe();
    this.navigateToEvaluation(payload, router);
  }

  static navigateToEvaluation(payloadData: any, router: Router) {
    router.navigate(['/quality/evaluation/', payloadData?.evaluationId], {
      queryParams: {
        evaluationResultId: payloadData?.evaluationResultId,
        referrer: 'evaluation-result',
      },
    });
  }

  onLinkClicked() {
    if (this.data.markedAsRead === true) {
      return;
    }
    this.notificationService.markAsRead(this.data.id).subscribe();
  }
}
