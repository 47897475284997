import { ChatTranscriptWord } from 'src/core/models/conversation/transcript/chat-transcript-word.model';

export class ChatCategoryMarkerDto {
  color: string;
  text: string;
  channel: number;
  categoryName: string;
  isSearchMarker?: boolean;
  isTopicMarker?: boolean;
  isCommentMarker?: boolean;
  timeStamp: Date;
  words: ChatTranscriptWord[];
}
