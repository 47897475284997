import { FormBuilder, FormControl } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from './../../../models/filter-panel-editor.component';
import { DateSelectionValueModel } from './../models/date-selection-value.model';
import { DateSelectionConfigurationModel } from './../models/date-selection-configuration.model';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ca-date-selection-panel',
  templateUrl: './date-selection-panel.component.html',
  styleUrls: ['./date-selection-panel.component.scss'],
})
export class DateSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit, AfterViewInit
{
  private actualConfig: DateSelectionConfigurationModel;
  private changedByShortcut = false;
  disableEndDate: boolean = false;
  tempEndDate: Date = new Date();
  maxDate: Date | null;
  minDate: Date | null;
  useNoEndDate: boolean = false;
  useShortcuts: boolean = true;
  hideTime: boolean = false;
  noEndDateChecked: boolean = false;
  disable: boolean = false;
  emptyDate: string;
  selectedShortcut: number = 0;

  @Output()
  public onChangeOut: EventEmitter<any> = new EventEmitter();

  @Input()
  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as DateSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  @Input()
  set disabled(value: boolean) {
    this.disable = value;
  }

  get disabled(): boolean {
    return this.disable;
  }

  get value(): DateSelectionValueModel {
    return this._value;
  }

  set value(value: DateSelectionValueModel) {
    this._value = value;

    if (this.actualConfig.maxDate) {
      this.maxDate = this.actualConfig.maxDate;
    }
    if (this.actualConfig.minDate) {
      this.minDate = this.actualConfig.minDate;
    }
    if (this.actualConfig.useNoEndDate) {
      this.useNoEndDate = this.actualConfig.useNoEndDate;
      this.onNoEndDateChange(value.noEndDate);
    }
    if (this.actualConfig.useShortcuts != null) {
      this.useShortcuts = this.actualConfig.useShortcuts;
    }
    if (this.actualConfig.hideTime != null) {
      this.hideTime = this.actualConfig.hideTime;
    }
    if (this.actualConfig.disableEndDate) {
      this.disableEndDate = this.actualConfig.disableEndDate;
    }

    if (value.shortcut != null) {
      this.onShortcutClick(value.shortcut);
    } else {
      setTimeout(() => {
        this.editorForm.patchValue(
          {
            start: value.start,
            end: value.end,
            editor: value,
          },
          { emitEvent: false }
        );
      }, 250);
    }
  }

  _value: DateSelectionValueModel = {
    shortcut: null,
    start: new Date(),
    end: new Date(),
    noEndDate: false,
  };

  init() {
    super.init();
  }
  
  autoSelectFirstItem(): void {}

  reload(): void {}

  reset(): void {}

  setStartDate(date: Date): void {
    this.editorForm.patchValue(
      {
        start: date,
      },
      { emitEvent: false }
    );
    this._value.start = date;
  }

  setEndDate(date: Date): void {
    this.editorForm.patchValue(
      {
        end: date,
      },
      { emitEvent: false }
    );
    this._value.end = date;
  }

  onNoEndDateChange(noEndDateChecked: boolean): void {
    this.noEndDateChecked = noEndDateChecked;
    this.disableEndDate = noEndDateChecked;
    if (noEndDateChecked) {
      this.tempEndDate = this.value.end;
      this.value.noEndDate = true;
    } else {
      this.value.noEndDate = false;
    }
  }

  onShortcutClick(diff: number, suppressEvent: boolean = false) {
    const start = new Date();
    const end = new Date();
    this.selectedShortcut = diff;

    this.changedByShortcut = !suppressEvent;
    this._value.shortcut = diff;

    if (diff > 0) {
      end.setDate(end.getDate() + diff);
    } else {
      start.setDate(start.getDate() + diff);

      if (diff === -1) {
        end.setDate(end.getDate() + diff);
      }
    }
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 0);

    this._value.start = start;
    this._value.end = end;
    setTimeout(() => {
      this.editorForm.patchValue(
        {
          start: start,
          end: end,
          editor: this._value,
        },
        { emitEvent: !suppressEvent }
      );
    }, 250);
  }

  constructor(protected fb: FormBuilder, private globalSettingsService: GlobalSettingsService) {
    super(fb);

    this.editorForm.addControl('start', new FormControl());
    this.editorForm.addControl('end', new FormControl());

    this.editorForm.valueChanges.pipe(debounceTime(100)).subscribe(val => {
      if (this.changedByShortcut) {
        this.changedByShortcut = false;
      } else {
        this.selectedShortcut = null;
        this._value.shortcut = null;
      }
      this._value.start = val.start;
      this._value.end = val.end;
      this.onChange();
      this.onChangeOut.emit();
    });

    this.emptyDate = this.globalSettingsService.systemDateFormat.replace(
      new RegExp('[a-zA-Z]', 'g'),
      '-'
    );
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {}
}
