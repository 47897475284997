import { PermissionService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input, OnInit } from '@angular/core';
import { ConversationService } from 'src/core/services/conversation/conversation.service';

@Component({
  selector: 'ca-conversation-export',
  templateUrl: './conversation-export.component.html',
  styleUrls: ['./conversation-export.component.scss'],
})
export class ConversationExportComponent implements OnInit {
  canExportConversation: boolean = false;

  @Input()
  isDisabled: boolean = false;

  constructor(
    private permissionService: PermissionService,
    private conversationService: ConversationService,
    private toastr: ToasterService
  ) {
    this.canExportConversation = this.permissionService.getGrantedPolicy(
      'Conversation.ConversationExport'
    );
  }

  ngOnInit(): void {}

  export() {
    this.conversationService.exportList().subscribe(() => {
      this.toastr.success('Conversation::ExportListRequestSuccessful');
    });
  }
}
