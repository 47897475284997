import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';
import { GenericLookupDto } from '../generic-lookup/generic-lookup.dto';
import { QueryDepartmentDto } from './query-department.dto';
import { DepartmentDto } from '../administration/department/department.dto';
import { MergedQueryItemDto } from './merged-query-item.dto';

@Api('api/app/query')
export class QueryDto extends DtoHasApi {
  id: number;
  name: string;
  active: boolean;
  creatorFullName: string;
  creationTime: Date;
  isCategory: boolean;
  queryTypeId: number;
  queryType: GenericLookupDto;
  queryTypeCode: string;
  queryDepartments: QueryDepartmentDto[];
  departments: DepartmentDto[];
  lastQueryVersionId: number;
  queryConversationTypeId: number;
  queryConversationTypeCode: string;
  categoryColor: string;
  categoryName: string;
  canMergeWithAnotherQuery?: boolean = true;
  queryItemDisableMessage?: string = '';
  mergeQueryItems: MergedQueryItemDto[];
  languageCodeId?: number;
  mergeable: boolean;
}
