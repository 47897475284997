import { ABP, ConfigStateService, RoutesService, TreeNode } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { RouteNames } from 'src/core/constants/route-names.constant';
import { takeUntil, timer } from 'rxjs';

export const APP_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService, ConfigStateService],
    multi: true,
  },
];

function configureRoutes(routesService: RoutesService, config: ConfigStateService) {
  return () => {
    var routes = [
      {
        name: '::Definitions',
        iconClass: 'far fa-ruler',
        parentName: eThemeSharedRouteNames.Administration,
        order: -1,
        path: '',
      },
      {
        name: '::AttachedData.TitleCase',
        iconClass: 'far fa-list-ul',
        order: 0,
        path: '/administration/attached-data',
        parentName: '::Definitions',
        requiredPolicy: 'DefinitionManagement.AttachedData || AbpAccount.SettingManagement',
      },
      {
        name: '::Departments',
        iconClass: 'far fa-building',
        order: 1,
        path: '/administration/departments',
        parentName: '::Definitions',
        requiredPolicy: 'DefinitionManagement.Department || AbpAccount.SettingManagement',
      },
      {
        name: '::GenericLookupTypes',
        iconClass: 'far fa-grip-lines',
        order: 2,
        path: '/administration/genericlookups',
        parentName: '::Definitions',
        requiredPolicy: 'DefinitionManagement.GenericLookup || AbpAccount.SettingManagement',
      },
      {
        name: '::IgnoredWords',
        iconClass: 'far fa-stream',
        order: 3,
        path: '/administration/ignored-phrases',
        parentName: '::Definitions',
        requiredPolicy: 'DefinitionManagement.IgnoredWords || AbpAccount.SettingManagement',
      },
      {
        name: '::Sources',
        iconClass: 'far fa-stream',
        order: 4,
        path: '/administration/sources',
        parentName: '::Definitions',
        requiredPolicy: 'DefinitionManagement.Sources || AbpAccount.SettingManagement',
      },
      {
        name: '::Integration',
        iconClass: 'far fa-project-diagram',
        order: 5,
        path: '/administration/integration',
        parentName: eThemeSharedRouteNames.Administration,
        requiredPolicy: 'DefinitionManagement.Integration',
      },
      {
        name: 'QualityManagement::ReasonPool',
        iconClass: 'far fa-project-diagram',
        order: 6,
        path: '/administration/reason',
        parentName: '::Definitions',
        forFeatures: [FeatureConstants.QualityManagement],
        requiredPolicy: 'DefinitionManagement.ReasonPool',
      },
      {
        name: '::Tag',
        iconClass: 'far fa-tag',
        order: 6,
        path: '/administration/tag',
        parentName: '::Definitions',
        requiredPolicy: 'DefinitionManagement.Tag',
      },
      {
        name: 'Conversation::CallRecorderManagement',
        iconClass: 'far fa-head-side-headphones',
        order: 0,
        path: '',
        parentName: eThemeSharedRouteNames.Administration,
        forFeatures: [FeatureConstants.CallRecorder],
      },
      {
        name: 'Conversation::CallRecorderManagement:Devices',
        iconClass: 'far fa-headset',
        order: 0,
        path: '/administration/devices',
        parentName: 'Conversation::CallRecorderManagement',
      },
      {
        name: 'Conversation::CallRecorderManagement:DeviceGroups',
        iconClass: 'far fa-layer-group',
        order: 1,
        path: '/administration/device-groups',
        parentName: 'Conversation::CallRecorderManagement',
      },
      {
        name: 'UserGroup::Menu:UserGroups',
        iconClass: 'far fa-users-class',
        order: 3,
        path: '/identity/user-groups',
        parentName: RouteNames.IdentityManagement,
        forFeatures: [FeatureConstants.UserGroupManagement],
        forRoles: ['Global.UserAccessScope.AllUsers'],
      },
      {
        name: 'Licensing::Menu:UserLicenses',
        iconClass: 'far fa-key',
        order: 0,
        path: '/administration/user-licenses',
        parentName: 'Licensing::Menu:LicenseManagement',
      },
      {
        name: 'Report::EmailAlertLogs',
        order: 7,
        iconClass: 'far fa-list',
        path: '/administration/email-alert-log',
        parentName: eThemeSharedRouteNames.Administration,
        requiredPolicy: 'Report.EmailReport',
        forSettings: ['Abp.Identity.SignIn.RequireConfirmedEmail'],
      },
      {
        name: 'UserGroup::Menu:InteractionManagement',
        iconClass: 'far fa-user-friends',
        order: 1,
        path: '/administration/interaction-management',
        parentName: eThemeSharedRouteNames.Administration,
        forFeatures: [FeatureConstants.UserGroupManagement],
        forRoles: ['Global.UserAccessScope.AllUsers'],
      },
      {
        name: 'Licensing::Menu:LicenseManagement',
        iconClass: 'far fa-key',
        order: 1,
        path: '/administration/license-management',
        parentName: eThemeSharedRouteNames.Administration,
        requiredPolicy: 'Administration.LicenseManagement',
      },
      {
        name: '::Menu:ScreenRecorderManagement',
        iconClass: 'far fa-desktop',
        order: 3,
        path: '',
        parentName: eThemeSharedRouteNames.Administration,
        forFeatures: [FeatureConstants.ScreenRecorder],
      },
      {
        name: 'ScreenRecorderManagement::Profiles',
        iconClass: 'far fa-layer-group',
        order: 1,
        path: '/administration/profiles',
        parentName: '::Menu:ScreenRecorderManagement',
      },
    ];
    routesService.add(routes);
    routesService.patch(RouteNames.LanguagesPage, { invisible: true });

    const timer$ = timer(10000);
    routesService.visible$.pipe(takeUntil(timer$)).subscribe(x => {
      var administrationMenu = x.filter(r => r.name.includes('Administration'))[0];
      if (administrationMenu) {
        if (!checkAdministrationMenuVisibility(administrationMenu)) {
          if (
            administrationMenu.invisible == null ||
            administrationMenu.invisible == undefined ||
            administrationMenu.invisible == false
          ) {
            routesService.patch(eThemeSharedRouteNames.Administration, { invisible: true });
          }
        }
      }
    });
  };
}

function checkAdministrationMenuVisibility(route: TreeNode<ABP.Route>) {
  if (!route || !route.children || route.children.length <= 0) {
    return false;
  }

  var definitionsMenu = route.children.filter(x => x.name.includes('Definitions'));

  if (definitionsMenu && definitionsMenu.length > 0 && definitionsMenu[0].children.length > 0) {
    return true;
  }

  var otherMenus = route.children.filter(x => !x.name.includes('Definitions'));

  if (otherMenus && otherMenus.length > 0) {
    return true;
  }

  return false;
}
