<ca-clickable-link
  [innerHtml]="
    'QualityManagement::EvaluationResultNotificationMessage' | abpLocalization : payloadData?.count
  "
  [targetLink]="['/quality/evaluation-result']"
  [params]="{
    departmentManager: payloadData?.departmentManager,
    supervisor: payloadData?.supervisor,
    date: payloadData?.date,
    endDate: payloadData?.endDate,
    conversationId: payloadData?.conversationId,
    departmentId: payloadData?.departmentId,
    referrer: 'evaluation-result'
  }">
</ca-clickable-link>
