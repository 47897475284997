import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { Observable } from 'rxjs';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { MatTableDataSource } from '@angular/material/table';
import { LocalizationService } from '@abp/ng.core';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { CompactPlayerComponent } from 'src/ca-shared/player/compact-player/compact-player.component';
import { ConversationListType } from 'src/core/models/generic-lookup-type/conversation/conversation-list-type.glt';

@Component({
  selector: 'ca-conversation-standart-video-call-list',
  templateUrl: './standart-video-call-list.component.html',
  styleUrls: ['./standart-video-call-list.component.scss'],
})
export class StandartVideoCallListComponent implements OnInit {
  @Select(ConversationModuleState.getData)
  data$: Observable<ConversationListResponseDto>;

  @Input()
  compactPlayer: CompactPlayerComponent;

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<ConversationDto>();
  data: ConversationDto[] = [];
  gridId: string;

  @Output()
  conversationIdChange: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  dataLoaded = new EventEmitter();

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'icons',
      header: this.localizationService.instant('::Icons'),
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: 'auto',
      tooltip: 'Conversation Type',
      locked: true,
      hideHeader: true,
      visible: true,
    },
    {
      columnName: 'conversationId',
      header: 'ID',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      minWidth: '100px',
      tooltip: this.localizationService.instant('Conversation::ConversationId'),
      sticky: true,
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: true,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'userName',
      header: this.localizationService.instant('::Agent'),
      binding: 'userName',
      valueParser: undefined,
      pipes: undefined,
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'departmentName',
      header: this.localizationService.instant('::Department'),
      binding: 'departmentName',
      valueParser: undefined,
      pipes: undefined,
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'channelName',
      header: this.localizationService.instant('::Channel'),
      binding: 'channelName',
      valueParser: val => {
        return this.localizationService.instant('GenericLookup::' + val);
      },
      pipes: undefined,
      width: '100px',
      minWidth: '100px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'conversationSourceName',
      header: this.localizationService.instant('::Source'),
      binding: 'conversationSourceName',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      minWidth: '100px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'startTime',
      header: this.localizationService.instant('::Time'),
      binding: 'startTime',
      valueParser: val => {
        return new Date(val);
      },
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTimeWithoutSeconds, true],
        },
      ],
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'StartTime',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'duration',
      header: this.localizationService.instant('::Duration'),
      binding: 'duration',
      valueParser: undefined,
      pipes: [
        {
          pipe: CATimePipe,
          args: [true],
        },
      ],
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'Duration',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
  ];

  constructor(
    private localizationService: LocalizationService,
    private conversationService: ConversationService
  ) {
    this.gridId = `conversation-list-${ConversationListType.videoCall}`;

    this.conversationService.checkAndAddAutomaticEvaluationScoreColumn(this.gridColumns);
    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }

    this.data$.subscribe(data => {
      this.data = data === null ? [] : data.items;

      this.dataSource.connect().next(this.data);
      this.dataLoaded.emit();
    });
  }

  ngOnInit() {}

  onConversationIdChanged(conversationId) {
    this.conversationIdChange.emit(conversationId);
  }
}
