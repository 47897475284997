<form [formGroup]="userDefinedCategorySettingsForm" (ngSubmit)="onSubmitCategorySettings()">
  <div class="row form-row">
    <h4>
      {{ 'Settings::IntervalCategorySettings' | abpLocalization }}
    </h4>
  </div>
  <div class="row form-row">
    <div class="row-container">
      <h5>
        {{ 'Settings::ForAgent' | abpLocalization }}
      </h5>
    </div>
    <div class="row-container">
      <div class="column-container col-3 first">
        <label for="agentBeginningWordCount" class="col-form-label pt-0 indicates-required-field">
          {{ ('Settings::BeginningWordCount' | abpLocalization) + ' ' }}
        </label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="agentBeginningWordCount"
          formControlName="agentBeginningWordCount" />
      </div>
      <div class="column-container col-3 second">
        <label for="agentEndingWordCount" class="col-form-label indicates-required-field">
          {{ ('Settings::EndingWordCount' | abpLocalization) + ' ' }}
        </label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="agentEndingWordCount"
          formControlName="agentEndingWordCount" />
      </div>
    </div>
  </div>
  <div class="row form-row">
    <div class="row-container">
      <h5>
        {{ 'Settings::ForCustomer' | abpLocalization }}
      </h5>
    </div>
    <div class="row-container">
      <div class="column-container col-3 first">
        <label
          for="customerBeginningWordCount"
          class="col-form-label pt-0 indicates-required-field">
          {{ ('Settings::BeginningWordCount' | abpLocalization) + ' ' }}
        </label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="customerBeginningWordCount"
          formControlName="customerBeginningWordCount" />
      </div>
      <div class="column-container col-3 second">
        <label for="customerEndingWordCount" class="col-form-label indicates-required-field">
          {{ ('Settings::EndingWordCount' | abpLocalization) + ' ' }}
        </label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="customerEndingWordCount"
          formControlName="customerEndingWordCount" />
      </div>
    </div>
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
