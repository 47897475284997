import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationFormWithSingleVersionDto } from 'src/core/models/quality-management/evaluation-form-with-single-version.dto';
import { ExportFormInputDto } from 'src/core/models/quality-management/export-form-input.dto';
import { FormBuilderDto } from 'src/core/models/quality-management/form-builder.dto';
import { FormRuleDto } from 'src/core/models/quality-management/form-rule.dto';
import { ListRequestDto } from 'src/core/models/request/list-request.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private apiBase = environment.apis.default.url;
  constructor(private http: HttpClient) { }

  getFormBuilderData(formVersionId: number): Observable<FormBuilderDto> {
    const apiUrl = `${this.apiBase}/api/app/quality/form-version/form-builder`;
    let params = new HttpParams();
    if (formVersionId !== null) {
      params = params.append('formVersionId', formVersionId.toString());
    }

    return this.http.get(apiUrl, {
      params,
    }) as Observable<FormBuilderDto>;
  }

  getFormwithVersion(
    formId: number,
    formVersionId: number
  ): Observable<EvaluationFormWithSingleVersionDto> {
    const apiUrl = `${this.apiBase}/api/app/quality/form/${formId}/version/${formVersionId}`;

    return this.http.get(apiUrl) as Observable<EvaluationFormWithSingleVersionDto>;
  }

  getEvaluableForms<SimpleFormDto>(
    request: ListRequestDto | null
  ): Observable<ListResponseDto<SimpleFormDto>> {
    const url = `${this.apiBase}/api/app/quality/form/evaluable`;
    let params = request === null ? null : new HttpParams();

    if (request !== null) {
      params = params.append('filters', JSON.stringify(request.filters));
      params = params.append('sorters', JSON.stringify(request.sorters));
      params = params.append('skipCount', JSON.stringify(request.skipCount));
      params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));
    }

    return this.http.get(url, {
      params,
    }) as Observable<ListResponseDto<SimpleFormDto>>;
  }

  getEvaluableFormCount<SimpleFormDto>(
    request: ListRequestDto | null
  ): Observable<number> {
    const url = `${this.apiBase}/api/app/form/evaluable-count`;
    let params = request === null ? null : new HttpParams();

    if (request !== null) {
      params = params.append('filters', JSON.stringify(request.filters));
    }

    return this.http.get(url, {
      params,
    }) as Observable<number>;
  }

  getByName(name: string): Observable<EvaluationFormWithSingleVersionDto> {
    const url = `${this.apiBase}/api/app/quality/form/by-name?name=` + encodeURIComponent(name);
    return this.http.get(url) as Observable<EvaluationFormWithSingleVersionDto>;
  }

  duplicate(formId: number, formVersionId: number): Observable<EvaluationFormWithSingleVersionDto> {
    const apiUrl = `${this.apiBase}/api/app/quality/form/${formId}/version/${formVersionId}/duplicate`;

    return this.http.post(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }) as Observable<EvaluationFormWithSingleVersionDto>;
  }

  deactivate(formId: number): Observable<number> {
    const apiUrl = `${this.apiBase}/api/app/quality/form/${formId}/deactivate`;

    return this.http.put(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }) as Observable<number>;
  }

  activate(formId: number): Observable<number> {
    const apiUrl = `${this.apiBase}/api/app/quality/form/${formId}/activate`;

    return this.http.put(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }) as Observable<number>;
  }

  exportForm(form: ExportFormInputDto): Observable<File> {
    let apiUrl = `${this.apiBase}/api/app/quality/form/export`;
    return this.http.post<File>(apiUrl, form, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
  }

  getImportName(name: string): Observable<string> {
    const url = `${this.apiBase}/api/app/quality/form/import-name?name=` + encodeURIComponent(name);

    return this.http.get(url, { responseType: 'text' as 'json' }) as Observable<string>;
  }

  deleteFormVersion(formId: number, formVersionId: number): Observable<any> {
    const apiUrl = `${this.apiBase}/api/app/quality/form/${formId}/version/${formVersionId}`;

    return this.http.delete(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }) as Observable<any>;
  }

  getRules(formId: number, formVersionId: number): Observable<ListResponseDto<FormRuleDto>> {
    const apiUrl = `${this.apiBase}/api/app/quality/form/${formId}/version/${formVersionId}/rules`;

    return this.http.get(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }) as Observable<ListResponseDto<FormRuleDto>>;
  }

  saveRules(
    formId: number,
    formVersionId: number,
    formRuleList: FormRuleDto[]
  ): Observable<ListResponseDto<FormRuleDto>> {
    const apiUrl = `${this.apiBase}/api/app/quality/form/${formId}/version/${formVersionId}/rules`;

    return this.http.post(apiUrl, formRuleList, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }) as Observable<ListResponseDto<FormRuleDto>>;
  }
}
