import { EvaluationZeroPointReasonDto } from './evaluation-zero-point-reason.dto';

export class CompletedWithZeroPointRequestDto {
  assignmentId: number | null;
  reasonList: EvaluationZeroPointReasonDto[];
  formerEvaluationId: number | null;
  maxPoint: number;
  evaluationDuration: number;
  evaluationTypeId: number;
}
