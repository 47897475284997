import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachedDataSelectorComponent } from './components/attached-data-selector/attached-data-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaseSelectorModule } from '../base-selector/base-selector.module';

export { AttachedDataSelectorComponent } from './components/attached-data-selector/attached-data-selector.component';

@NgModule({
  declarations: [AttachedDataSelectorComponent],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgSelectModule,
    MatPaginatorModule,
    BaseSelectorModule,
  ],
  exports: [AttachedDataSelectorComponent],
})
export class AttachedDataSelectorModule {}
