import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { environment } from 'src/environments/environment';
import { EvaluationMasterDto } from 'src/core/models/quality/evaluation/evaluation-master.dto';
import { EvaluationZeroPointReasonDto } from 'src/core/models/quality-management/evaluation/evaluation-zero-point-reason.dto';
import { CompletedWithZeroPointRequestDto } from 'src/core/models/quality-management/evaluation/complete-with-zero-point-request.dto';
import { Observable } from 'rxjs';
import { EvaluationObjectionDto } from 'src/core/models/quality/evaluation/evaluation-objection.dto';
import { EvaluationType } from 'src/core/models/generic-lookup-type/quality/evaluation-type.glt';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  completeWithZeroPoint(
    conversationId: number,
    formVersionId: number,
    assignmentId: number | null,
    reasonList: EvaluationZeroPointReasonDto[],
    maxPoint: number,
    formerEvaluationId: number | null,
    evaluationDuration: number,
    evaluationTypeId: number
  ) {
    let params = new CompletedWithZeroPointRequestDto();
    params.reasonList = reasonList;
    params.assignmentId = assignmentId;
    params.formerEvaluationId = formerEvaluationId;
    params.maxPoint = maxPoint;
    params.evaluationDuration = evaluationDuration;
    params.evaluationTypeId = evaluationTypeId ?? EvaluationType.original;
    const url =
      this.getApiUrl(EvaluationMasterDto) +
      '/conversation/' +
      conversationId +
      '/complete-with-zero-point-for/' +
      formVersionId;
    return this.http.put(url, params);
  }
  constructor(private http: HttpClient) { }

  protected getApiUrl(type = DtoHasApi): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/' + type.apiUrl : type.apiUrl;
  }

  createObjection(objection: EvaluationObjectionDto): Observable<EvaluationObjectionDto> {
    const url = this.getApiUrl(EvaluationObjectionDto) + '/objection';
    return this.http.post(url, objection) as Observable<EvaluationObjectionDto>;
  }

  updateObjection(objection: EvaluationObjectionDto, newPoint: number): Observable<EvaluationObjectionDto> {
    const url = this.getApiUrl(EvaluationObjectionDto) + '/' + objection.id + '/objection/' + newPoint;
    return this.http.put(url, objection) as Observable<EvaluationObjectionDto>;
  }

  getObjectionCount(): Observable<number> {
    const url = this.getApiUrl(EvaluationObjectionDto) + '/objection-count';
    return this.http.get(url) as Observable<number>;  
  }

  isObjectionLimitExceeded(): Observable<boolean> {
    const url = this.getApiUrl(EvaluationObjectionDto) + '/objection-limit';
    return this.http.get(url) as Observable<boolean>;
  }

  getObjectionStatus(id: number): Observable<number> {
    const url = this.getApiUrl(EvaluationObjectionDto) + '/objection/' + id + '/status';
    return this.http.get(url) as Observable<number>;
  }

  deleteObjection(objectionId: number): Observable<any> {
    const url = this.getApiUrl(EvaluationObjectionDto) + '/' + objectionId + '/objection';
    return this.http.delete(url) as Observable<any>;
  }
}
