<div>
  <h2>{{ 'Settings::QualityManagement' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="qualityManagementSettingsForm" (ngSubmit)="onSubmitQualityManagementSettings()">
  <div formGroupName="groupAutomaticEvaluationsForm">
    <h4>
      {{ 'Settings::AutomaticAssignmentSettings' | abpLocalization }}
    </h4>
    <div class="form-group mt-3">
      <label class="form-label" for="groupEvaluationsBy">
        {{ 'Settings::AutomaticAssignmentGroupByHeader' | abpLocalization }}
      </label>
      <ca-select formControlName="groupEvaluationsBy">
        <ca-option [value]="'Agent'">
          {{ 'Agent' | abpLocalization }}
        </ca-option>
        <ca-option [value]="'Department'">
          {{ 'Department' | abpLocalization }}
        </ca-option>
      </ca-select>
      <small class="form-text text-muted">
        {{ 'Settings::AutomaticAssignmentGroupByExplanation' | abpLocalization }}</small
      >
    </div>

    <div class="form-group mt-3">
      <input
        type="checkbox"
        id="showAutomaticEvaluationScore"
        formControlName="showAutomaticEvaluationScore" />
      <label class="ps-1" for="showAutomaticEvaluationScore">
        {{ 'Settings::ShowAutomaticEvaluationScore' | abpLocalization }}
      </label>
    </div>
  </div>

  <div class="mt-3" formGroupName="calibrationSettingsForm">
    <h4>{{ 'Settings::CalibrationSettings' | abpLocalization }}</h4>
    <div class="form-group mt-3">
      <input
        type="checkbox"
        id="calibrateWithFilledForm"
        formControlName="calibrateWithFilledForm" />
      <label class="ps-1" for="calibrateWithFilledForm">
        {{ 'Settings::CalibrateWithFilledForm' | abpLocalization }}
      </label>
    </div>
  </div>

  <div class="mt-3" formGroupName="evaluationSettingsForm">
    <h4>{{ 'Settings::ObjectionLimitSettings' | abpLocalization }}</h4>
    <div class="form-group mt-3">
      <label class="form-label" for="groupEvaluationsBy">
        {{ 'Settings::ObjectionLimitSettingsHeader' | abpLocalization }}
      </label>
      <input
        type="number"
        [min]="0"
        [max]="999"
        id="objectionLimit"
        formControlName="objectionLimit"
        class="form-control form-control-sm" />
      <small class="form-text text-muted">
        {{ 'Settings::ObjectionLimitSettingsExplanation' | abpLocalization }}</small
      >
    </div>
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
