import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { NotificationGroupHeaderDto } from 'src/core/models/notification/notification-group-header.dto';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';

@Component({
  selector: 'ca-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
@AutoUnsubscribe()
export class NotificationListComponent extends HasSubscription implements OnInit {
  dataSource = new MatTableDataSource<NotificationDto | NotificationGroupHeaderDto>();
  data: (NotificationDto | NotificationGroupHeaderDto)[] = [];
  displayedColumns: string[] = [];
  totalCount = 0;
  currentPage = 0;
  pageSize = 25;
  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];

  isLoading = false;
  readSubscription: Subscription;

  private readonly _recentNotificationLimitInHours = 1;
  private status = 'unread';

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'menu',
      header: '',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      tooltip: 'Menu',
    },
    {
      columnName: 'creationTime',
      header: this.localizationService.instant('::CreationTime'),
      binding: 'creationTime',
      valueParser: val => {
        return new Date(val);
      },
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTimeWithoutSeconds, true],
        },
      ],
      width: '150px',
      tooltip: undefined,
    },
    {
      columnName: 'type',
      header: this.localizationService.instant('Notification::NotificationType'),
      binding: 'type',
      valueParser: undefined,
      pipes: undefined,
      width: '100%',
      tooltip: this.localizationService.instant('Notification::NotificationType'),
    },
  ];

  constructor(
    public localizationService: LocalizationService,
    private notificationService: NotificationService,
    private toastr: ToasterService
  ) {
    super();

    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }

    this.load(this.filters, this.sorters);

    this.notificationService.notificationReadStatusChanged
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .subscribe(result => {
        this.data
          .filter(item => 'type' in item)
          .forEach((item: NotificationDto) => {
            if (item.id === result.notificationId) {
              item.markedAsRead = result.readStatus;
            }
          });
      });
  }

  ngOnInit(): void {}

  loadWithStatus(status) {
    this.status = status;

    this.load(this.filters, this.sorters);
  }

  load(filters: FilterItemDto[], sorters: SorterItemDto[]) {
    this.filters = filters;
    this.sorters = sorters;
    this.isLoading = true;

    this.notificationService
      .getList(this.status, {
        filters,
        sorters,
        maxResultCount: this.pageSize,
        skipCount: this.pageSize * this.currentPage,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(response => {
        this.data = this.assignNotificationGroups(response.items);

        this.totalCount = response.totalCount;
        this.dataSource.connect().next(this.data);
      });
  }

  toggleStatus(notification: NotificationDto): void {
    const method = notification.markedAsRead
      ? this.notificationService.markAsUnread
      : this.notificationService.markAsRead;
    const action = method
      .bind(this.notificationService, notification.id)()
      .subscribe({
        next: result => {},
        error: () => {
          const msg = notification.markedAsRead
            ? 'Notification::ErrorOnMarkingAsUnread'
            : 'Notification::ErrorOnMarkingAsRead';

          this.toastr.error(msg);
        },
      });
  }

  markAllAsRead(): void {
    this.notificationService
      .markAllAsRead()
      .pipe(take(1))
      .subscribe({
        next: result => {
          this.data
            .filter(item => 'type' in item)
            .forEach((item: NotificationDto) => {
              item.markedAsRead = true;
            });
        },
        error: () => {
          this.toastr.error('Notification::ErrorOnMarkingAllAsRead');
        },
      });
  }

  onPageChange(event?: PageEvent) {
    this.currentPage = event.pageIndex;
    this.load(this.filters, this.sorters);
  }

  private assignNotificationGroups(
    data: NotificationDto[]
  ): (NotificationDto | NotificationGroupHeaderDto)[] {
    let result: (NotificationDto | NotificationGroupHeaderDto)[];
    const now = new Date();

    const recentItems: (NotificationDto | NotificationGroupHeaderDto)[] = [
      {
        name: this.localizationService.instant('Notification::RecentNotifications'),
      } as NotificationGroupHeaderDto,
    ];

    const olderItems: (NotificationDto | NotificationGroupHeaderDto)[] = [
      {
        name: this.localizationService.instant('Notification::OlderNotifications'),
      } as NotificationGroupHeaderDto,
    ];

    data.forEach(notification => {
      const creationTime = new Date(notification.creationTime);

      const diffInHours: any = Math.abs(now.getTime() - creationTime.getTime()) / 3600000;

      if (diffInHours <= this._recentNotificationLimitInHours) {
        recentItems.push(notification);
      } else {
        olderItems.push(notification);
      }
    });

    if (recentItems.length == 1) {
      recentItems.splice(0, 1);
    }

    if (olderItems.length == 1) {
      olderItems.splice(0, 1);
    }

    result = recentItems.concat(olderItems);

    return result;
  }
}
