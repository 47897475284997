import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { EvaluationReferrer } from 'src/core/constants/evaluation-referrer.constant';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-evaluation-result-agent-notification',
  templateUrl: './evaluation-result-agent-notification.component.html',
  styleUrls: ['./evaluation-result-agent-notification.component.scss'],
})
@NotificationType('EvaluationResultAgentNotification', EvaluationResultAgentNotificationComponent)
export class EvaluationResultAgentNotificationComponent implements OnInit {
  data: NotificationDto;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  constructor(private notificationService: NotificationService, private router: Router) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'QualityManagement::EvaluationResultAgentNotificationMessage',
      parsedPayload.conversationId,
      parsedPayload.score.toFixed(1),
      parsedPayload.maxScore.toFixed(1)
    );

    var toastrId = toastr.success(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(notificationService, notificationId, parsedPayload, router);
    });
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    payload: any,
    router: Router
  ) {
    notificationService.markAsRead(notificationId).subscribe();
    this.navigateToEvaluation(payload, router);
    notificationService.markAsRead(notificationId);
  }

  static navigateToEvaluation(payloadData: any, router: Router) {
    const queryParams: any = {
      conversationId: payloadData.conversationId,
      evaluationMasterId: payloadData.evaluationMasterId,
      referrer: EvaluationReferrer.evaluationResult,
    };

    router.navigate(['quality', 'evaluation-result'], {
      queryParams: queryParams,
    });
  }

  onClick() {
    const parsedPayload = JSON.parse(this.data.payload);
    EvaluationResultAgentNotificationComponent.navigateToEvaluation(parsedPayload, this.router);
    this.notificationService.markAsRead(this.data.id);
  }
}
