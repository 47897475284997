import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';

@Component({
  selector: 'ca-quality-management-settings',
  templateUrl: './quality-management-settings.component.html',
  styleUrls: ['./quality-management-settings.component.scss'],
})
export class QualityManagementSettingsComponent implements OnInit {
  qualityManagementSettingsForm: FormGroup;
  qualityManagementSettingDtos: ConfigurationSettingsDto[] = [];
  private readonly settingKeyCalibrateWithFilledForm =
    'QualityManagement.Calibration.CalibrateWithFilledForm';
  private readonly settingKeyGroupAutomaticEvaluations =
    'QualityManagement.AutomaticEvaluation.GroupBy';
  private readonly settingKeyShowAutomaticEvaluationScore =
    'QualityManagement.AutomaticEvaluation.ShowAutomaticEvaluationScore';
  private readonly settingKeyObjectionLimit =
    'QualityManagement.Evaluation.ObjectionLimit';

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.qualityManagementSettingsForm = fb.group({
      calibrationSettingsForm: fb.group({
        calibrateWithFilledForm: [
          JSON.parse(this.config.getSetting(this.settingKeyCalibrateWithFilledForm).toLowerCase()),
        ],
      }),
      groupAutomaticEvaluationsForm: fb.group({
        groupEvaluationsBy: [this.config.getSetting(this.settingKeyGroupAutomaticEvaluations)],
        showAutomaticEvaluationScore: [
          JSON.parse(
            this.config.getSetting(this.settingKeyShowAutomaticEvaluationScore).toLowerCase()
          ),
        ],
      }),
      evaluationSettingsForm: fb.group({
        objectionLimit: [
          JSON.parse(this.config.getSetting(this.settingKeyObjectionLimit).toLowerCase()),
        ],
      }),
    });
  }

  ngOnInit(): void { }

  onSubmitQualityManagementSettings() {
    if (this.qualityManagementSettingsForm.invalid) {
      return;
    }
    this.qualityManagementSettingDtos = new Array();

    this.addCalibrationSettings();
    this.addObjectionLimitSettings();
    this.addAutomaticAssignmentSettings();
    this.addShowAutomaticAssignmentScoreSettings();
    this.settingService.saveSetting(this.qualityManagementSettingDtos).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }

  private addCalibrationSettings() {
    this.qualityManagementSettingDtos.push({
      settingName: this.settingKeyCalibrateWithFilledForm,
      settingValue: this.qualityManagementSettingsForm
        .get('calibrationSettingsForm.calibrateWithFilledForm')
        .value.toString(),
    });
  }

  private addObjectionLimitSettings() {
    this.qualityManagementSettingDtos.push({
      settingName: this.settingKeyObjectionLimit,
      settingValue: this.qualityManagementSettingsForm
        .get('evaluationSettingsForm.objectionLimit')
        .value.toString(),
    });
  }

  private addShowAutomaticAssignmentScoreSettings() {
    this.qualityManagementSettingDtos.push({
      settingName: this.settingKeyShowAutomaticEvaluationScore,
      settingValue: this.qualityManagementSettingsForm
        .get('groupAutomaticEvaluationsForm.showAutomaticEvaluationScore')
        .value.toString(),
    });
  }

  private addAutomaticAssignmentSettings() {
    this.qualityManagementSettingDtos.push({
      settingName: this.settingKeyGroupAutomaticEvaluations,
      settingValue: this.qualityManagementSettingsForm.get(
        'groupAutomaticEvaluationsForm.groupEvaluationsBy'
      ).value,
    });
  }
}
