import { CallCategoryMarkerDto } from './call-category-marker.dto';
export class PlayerTimelineCategoryMarkerGroupDto {
  color: string;
  text: string;
  startMillisecond: number;
  endMillisecond: number;
  class: string;
  markers: CallCategoryMarkerDto[] = [];
  position: number;
  isSearchMarker?: boolean;
  isTopicMarker?: boolean;
  isCommentMarker?: boolean;
}
