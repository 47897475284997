import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'ca-user-defined-category-settings',
  templateUrl: './user-defined-category-settings.component.html',
  styleUrls: ['./user-defined-category-settings.component.scss']
})
export class UserDefinedCategorySettingsComponent implements OnInit {
  userDefinedCategorySettingsForm: FormGroup;
  userDefinedCategorySettingDtos: ConfigurationSettingsDto[] = [];

  private readonly settingKeyAgentBeginningWordCount = 'UserDefinedCategory.AgentBeginningWordCount';
  private readonly settingKeyAgentEndingWordCount = 'UserDefinedCategory.AgentEndingWordCount';
  private readonly settingKeyCustomerBeginningWordCount = 'UserDefinedCategory.CustomerBeginningWordCount';
  private readonly settingKeyCustomerEndingWordCount = 'UserDefinedCategory.CustomerEndingWordCount';

  private addCategorySettings() {
    this.userDefinedCategorySettingDtos.push(
      {
        settingName: this.settingKeyAgentBeginningWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('agentBeginningWordCount')
          .value.toString(),
      },
      {
        settingName: this.settingKeyAgentEndingWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('agentEndingWordCount')
          .value.toString(),
      },
      {
        settingName: this.settingKeyCustomerBeginningWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('customerBeginningWordCount')
          .value.toString(),
      },
      {
        settingName: this.settingKeyCustomerEndingWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('customerEndingWordCount')
          .value.toString(),
      }
    );
  }

  onSubmitCategorySettings() {
    this.confirmationService
      .warn(
        'Settings::IntervalCategorySettingConfirmationMessage',
        'Settings::SaveChanges',
        {
          yesText: '::Save',
        }
      )
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          if (this.userDefinedCategorySettingsForm.invalid) {
            return;
          }

          this.addCategorySettings();

          this.settingService.saveSetting(this.userDefinedCategorySettingDtos).subscribe(res => {
            this.toastr.success(
              this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
            );
          });
        }
      });
  }

  popoverClicked(popover: NgbPopover, event: any): boolean {
    popover.toggle();
    event.stopPropagation();
    return false;
  }

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private confirmationService: ConfirmationService,
  ) {
    this.userDefinedCategorySettingsForm = fb.group({
      agentBeginningWordCount: [
        this.config.getSetting(this.settingKeyAgentBeginningWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
      agentEndingWordCount: [
        this.config.getSetting(this.settingKeyAgentEndingWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
      customerBeginningWordCount: [
        this.config.getSetting(this.settingKeyCustomerBeginningWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
      customerEndingWordCount: [
        this.config.getSetting(this.settingKeyCustomerEndingWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
    });
  }

  ngOnInit(): void {
  }

}
