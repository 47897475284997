import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CategoryOccurrenceElement } from 'src/core/models/conversation/category/category-occurrence-element';
import { CategoryGroupBy } from 'src/core/models/conversation/category/category-group-by';
import { CallCategoryMarkerDto } from 'src/ca-shared/player/models/call-category-marker.dto';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { ColorUtils } from 'src/core/helpers/color-utils';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { LocalizationService, PermissionService } from '@abp/ng.core';
import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';

@Component({
  selector: 'ca-call-category-detail-panel',
  templateUrl: './call-category-detail-panel.component.html',
  styleUrls: ['./call-category-detail-panel.component.scss'],
})
export class CallCategoryDetailPanelComponent implements OnInit {
  @BlockUI('table') blockUI: NgBlockUI;
  dataSource: any;
  displayedColumns: string[] = ['startTime', 'channel', 'word'];
  canEditCategory: boolean;
  canDeleteCategory: boolean;
  analyticsFeatureEnabled: boolean;
  private _categoryMarks: CallCategoryMarkerDto[];
  private _categories: ConversationCategoryDto[];
  @Output()
  categoryRowClick: EventEmitter<{
    markerInfo: CallCategoryMarkerDto;
  }> = new EventEmitter();

  @Output()
  categoryRowChanged: EventEmitter<{
    oldWords: TranscriptWord[];
    newWords: TranscriptWord[];
    channel: number;
  }> = new EventEmitter();

  @Output()
  categoryRowDeleted: EventEmitter<{
    oldWords: TranscriptWord[];
    newWords: TranscriptWord[];
    channel: number;
  }> = new EventEmitter();

  @Input()
  set categoryMarks(categoryMarks: CallCategoryMarkerDto[]) {
    this.blockUI.stop();
    this.clearDataSource();
    this._categoryMarks = categoryMarks;
    this.loadCategoryData();
  }

  @Input()
  set categories(categories: ConversationCategoryDto[]) {
    this._categories = categories;
  }

  @Input()
  conversationId: number;

  get categoryMarks() {
    return this._categoryMarks;
  }

  get categories(): ConversationCategoryDto[] {
    return this._categories;
  }

  constructor(
    private _formatHelper: FormatHelper,
    public colorUtils: ColorUtils,
    private confirmationService: ConfirmationService,
    private localizationService: LocalizationService,
    private permissionService: PermissionService,
    private featureService: FeatureService
  ) {
    const ELEMENT_DATA: (CategoryOccurrenceElement | CategoryGroupBy)[] = [];
    this.canEditCategory = this.permissionService.getGrantedPolicy('Conversation.Category._Edit');
    this.canDeleteCategory = this.permissionService.getGrantedPolicy(
      'Conversation.Category._Delete'
    );
    let extraButtons = [];
    if (this.canEditCategory) {
      extraButtons.push('edit');
    }

    if (this.canDeleteCategory) {
      extraButtons.push('delete');
    }

    this.displayedColumns = [...extraButtons, ...this.displayedColumns];
    this.dataSource = ELEMENT_DATA;
    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
  }

  getMarkGroupByCategory() {
    let categoryMarks = this._categoryMarks.filter(x => !x.isCommentMarker);
    return categoryMarks.reduce(function (rv, x) {
        (rv[x['categoryName']] = rv[x['categoryName']] || []).push(x);
        return rv;
    }, {});
  }

  getCategoryIconClass(category: CallCategoryMarkerDto) {
    if (category.isSearchMarker) {
      return 'fas fa-search';
    } else if (category.isTopicMarker) {
      return 'far fa-circle';
    } else if (category.isCommentMarker) {
      return 'far fa-comment';
    } else {
      return 'fas fa-circle';
    }
  }
  loadCategoryData() {
    const categoriesGroupedList = this.getMarkGroupByCategory();
    let firstTopicRecord = true;
    let firstCategoryRecord = true;
    for (let category in categoriesGroupedList) {
      let groupHeader = '';
      let tempList: CallCategoryMarkerDto[] = categoriesGroupedList[category].sort(
        (a, b) => a.startMillisecond - b.startMillisecond
      );

      if (
        firstTopicRecord &&
        categoriesGroupedList[category][0].isTopicMarker === true &&
        this.analyticsFeatureEnabled === true
      ) {
        groupHeader = this.localizationService.instant('::Topics');
        firstTopicRecord = false;
      } else if (
        firstCategoryRecord &&
        categoriesGroupedList[category][0].isTopicMarker != true &&
        categoriesGroupedList[category][0].isSearchMarker != true
      ) {
        groupHeader = this.localizationService.instant('Conversation::Categories');
        firstCategoryRecord = false;
      }

      const header = {
        groupHeader: groupHeader,
        initial: tempList[0].categoryName,
        isGroupBy: true,
        isSearchMarker: tempList[0].isSearchMarker,
        isTopicMarker: tempList[0].isTopicMarker,
        color: tempList[0].color,
      };
      this.dataSource.push(header);
      tempList.forEach(item => {
        let markModel = {
          word: item.text,
          categoryName: item.categoryName,
          startTime: this._formatHelper.convertMillisecondToTimeString(item.startMillisecond),
          startMillisecond: item.startMillisecond,
          endMillisecond: item.endMillisecond,
          color: item.color,
          channel: item.channel,
          isTopicMarker: item.isTopicMarker,
          words: item.words,
          editable: false,
        };
        this.dataSource.push(markModel);
      });
    }
    this.dataSource = [...this.dataSource];
  }
  clearDataSource() {
    this.dataSource = [];
  }
  isGroup(index, item): boolean {
    return item.isGroupBy;
  }
  onRowClick(markerInfo: CallCategoryMarkerDto) {
    this.categoryRowClick.emit({ markerInfo: markerInfo });
  }
  ngOnInit(): void {}

  onEnableEdit(mark: any) {
    mark.editable = true;
  }

  onDeleteTranscript(category: CallCategoryMarkerDto) {
    this.confirmationService
      .warn('Conversation::DeleteCategoryPhraseConfirmationMessage', '', {
        yesText: '::Delete',
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.blockUI.start();
          this.categoryRowDeleted.emit({
            oldWords: category.words,
            newWords: [],
            channel: category.channel,
          });
        }
      });
  }

  onTranscriptChange(
    eventArgs: { oldWords: TranscriptWord[]; newWords: TranscriptWord[] },
    mark: any
  ) {
    if (eventArgs.newWords.length > 0) {
      mark.words = eventArgs.newWords;
      this.blockUI.start();
      this.categoryRowChanged.emit({
        oldWords: eventArgs.oldWords,
        newWords: eventArgs.newWords,
        channel: mark.channel,
      });
    } else {
      this.confirmationService
        .warn('Conversation::DeleteCategoryPhraseConfirmationMessage', '', {
          yesText: '::Delete',
        })
        .subscribe((status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.blockUI.start();
            this.categoryRowDeleted.emit({
              oldWords: eventArgs.oldWords,
              newWords: eventArgs.newWords,
              channel: mark.channel,
            });
          } else {
            this.onEditorBlurred(mark);
          }
        });
    }
  }
  onEditorBlurred(mark: any) {
    mark.editable = false;
  }
}
