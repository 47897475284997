import { LocalizationService, PermissionService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReasonDropdownSelectorComponent } from 'src/ca-shared/reason-selector/reason-selector.module';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';
import { CallAnalysisStatus } from 'src/core/models/generic-lookup-type/call/call-analysis-status.glt';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { TagType } from 'src/core/models/generic-lookup-type/conversation/tag-type.glt';
import { ReasonType } from 'src/core/models/generic-lookup-type/quality/reason-type.glt';
import { NotSuitableForEvaluationReasonDto } from 'src/core/models/quality-management/not-suitable-for-evaluation-reason-dto';
import { ReasonDto } from 'src/core/models/quality/reason.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { ConversationMarkAsCommunicationService } from '../conversation-mark-as-communication.service';
import { take } from 'rxjs';

@Component({
  selector: 'ca-conversation-mark-as',
  templateUrl: './conversation-mark-as.component.html',
  styleUrls: ['./conversation-mark-as.component.scss'],
})
export class ConversationMarkAsComponent implements OnInit {
  @ViewChild('reasonSelector')
  reasonSelector: ReasonDropdownSelectorComponent;

  tagDropdownOptions: any;
  filters: FilterItemDto[] = [];
  _conversation: ConversationDto;
  disableEvaluableMarks: boolean = false;

  tagType: number = TagType.userDefined;
  reasonType: number = ReasonType.notSuitableForEvaluationReason;

  canMarkNotSuitableForEvaluation: boolean = false;
  canAssignTag: boolean = false;
  qualityManagementFeatureEnabled: boolean;

  get isCall(): boolean {
    if (this.conversation?.typeId === ConversationType.call) {
      return true;
    } else {
      return false;
    }
  }

  get isAnalysed(): boolean {
    if (
      !this.isCall ||
      this.conversation?.call?.callAnalysis.status.id == CallAnalysisStatus.analyzed
    ) {
      return true;
    } else {
      return false;
    }
  }

  get autoClose(): any {
    if (
      this.reasonSelector &&
      this.reasonSelector.ngSelectComponent &&
      this.reasonSelector.ngSelectComponent.isOpen
    ) {
      return false;
    }
    return 'outside';
  }

  @Input()
  set conversation(value: ConversationDto) {
    this._conversation = value;
    this.setNotSuitableForEvaluationReasons();
  }

  get conversation() {
    return this._conversation;
  }

  @Input()
  divClass: string;

  @Input()
  buttonClass: string;

  constructor(
    private conversationService: ConversationService,
    public conversationMarkAsCommunicationService: ConversationMarkAsCommunicationService,
    private operators: Operators,
    private permissionService: PermissionService,
    private featureService: FeatureService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.canMarkNotSuitableForEvaluation = this.permissionService.getGrantedPolicy(
      'Conversation.CanMarkNotSuitableForEvaluation'
    );
    this.canAssignTag = this.permissionService.getGrantedPolicy('Conversation.Tag.AssignTag');
    this.qualityManagementFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.QualityManagement
    );

    this.tagDropdownOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      filters: this.filters,
      emptyText: this.localizationService.instant('::SelectTag'),
      multiple: false,
      url: 'api/app/tag',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: false,
      selectorPosition: 'Top',
    };
  }

  ngOnInit(): void {}

  markAsNotSuitableForEvaluation() {
    this.disableEvaluableMarks = true;
    var notSuitableReasons: NotSuitableForEvaluationReasonDto[] = [];
    var reasons = this.reasonSelector.value;
    reasons.forEach(r => {
      notSuitableReasons.push({
        conversationId: this.conversation.id,
        reasonId: r.id,
        text: r.text,
      });
    });
    this.conversationService
      .markAsNotSuitableForEvaluation(this.conversation.id, null, notSuitableReasons)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.conversation.isPriorForEvaluation = false;
          this.conversation.isSuitableForEvaluation = false;
          this.conversation.notSuitableForEvaluationReasons = notSuitableReasons;
          this.setNotSuitableForEvaluationReasons();
          this.toastr.success('Conversation::MarkedAsNotEvaluableMessage');
          this.disableEvaluableMarks = false;
        },
        error: () => {
          this.disableEvaluableMarks = false;
        },
      });
  }

  markAsPriorForEvaluation() {
    this.disableEvaluableMarks = true;
    this.conversationService
      .markAsPriorForEvaluation(this.conversation.id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.conversation.isPriorForEvaluation = true;
          this.conversation.isSuitableForEvaluation = true;
          this.conversation.notSuitableForEvaluationReasons = [];
          this.setNotSuitableForEvaluationReasons();
          this.toastr.success(
            this.localizationService.instant('Conversation::MarkedAsPriorMessage')
          );
          this.disableEvaluableMarks = false;
        },
        error: () => {
          this.disableEvaluableMarks = false;
        },
      });
  }

  addTag(selectedTag: TagDto) {
    this.conversationMarkAsCommunicationService.addTag(this.conversation, selectedTag);
  }

  getExcludedItemIds(): number[] {
    return !this.conversation || !this.conversation.conversationTags
      ? []
      : this.conversation.conversationTags.map(t => t.tagId);
  }

  setNotSuitableForEvaluationReasons() {
    if (this.reasonSelector) {
      var reasonList: ReasonDto[] = [];
      const notSuitableForEvaluationReasonList = this.conversation?.notSuitableForEvaluationReasons;
      notSuitableForEvaluationReasonList?.forEach(reason => {
        let reasonDto = new ReasonDto();
        reasonDto.reasonTypeId = this.reasonType;
        reasonDto.id = reason.reasonId;
        reasonDto.text = reason.text;
        reasonList.push(reasonDto);
      });
      this.reasonSelector.writeValue(reasonList);
    }
  }

  onDropdownOpen(isOpen: boolean) {
    if (isOpen && this.conversation.isSuitableForEvaluation) {
      this.reasonSelector.clearModel();
    }
  }
}
