import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { AssignmentDto } from 'src/core/models/quality-management/assignment.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { AssignmentSelectionConfigurationModel } from '../models/assignment-selection-configuration.model';

@Component({
  selector: 'ca-assignment-selection-panel',
  templateUrl: './assignment-selection-panel.component.html',
  styleUrls: ['./assignment-selection-panel.component.scss'],
})
export class AssignmentSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit {
  actualConfig: AssignmentSelectionConfigurationModel;

  _value: AssignmentDto[];
  assignmentSelectorOptions: any;

  @ViewChild(SelectorComponent, { read: SelectorComponent })
  assignmentSelector: SelectorComponent;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as AssignmentSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get multipleSelection(): boolean {
    return this.actualConfig.selectionType == 0 ? false : true;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(
    private localizationService: LocalizationService,
    private operators: Operators,
    protected fb: FormBuilder
  ) {
    super(fb);

    let sorters: SorterItemDto[] = [];
    sorters.push({
      field: 'Name',
      direction: 'ASC',
    });

    this.assignmentSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('QualityManagement::SelectAssignments'),
      multiple: true,
      url: 'api/app/assignment',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
      filters: [],
      sorters: sorters,
    };

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void { }

  reload(): void { }

  reset(): void { }

  autoSelectFirstItem(): void {}

  init() {
    super.init();
    if (this.actualConfig.autoLoad) {
      this.assignmentSelector.refresh();
    }
  }
}