import { ConversationAnalysisCompleteActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-analysis-complete-action-log/conversation-analysis-complete-action-log.component';
import { ConversationAnalysisStartActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-analysis-start-action-log/conversation-analysis-start-action-log.component';
import { ConversationAutomaticEvaluationCompleteActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-automatic-evaluation-complete-action-log/conversation-automatic-evaluation-complete-action-log.component';
import { ConversationAutomaticEvaluationStartActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-automatic-evaluation-start-action-log/conversation-automatic-evaluation-start-action-log.component';
import { ConversationCreateActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-create-action-log/conversation-create-action-log.component';
import { ConversationDownloadedActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-downloaded-action-log/conversation-downloaded-action-log.component';
import { ConversationIndexCompleteActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-index-complete-action-log/conversation-index-complete-action-log.component';
import { ConversationIndexStartActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-index-start-action-log/conversation-index-start-action-log.component';
import { ConversationTextCorrectionCompleteActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-text-correction-complete-action-log/conversation-text-correction-complete-action-log.component';
import { ConversationTranscriptEditedActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-transcript-edited-action-log/conversation-transcript-edited-action-log.component';
import { ConversationViewedActionLogComponent } from 'src/ca-shared/action-log/action-types/conversation-viewed-action-log/conversation-viewed-action-log.component';
import { ActionLogActionType } from 'src/core/models/generic-lookup-type/action-log/action-log-action-type.glt';

export class ActionLogHelper {
  private static _actionTypeMappings: Map<number, any> = new Map<number, any>();

  static get actionTypeMappings(): Map<number, any> {
    if (this._actionTypeMappings.size == 0) {
      this.initializeActionType();
    }

    return this._actionTypeMappings;
  }

  private static initializeActionType() {
    this._actionTypeMappings.set(
      ActionLogActionType.conversationCreate,
      ConversationCreateActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationIndexStart,
      ConversationIndexStartActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationIndexComplete,
      ConversationIndexCompleteActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationAnalysisStart,
      ConversationAnalysisStartActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationAnalysisComplete,
      ConversationAnalysisCompleteActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationAutomaticEvaluationStart,
      ConversationAutomaticEvaluationStartActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationAutomaticEvaluationComplete,
      ConversationAutomaticEvaluationCompleteActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationTextCorrectionComplete,
      ConversationTextCorrectionCompleteActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationTranscriptEdited,
      ConversationTranscriptEditedActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationViewed,
      ConversationViewedActionLogComponent
    );
    this._actionTypeMappings.set(
      ActionLogActionType.conversationDownloaded,
      ConversationDownloadedActionLogComponent
    );
  }
}
