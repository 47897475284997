import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { ExcelService } from 'src/core/services/excel/excel.service';

@Component({
  selector: 'ca-conversation-report-download',
  templateUrl: './conversation-report-download.component.html',
  styleUrls: ['./conversation-report-download.component.scss'],
})
@AutoUnsubscribe()
export class ConversationReportDownloadComponent extends HasSubscription implements OnInit {
  infoLabel = '';
  isCompleted = false;
  isFailure = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private excelService: ExcelService,
    private localizationService: LocalizationService
  ) {
    super();

    this.infoLabel = this.localizationService.instant(
      'Conversation::DownloadWillStartAutomatically'
    );
  }

  ngOnInit(): void {
    const reportId = this.route.snapshot.params.id;
    this.route.queryParams.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(params => {
      let partionCount = params?.partionCount;
      for (let i = 0; i < partionCount; i++) {
        let fileName = this.localizationService.instant('Conversation::ExcelExport.FileName', '');
        if (partionCount > 1) {
          fileName = this.localizationService.instant(
            'Conversation::ExcelExport.FileName',
            '-' + (i + 1)
          );
        }
        this.excelService
          .getExcelReport(reportId, fileName, i)
          .pipe(take(1))
          .subscribe({
            next: result => {
              if (result.size > 0) {
                downloadBlob(result, fileName);
                this.isCompleted = true;
                this.infoLabel = this.localizationService.instant(
                  'Conversation::RedirectionAfterDownloadCompleted'
                );
                setTimeout(() => {
                  this.router.navigate(['/']);
                }, 1000);
              }
            },
            error: () => {
              this.isFailure = true;
              this.infoLabel = this.localizationService.instant('Conversation::DownloadFileError');

              setTimeout(() => {
                this.infoLabel = this.localizationService.instant(
                  'Conversation::RedirectionToHomePage'
                );

                setTimeout(() => {
                  this.router.navigate(['/']);
                }, 1000);
              }, 3000);
            },
          });
      }
    });
  }
}
