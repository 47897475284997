import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { ExcelService } from 'src/core/services/excel/excel.service';

@Component({
  selector: 'ca-conversation-list-export-ready-notification',
  templateUrl: './conversation-list-export-ready-notification.component.html',
  styleUrls: ['./conversation-list-export-ready-notification.component.scss'],
})
@NotificationType(
  'ConversationListExportReadyNotification',
  ConversationListExportReadyNotificationComponent
)
export class ConversationListExportReadyNotificationComponent implements OnInit {
  data: NotificationDto;

  constructor(
    private excelService: ExcelService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector
  ) {
    const parsedPayload = JSON.parse(payload);

    var toastrId = toastr.success('Conversation::ClickHereToDownloadConversationsReport');

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(
        injector,
        notificationService,
        notificationId,
        parsedPayload.fileId,
        parsedPayload.partionCount,
        localizationService
      );
    });
  }

  static toasterClickedHandler(
    injector: Injector,
    notificationService: NotificationService,
    notificationId: string,
    fileId: string,
    partionCount: number,
    localizationService: LocalizationService
  ) {
    for (let i = 0; i < partionCount; i++) {
      let fileName = localizationService.instant('Conversation::ExcelExport.FileName', '');
      if (partionCount > 1) {
        fileName = localizationService.instant('Conversation::ExcelExport.FileName', '-' + (i + 1));
      }

      injector.get(ExcelService).downloadExcelReport(fileId, fileName, i);
    }
    notificationService.markAsRead(notificationId).pipe(take(1)).subscribe();
  }

  downloadFile() {
    const parsedPayload = JSON.parse(this.data.payload);
    this.downloadFileById(parsedPayload.fileId, parsedPayload.partionCount);
    this.notificationService
      .getReadStatus(this.data.id)
      .pipe(take(1))
      .subscribe(result => {
        if (!result) {
          this.notificationService.markAsRead(this.data.id).pipe(take(1)).subscribe();
        }
      });
  }

  downloadFileById(fileId: string, partionCount: number) {
    for (let i = 0; i < partionCount; i++) {
      let fileName = this.localizationService.instant('Conversation::ExcelExport.FileName', '');
      if (partionCount > 1) {
        fileName = this.localizationService.instant(
          'Conversation::ExcelExport.FileName',
          '-' + (i + 1)
        );
      }
      this.excelService
        .getExcelReport(fileId, fileName, i)
        .pipe(take(1))
        .subscribe({
          next: result => {
            if (result.size > 0) {
              downloadBlob(result, fileName);
            }
          },
          error: () => {
            this.toastr.error('Conversation::DownloadFileError');
          },
        });
    }
  }
}
