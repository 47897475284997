<ng-container [formGroup]="editorForm">
  <div class="float-container">
    <div
      class="form-group"
      [ngClass]="customClass"
      [formGroup]="editorForm"
      [ngbTooltip]="actualConfig.placeholder | abpLocalization"
      [disableTooltip]="(actualConfig.placeholder | abpLocalization).length < 50">
      <label
        *ngIf="actualConfig.label && actualConfig.label.length > 0"
        [for]="'text-editor-' + this.actualConfig.key"
        [ngClass]="labelClass"
        >{{ actualConfig.label | abpLocalization }}</label
      >
      <div class="input-wrapper" *ngIf="hasIcon">
        <i [ngClass]="actualConfig.iconCls"></i>
        <input
          id="quickSearchTextWithIcon"
          type="text"
          formControlName="quickSearchText"
          class="form-control form-control-sm editor"
          [placeholder]="actualConfig.placeholder | abpLocalization"
          #quickSearchTextPopover="ngbPopover"
          [ngbPopover]="quickSearchTextPopContent"
          popoverClass="quick-search-popover"
          placement="bottom auto"
          container="body" />
      </div>
      <input
        *ngIf="!hasIcon"
        id="quickSearchTextWithoutIcon"
        type="text"
        formControlName="quickSearchText"
        class="form-control form-control-sm"
        [placeholder]="actualConfig.placeholder | abpLocalization"
        #quickSearchTextPopover="ngbPopover"
        [ngbPopover]="quickSearchTextPopContent"
        popoverClass="quick-search-popover"
        placement="bottom auto"
        container="body" />
    </div>

    <ca-conversation-side-selector formControlName="sideId"> </ca-conversation-side-selector>
  </div>
</ng-container>

<ng-template #quickSearchTextPopContent>
  <div class="expandable-header-search-help">
    <div class="search-help">
      <div class="search-filter-caption">{{ '::FilterByField' | abpLocalization }}</div>
      <ul class="search-filter-list detailed" (click)="changeText()">
        <li class="search-filter-item" tabindex="-1">
          <div class="search-filter-text">{{ '::FilterByExtIdLabel' | abpLocalization }}</div>
          <div class="search-filter-hint">{{ '::FilterByExtIdText' | abpLocalization }}</div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
