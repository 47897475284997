import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownTreeComponent } from 'src/ca-shared/dropdown-tree/components/dropdown-tree/dropdown-tree.component';
import { DropdownTreeNodeModel } from 'src/ca-shared/dropdown-tree/dropdown-tree.module';
import { IntegerQueryItemDto } from 'src/core/models/query/integer-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { DepartmentService } from 'src/core/services/administration/department-service';
import { LocalizationService } from '@abp/ng.core';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { QueryService } from 'src/core/services/query/query.service';
import { Operators } from 'src/core/models/request/operator.enum';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-query-tree-node-department-tree',
  templateUrl: './query-tree-node-department-tree.component.html',
  styleUrls: ['./query-tree-node-department-tree.component.scss'],
})
export class QueryTreeNodeDepartmentTreeComponent
  extends QueryEditorBase
  implements OnInit, AfterViewInit
{
  departmentForm: FormGroup;
  payload: IntegerQueryItemDto;
  multipleValuePayloads: IntegerQueryItemDto[] = [];
  reservedIds: number[] = [];
  reservedInternalIds: number[] = [];
  information: string;
  isInitialLoad: boolean = true;
  loading = true;
  departments: DepartmentDto[] = [];

  get departmentNameList(): string {
    return this.departments.map(x => x.name).join('\r\n');
  }

  constructor(
    private departmentService: DepartmentService,
    private fb: FormBuilder,
    private localizationService: LocalizationService,
    private queryService: QueryService,
    private operators: Operators,
    private crudService: CrudService
  ) {
    super();

    this.information = this.localizationService.instant('::ThisFieldIsRequired');

    this.departmentForm = this.fb.group(
      {
        department: [null, { validators: [Validators.required], updateOn: 'change' }],
      },
      { updateOn: 'blur' }
    );

    this.departmentForm.get('department').valueChanges.subscribe((val: DepartmentDto[]) => {
      this.onChange(val);
    });

    this.departmentForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });
  }

  operatorChanged() {
    this.node.isDirty = true;
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as IntegerQueryItemDto;
    this.multipleValuePayloads = Array.isArray(this.node.categoryItem.multipleValuePayloads)
      ? this.node.categoryItem.multipleValuePayloads.map(x => x as IntegerQueryItemDto)
      : [];

    // use old ids when the selection changes to prevent generating new ones
    this.reservedIds = this.multipleValuePayloads.map(x => x.id);
    this.reservedInternalIds = this.multipleValuePayloads.map(x => x.internalId);
  }

  ngAfterViewInit() {
    if (this.payload.value) {
      let values: number[] = [this.payload.value];

      this.multipleValuePayloads.forEach(x => {
        values.push(x.value);
      });

      this.crudService
        .get<DepartmentDto>(DepartmentDto, {
          filters: [
            {
              field: 'id',
              operator: this.operators.In,
              value: JSON.stringify(values),
            },
          ],
          maxResultCount: 9999,
          skipCount: 0,
          sorters: [],
        })
        .subscribe(response => {
          this.departmentForm.get('department').setValue(response.items);
          this.departments = response.items;

          this.loading = false;
        });
    }
  }

  onChange(selectedDepartments: DepartmentDto[]) {
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
      return;
    } else {
      this.node.isDirty = true;
    }

    if (Array.isArray(selectedDepartments) && selectedDepartments.length > 0) {
      const copyOfReservedIds = new Array(...this.reservedIds);
      const copyOfReservedInternalIds = new Array(...this.reservedInternalIds);

      this.payload.value = selectedDepartments[0].id;
      this.multipleValuePayloads = [];

      for (let i = 1; i < selectedDepartments.length; i++) {
        const reservedId = copyOfReservedIds.pop();
        const reservedInternalId = copyOfReservedInternalIds.pop();

        const newPayload = Object.assign({}, this.payload);

        newPayload.internalId = reservedInternalId
          ? reservedInternalId
          : this.queryService.getInternalId();
        newPayload.parentInternalId = this.payload.internalId;
        newPayload.multiple = true;
        newPayload.value = selectedDepartments[i].id;
        newPayload.id = reservedId ? reservedId : 0;

        this.multipleValuePayloads.push(newPayload);
      }

      this.node.categoryItem.multipleValuePayloads = new Array(...this.multipleValuePayloads);
    }
  }
}
