import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CallTranscriptDto } from 'src/core/models/call/call-transcript.dto';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { CallAnalysisDto } from 'src/core/models/call/call-analysis.dto';
import { CallCategoryMarkerDto } from 'src/ca-shared/player/models/call-category-marker.dto';
import { ListeningStatusChange } from 'src/core/actions/conversation/conversation-module.actions';
import { ConversationListeningStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-listening-status.glt';
import { Store } from '@ngxs/store';
import { CallTranscriptService } from 'src/core/services/conversation/call-transcript.service';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import { CallDto } from 'src/core/models/call/call.dto';
import { CallAnalysisStatus } from 'src/core/models/generic-lookup-type/call/call-analysis-status.glt';
import { PlayerStatus } from 'src/ca-shared/player/models/player-status.enum';
import { ActivatedRoute } from '@angular/router';
import { PlayerComponent } from 'src/ca-shared/player/player/player.component';
import { Observable, Subscription } from 'rxjs';
import { CallTranscriptPanelComponent } from '../../conversation-transcript/call-transcript-panel/call-transcript-panel.component';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';
import { ConversationMediaType } from 'src/core/models/generic-lookup-type/conversation/conversation-media-type.glt';
import { LocalizationService } from '@abp/ng.core';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { PlayerControllerComponent } from 'src/ca-shared/player/player.module';
import { ConversationCommentComponentPanel } from '../../conversation-comment-panel/conversation-comment-panel.component';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  ExcelWorkBook,
  ClientExcelService,
  ExcelRowModel,
} from 'src/ca-shared/client-excel/client-excel.module';
import { CommentTermItemDto } from 'src/core/models/query/comment-term-item.dto';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';

@Component({
  selector: 'ca-call-detail-tab-panel',
  templateUrl: './call-detail-tab-panel.component.html',
  styleUrls: ['./call-detail-tab-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CallDetailTabPanelComponent implements OnInit, OnDestroy {
  @ViewChild('player', {
    read: PlayerComponent,
    static: true,
  })
  player: PlayerComponent;

  @ViewChild('playerController', {
    read: PlayerControllerComponent,
    static: true,
  })
  playerController: PlayerControllerComponent;

  @ViewChild('callTranscriptPanel', {
    read: CallTranscriptPanelComponent,
    static: false,
  })
  callTranscriptPanel: CallTranscriptPanelComponent;

  @ViewChild('commentPanel', {
    read: ConversationCommentComponentPanel,
    static: false,
  })
  commentPanel: ConversationCommentComponentPanel;

  activeTab = 1;
  lastPosition = -1;
  transcripts: CallTranscriptDto[] = [];
  qualityManagementFeatureEnabled: boolean;
  analyticsFeatureEnabled: boolean;
  sentimentFetureEnabled: boolean;
  isTransferConversation = false;
  commentCount: number = 0;
  selectedCategories: number[];

  private _conversation: ConversationDto;
  private _categories: ConversationCategoryDto[];
  private _analysisData: CallAnalysisDto;
  private _categoryMarks: CallCategoryMarkerDto[];
  private _categoryRegionSpaceMillisecond = 4000;
  private _categoryOffsetMillisecond = 3000;
  private _loadPlayerFn;
  private _onPlayerErrorSubscription: Subscription;

  @Input()
  set conversation(value) {
    this.isTransferConversation = this._conversation ? this._conversation.id != value.id : false;
    this._conversation = value;
    this.load(this.conversation.id);
  }

  @Input()
  set categories(categories: ConversationCategoryDto[]) {
    this._categories = categories;
  }

  set analysisData(analysisData: CallAnalysisDto) {
    this._analysisData = analysisData;
  }

  set categoryMarks(categoryMarks: CallCategoryMarkerDto[]) {
    this._categoryMarks = categoryMarks;
  }

  @Output()
  categoryRowClick: EventEmitter<{
    startMillisecond: number;
  }> = new EventEmitter();

  @Output()
  addCommentRequested: EventEmitter<{
    comment: any;
    startTime: any;
    endTime: any;
  }> = new EventEmitter();

  get categoryMarks(): CallCategoryMarkerDto[] {
    return this._categoryMarks ?? [];
  }
  get conversation(): ConversationDto {
    return this._conversation;
  }
  get categories(): ConversationCategoryDto[] {
    return this._categories;
  }
  get analysisData(): CallAnalysisDto {
    return this._analysisData;
  }

  constructor(
    private store: Store,
    private transcriptService: CallTranscriptService,
    private crudService: CrudService,
    private activatedRoute: ActivatedRoute,
    private featureService: FeatureService,
    private videoPlayerService: VideoPlayerService,
    private localizationService: LocalizationService,
    private caDatePipe: CADatePipe,
    private formatHelper: FormatHelper,
    private toastr: ToasterService,
    private excelService: ClientExcelService,
    private caTimePipe: CATimePipe
  ) {
    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
    this.sentimentFetureEnabled = this.featureService.isEnabled(FeatureConstants.SentimentAnalysis);
    this.qualityManagementFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.QualityManagement
    );

    this._loadPlayerFn = this.loadPlayer.bind(this);
  }

  onCategoryRowClick(eventArgs: { markerInfo }) {
    let startMillisecond = eventArgs.markerInfo.startMillisecond - this._categoryOffsetMillisecond;
    if (startMillisecond < 0) {
      startMillisecond = 0;
    }

    this.player.play(startMillisecond / 1000);

    this.player.addRegion(
      eventArgs.markerInfo.startMillisecond - this._categoryRegionSpaceMillisecond,
      eventArgs.markerInfo.words[eventArgs.markerInfo.words.length - 1].endTime +
      this._categoryRegionSpaceMillisecond
    );
  }

  setListeningStatus(eventArgs) {
    const action = new ListeningStatusChange(
      ConversationListeningStatus.listenedBeforeByYou,
      this.conversation.id
    );
    this.store.dispatch(action);
  }

  onCategorySelectionChanged(eventArgs: { selectedCategories: number[] }) {
    this.categoryMarks = [];
    this.selectedCategories = eventArgs.selectedCategories;

    this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
      this.categories,
      this.transcripts,
      x => eventArgs.selectedCategories.indexOf(x.id) !== -1
    );
  }

  public transferConversation(conversationId: number) {
    this.reloadConversation(conversationId).subscribe(conversation => {
      this.playerController.restorePlayerController();
      this.player.transfer();
      this.conversation = conversation;
      this.getTranscript(conversation.call.id);
    });
  }

  onCategoryRowChanged(eventArgs: { oldWords: []; newWords: []; channel: number }) {
    this.callTranscriptPanel.onTranscriptRowChanged(eventArgs, eventArgs.channel);
    this.callTranscriptPanel.saveCategoryTranscript();
  }

  onCategoryRowDeleted(eventArgs: { oldWords: []; newWords: []; channel: number }) {
    this.callTranscriptPanel.onTranscriptRowChanged(eventArgs, eventArgs.channel);
    this.callTranscriptPanel.deleteCategoryTranscript();
  }

  onTranscriptSaved(eventArgs: { transcripts: CallTranscriptDto[] }) {
    this.transcripts = eventArgs.transcripts;
    this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
      this.categories,
      this.transcripts
    );
  }

  onTranscriptSaveFailed() {
    this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
      this.categories,
      this.transcripts
    );
  }

  public setConversation(conversation: ConversationDto) {
    this.conversation = conversation;
    this.player.transfer();
    this.setPlayer();
  }

  private setPlayer(forceToLoadAudio = false) {
    if (this.conversation) {
      this.player.contentSource =
        this.conversation.hasScreenRecording && !forceToLoadAudio
          ? `api/app/conversation/${this._conversation.id}/media/${ConversationMediaType.screenRecording}`
          : `api/app/conversation/${this._conversation.id}/media/${ConversationMediaType.voiceRecording}`;
      this.player.peakSource = 'api/app/conversation/' + this.conversation.id + '/waveform';
      this.player.downloadUrl = 'api/app/conversation/' + this.conversation.id + '/download';
      this.player.fileName = this.conversation.id.toString();
      this.player.conversationId = this.conversation.id;
      this.player.agentChannel = this.conversation?.call?.agentChannelId;

      if (this.conversation.hasScreenRecording && !forceToLoadAudio) {
        this.openVideoPlayer();
        this.player.isVideo = true;
        this.player.load(true, false);
      } else {
        this.player.isVideo = false;
        this.player.load();
      }
    } else {
      this.player.reset();
    }

    this.player.positionChange.subscribe(this.onAudioProcess.bind(this));
  }

  onPlayerError(args: { message: string; isVideo: boolean }): void {
    if (args.isVideo) {
      this.toastr.warn('Conversation::PlayingAudioInsteadOfScreenRecording');

      this.videoPlayerService.close();
      this.setPlayer(true);
      this.player.play();
    }
  }

  onAudioProcess(args: { currentTime: number; currentTimeString: string }) {
    const position: number = Math.floor(args.currentTime * 10);
    if (position === this.lastPosition) {
      return;
    }

    this.lastPosition = position;
  }

  getTranscript(callId: number) {
    this.crudService.getById<CallAnalysisDto>(CallDto, callId, 'analysis').subscribe(analysis => {
      this.transcripts = [];
      this.categoryMarks = [];
      this.analysisData = analysis;
      if (this.analysisData.statusId === CallAnalysisStatus.analyzed) {
        this.crudService
          .getById<CallTranscriptDto[]>(CallDto, callId, 'transcript')
          .subscribe(response => {
            this.transcripts = response;
            const searchedQuery = this.categories.filter(
              category =>
                category.isSearchMarker == true ||
                category.isSearchTopic == true ||
                category.isComment == true
            );
            let categories = [];
            if (searchedQuery && searchedQuery.length > 0) {
              let mergedQueries = this.categories.filter(category => category.rootQueryId != null);
              categories = [...searchedQuery, ...mergedQueries];
            }
            this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
              categories,
              this.transcripts
            );
            this.setQuickSearchFilterRegion(this.categoryMarks);
          });
      }
    });
  }

  loadPlayer() {
    if (this.player.status === PlayerStatus.NotInitialized) {
      setTimeout(this._loadPlayerFn, 100);

      return;
    }

    this.setPlayer();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const activeTab = this.activatedRoute.snapshot.queryParams.activeTab;
      if (activeTab) {
        this.activeTab = Number(activeTab);
      }
    });

    this._onPlayerErrorSubscription = this.player.error.subscribe(this.onPlayerError.bind(this));
  }

  ngOnDestroy(): void {
    this._onPlayerErrorSubscription.unsubscribe();
    this.videoPlayerService.close();
  }

  reloadConversation(id: number): Observable<ConversationDto> {
    return this.crudService.getById<ConversationDto>(ConversationDto, id);
  }

  load(id: number) {
    if (this.isTransferConversation) {
      this.transferConversation(id);
    } else {
      this.getTranscript(this.conversation.call.id);
    }

    this.loadPlayer();
  }

  setQuickSearchFilterRegion(categoryMarks: CallCategoryMarkerDto[]) {
    let firstSearchMarker = categoryMarks.find(x => x.isSearchMarker === true);
    this.player.ready.subscribe(res => {
      if (firstSearchMarker) {
        this.player.addRegion(
          firstSearchMarker.startMillisecond - this._categoryRegionSpaceMillisecond,
          firstSearchMarker.words[firstSearchMarker.words.length - 1].endTime +
          this._categoryRegionSpaceMillisecond
        );
      }
    });
  }

  onExcelExportRequest(eventArgs) {
    var excelData = eventArgs.data;

    let titleStyle = {
      bold: true,
      size: 12,
    };

    const header = excelData.headers;
    const data = excelData.data;

    let headerRow: ExcelRowModel = {
      cells: [],
    };
    header.forEach(headerValue => {
      headerRow.cells.push({
        value: headerValue,
        properties: {
          font: titleStyle,
        },
      });
    });

    let dataRows = [];
    data.forEach(d => {
      let row: ExcelRowModel = {
        cells: [],
      };
      d.forEach(cellValue => {
        row.cells.push({ value: cellValue });
      });
      dataRows.push(row);
    });

    let workbook: ExcelWorkBook = {
      fileName:
        this.conversation.userName +
        ' - ' +
        this.conversation.id +
        ' (' +
        this.localizationService.instant('Conversation::Transcript') +
        ')' +
        '.xlsx',

      worksheets: [
        {
          properties: {
            defaultColWidth: 30,
          },
          title: this.localizationService.instant('Conversation::Transcript'),
          rows: [
            {
              cells: [
                {
                  value: this.localizationService.instant('::Id'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.id.toString(),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Agent'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.userName,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Department'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.departmentName,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Type'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.localizationService.instant(
                    'GenericLookup::' + this.conversation.typeName
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Time'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.caDatePipe.transform(
                    this.conversation.startTime,
                    DateDisplayType.DateTime
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Direction'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.localizationService.instant(
                    'GenericLookup::' + this.conversation?.call.directionCode
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Channel'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.localizationService.instant(
                    'GenericLookup::' + this.conversation.channelName
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Duration'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.caTimePipe.transform(this.conversation.duration),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('Conversation::Caller'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.call.callerNumber,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('Conversation::Called'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.call.calledNumber,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Source'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation?.conversationSourceName,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('Conversation::ExternalId'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.localizationService.instant(this.conversation?.externalId.toString()),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('Conversation::RelatedGroupId'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.localizationService.instant(this.conversation?.relatedGroupId),
                },
              ],
            },
            {
              cells: [],
              mergeOptions: 'A:J',
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'A6A6A6' },
                bgColor: { argb: '' },
              },
            },
            ...[headerRow],
            ...dataRows,
          ],
        },
      ],
    };

    this.excelService.generateAndDownload(workbook);
  }

  private openVideoPlayer(): void {
    this.videoPlayerService.show(
      `${this.localizationService.instant('Conversation::Conversation')} #${this.conversation.id}`
    );
  }

  onAddCommentRequest(eventArgs) {
    this.addCommentRequested.emit(eventArgs);
  }

  onCommentCountChanged(eventArgs) {
    let commentId = -99;
    if (!this.categories.some(x => x.id == commentId) && eventArgs.comments.length > 0) {
      let category = this.addCommentToCategory(eventArgs.comments);
      this.categories.push(category);
    } else if (this.categories.some(x => x.id == commentId) && eventArgs.comments.length <= 0) {
      this.categories = this.categories.filter(x => x.id != commentId);
    } else if (this.categories.some(x => x.id == commentId) && eventArgs.comments.length > 0) {
      this.categories = this.categories.filter(x => x.id != commentId);
      let category = this.addCommentToCategory(eventArgs.comments);
      this.categories.push(category);
    }

    if (this.selectedCategories && this.selectedCategories.length > 0) {
      this.selectedCategories = this.selectedCategories.filter(x => x != commentId);
      this.selectedCategories.push(commentId);
      this.onCategorySelectionChanged({ selectedCategories: this.selectedCategories });
    } else {
      this.onCategorySelectionChanged({ selectedCategories: [commentId] });
    }

    this.commentCount = eventArgs.comments.length;
  }

  addCommentToCategory(conversationComments: ConversationCommentDto[]) {
    let category: ConversationCategoryDto = {
      categoryColor: '#94acce',
      id: -99,
      queryVersionId: 0,
      categoryName: this.localizationService.instant('::Comment'),
      commentTermItems: [],
      isComment: true,
    };
    conversationComments.forEach(conversationComment => {
      let term: CommentTermItemDto = {
        term: conversationComment.comment,
        startTime: conversationComment.startTime,
        endTime: conversationComment.endTime,
      };
      category.commentTermItems.push(term);
    });

    return category;
  }

  onGoToComment(eventArgs) {
    this.activeTab = eventArgs.activeTab;
    const startTime = this.formatHelper.convertStringToTimerSecond(eventArgs.startTime);
    let endTime = 0;

    this.player.play(startTime);

    if (eventArgs.endTime) {
      endTime = this.formatHelper.convertStringToTimerSecond(eventArgs.endTime);
      this.player.addRegion(startTime * 1000, endTime * 1000);
      if (this.player.regions().list['region_1'] != null) {
        this.player.regions().list['region_1'].play();
      }
    }
  }

  onGoToMarkClick(eventArgs: { code: string; channelId: number }) {
    this.player.onGoToMarkClick(eventArgs);
  }
}
