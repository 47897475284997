<div class="row m-0 p-3" *ngIf="categories.length == 0">
  {{ 'Conversation::ConversationNotContainCategoryData' | abpLocalization }}
</div>
<div class="row m-0 p-3" *ngIf="categories.length > 0 && categoryMarks.length == 0">
  {{ 'Conversation::ConversationNoCategoryEnabled' | abpLocalization }}
</div>
<div *blockUI="'table'">
  <table mat-table *ngIf="categoryMarks.length > 0" [dataSource]="dataSource" style="width: 100%">
    <ng-container matColumnDef="edit" *ngIf="canEditCategory">
      <th scope="col" style="width: 40px; min-width: 40px" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          *ngIf="!element.isTopicMarker"
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          container="body"
          (click)="onEnableEdit(element); $event.stopPropagation()"
          [ngbTooltip]="'AbpUi::Edit' | abpLocalization"
        >
          <i class="far fa-pen"></i>
        </button></td
    ></ng-container>
    <ng-container matColumnDef="delete" *ngIf="canDeleteCategory">
      <th scope="col" style="width: 40px; min-width: 40px" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          *ngIf="!element.isTopicMarker"
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          container="body"
          [ngbTooltip]="'AbpUi::Delete' | abpLocalization"
          (click)="onDeleteTranscript(element); $event.stopPropagation()"
        >
          <ca-delete-icon></ca-delete-icon>
        </button></td
    ></ng-container>
    <ng-container matColumnDef="startTime">
      <th scope="col" style="width: 100px; min-width: 100px" mat-header-cell *matHeaderCellDef>
        {{ '::Time' | abpLocalization }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.startTime }}</td>
    </ng-container>
    <ng-container matColumnDef="channel">
      <th scope="col" style="width: 64px; min-width: 64px" mat-header-cell *matHeaderCellDef>
        {{ '::Channel' | abpLocalization }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span
          class="fa fa-headphones fa-lg"
          *ngIf="element.channel == 1"
          style="color: rgb(255, 157, 9)"
          title="{{ 'Conversation::Agent' | abpLocalization }}"
        ></span>
        <span
          class="fa fa-user fa-lg"
          *ngIf="element.channel != 1"
          style="color: rgb(111, 168, 220)"
          title="{{ 'Conversation::Customer' | abpLocalization }}"
        ></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="word">
      <th scope="col" style="width: 100%; min-width: 100%" mat-header-cell *matHeaderCellDef>
        {{ '::Phrase' | abpLocalization }}
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="!element.editable">
          <span
            #wordContainer
            [attr.data-time]="(element.startTime / 1000).toFixed(2)"
            [attr.data-channel]="element.channel"
            *ngFor="let elementWords of element.words"
            class="word-container"
          >
            {{ elementWords.word }}
          </span>
        </ng-container>
        <ng-container *ngIf="element.editable">
          <ca-conversation-transcript-editor-input
            [autoFocus]="true"
            (click)="$event.stopPropagation()"
            [rowInfo]="element"
            (changed)="onTranscriptChange($event, element)"
            (focusout)="onEditorBlurred(element)"
          ></ca-conversation-transcript-editor-input>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>

    <!-- Group header -->
    <ng-container matColumnDef="groupHeader">
      <td colspan="999" mat-cell *matCellDef="let groupBy">
        <h4 class="pt-1">{{ groupBy.groupHeader }}</h4>
        <span [title]="groupBy.initial" class="badge category-badge category-box">
          <i [ngClass]="getCategoryIconClass(groupBy)" style="color:{{ groupBy.color }}"></i>
          {{ groupBy.initial }}</span
        >
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></tr>
  </table>
</div>
