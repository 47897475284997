import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { IQueryBuilderCategoryItem } from 'src/core/models/query/query-builder-category-item.interface';
import { OrderedGroupQueryItemDto } from 'src/core/models/query/ordered-group-query-item.dto';
import { Observable } from 'rxjs';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { QueryRangeUnit } from 'src/core/models/generic-lookup-type/query/query-range-unit.glt';
import { first, map, mergeMap } from 'rxjs/operators';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { ConversationFilterableFields } from 'src/core/models/conversation/conversation-filterable-fields.enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderedGroupWithRangeQueryItemDto } from 'src/core/models/query/ordered-group-with-range-query-item.dto';
import { QueryConstants } from 'src/core/constants/query-constant';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';

export enum QueryIntervalType {
  Opening = '1',
  Closing = '2',
  All = '3',
}

export enum OrderedGroupQueryType {
  OrderedGroup = 1,
  OrderedGroupWithRange,
}

@Component({
  selector: 'ca-query-tree-node-ordered-group',
  templateUrl: './query-tree-node-ordered-group.component.html',
  styleUrls: ['./query-tree-node-ordered-group.component.scss'],
})
export class QueryTreeNodeOrderedGroupComponent extends QueryEditorBase implements OnInit {
  categories: QueryBuilderCategoryDto[];
  payload: any;

  rangeUnits$: Observable<GenericLookupDto[]>;
  conversationSides$: Observable<GenericLookupDto[]>;

  queryForm: FormGroup = null;
  QueryIntervalType = QueryIntervalType;
  OrderedGroupQueryType = OrderedGroupQueryType;
  private currentOrderedGroupQueryTypeInternal: OrderedGroupQueryType =
    OrderedGroupQueryType.OrderedGroup;
  private currentQueryIntervalTypeInternal: QueryIntervalType = QueryIntervalType.All;
  rangeUnitSecondsId: number;
  readonly RANGE_SECONDS = QueryConstants.RANGE_OPENING_END_SECOND.toString();

  formRangeUnitId: number;
  formStartTime: number;
  formEndTime: number;

  intervalAgentQueryFirstNWords: string = '';
  intervalAgentQueryLastNWords: string = '';
  intervalCustomerQueryFirstNWords: string = '';
  intervalCustomerQueryLastNWords: string = '';

  static requiredTwoOrderedSimpleTermEditorsCreatedForComponent = [];

  get currentOrderedGroupQueryType(): OrderedGroupQueryType {
    return this.currentOrderedGroupQueryTypeInternal;
  }

  get currentQueryIntervalType(): QueryIntervalType {
    return this.currentQueryIntervalTypeInternal;
  }

  onAddClick(eventArgs: MouseEvent) {
    this.addOrderedSimpleTermNode();
  }

  private addOrderedSimpleTermNode() {
    this.categories.forEach(category => {
      category.items.forEach(item => {
        if (item.dataType === this.queryFieldDataType.OrderedSimpleTerm) {
          this.queryBuilder.addNodeFromAnotherNode(this.node, category, item);
        }
      });
    });
  }

  onMenuOpened(eventArgs) {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach(btn => {
      btn.classList.add('show-on-hover-disabled');
    });
  }

  onMenuClosed(eventArgs) {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach((btn: HTMLElement) => {
      btn.classList.remove('show-on-hover-disabled');
      btn.style.display = 'none';
    });
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    public queryField: ConversationFilterableFields,
    public queryFieldDataType: QueryFieldDataType,
    private store: Store,
    private fb: FormBuilder,
    private localizationService: LocalizationService,
    private config: ConfigStateService,
  ) {
    super();

    this.intervalAgentQueryFirstNWords = this.config.getSetting('UserDefinedCategory.AgentBeginningWordCount');
    this.intervalAgentQueryLastNWords = this.config.getSetting('UserDefinedCategory.AgentEndingWordCount');
    this.intervalCustomerQueryFirstNWords = this.config.getSetting('UserDefinedCategory.CustomerBeginningWordCount');
    this.intervalCustomerQueryLastNWords = this.config.getSetting('UserDefinedCategory.CustomerEndingWordCount');

    this.rangeUnits$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(QueryRangeUnit)));

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.rangeUnits$
      .pipe(
        first(),
        mergeMap(x => x.filter(y => y.code == QueryConstants.RANGE_UNIT_SECONDS_CODE))
      )
      .subscribe(data => {
        this.rangeUnitSecondsId = data.id;
      });

    this.queryForm = fb.group(
      {
        intervalType: [QueryIntervalType.All],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('intervalType').valueChanges.subscribe(value => {
      const intervalType: QueryIntervalType = this.queryForm.get('intervalType').value;
      this.formRangeUnitId = this.rangeUnitSecondsId;
      this.payload.rangeUnitId = this.formRangeUnitId;

      if (intervalType === QueryIntervalType.Opening) {
        this.changeQueryType(OrderedGroupQueryType.OrderedGroupWithRange, false);
        this.formStartTime = QueryConstants.RANGE_OPENING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_OPENING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      } else if (intervalType === QueryIntervalType.Closing) {
        this.changeQueryType(OrderedGroupQueryType.OrderedGroupWithRange, false);
        this.formStartTime = QueryConstants.RANGE_CLOSING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_CLOSING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      } else {
        this.changeQueryType(OrderedGroupQueryType.OrderedGroup, false);
      }
    });
  }

  ngOnInit() {
    this.categories = this.queryBuilder.visibleCategories;
    this.payload = this.node.categoryItem.payload;
    if (this.payload.id !== 0) {
      QueryTreeNodeOrderedGroupComponent.requiredTwoOrderedSimpleTermEditorsCreatedForComponent.push(
        this.payload.internalId
      );
    }

    let dtoType = this.node.categoryItem.dataType;
    this.node.validationStatus = true;

    switch (dtoType) {
      case this.queryFieldDataType.OrderedGroup:
        this.currentQueryIntervalTypeInternal = QueryIntervalType.All;
        this.queryForm.get('intervalType').patchValue(QueryIntervalType.All);
        break;
      case this.queryFieldDataType.OrderedGroupWithRange:
        if (
          this.payload.startTime === QueryConstants.RANGE_OPENING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_OPENING_END_SECOND
        ) {
          this.currentQueryIntervalTypeInternal = QueryIntervalType.Opening;
          this.queryForm.get('intervalType').patchValue(QueryIntervalType.Opening);
        } else if (
          this.payload.startTime === QueryConstants.RANGE_CLOSING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_CLOSING_END_SECOND
        ) {
          this.currentQueryIntervalTypeInternal = QueryIntervalType.Closing;
          this.queryForm.get('intervalType').patchValue(QueryIntervalType.Closing);
        }
        break;
    }

    if (
      this.payload.id === 0 &&
      !QueryTreeNodeOrderedGroupComponent.requiredTwoOrderedSimpleTermEditorsCreatedForComponent.includes(
        this.payload.internalId
      ) &&
      !this.node.isImport
    ) {
      QueryTreeNodeOrderedGroupComponent.requiredTwoOrderedSimpleTermEditorsCreatedForComponent.push(
        this.payload.internalId
      );
      this.addOrderedSimpleTermNode();
      this.addOrderedSimpleTermNode();
    }
  }

  getOpeningIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
    }
    else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryFirstNWords);
    }
    else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideFirstQueryNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryFirstNWords);
    }
    return tooltip;
  }

  getClosingIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
    }
    else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryLastNWords);
    }
    else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryLastNWords);
    }
    return tooltip;
  }

  private changeQueryType(queryType: OrderedGroupQueryType, initial: boolean) {
    this.currentOrderedGroupQueryTypeInternal = queryType;
    switch (queryType) {
      case OrderedGroupQueryType.OrderedGroup:
        let payloadOrdered = new OrderedGroupQueryItemDto();

        payloadOrdered.categoryId = this.payload.categoryId;
        payloadOrdered.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_ORDERED;
        payloadOrdered.id =
          this.node.categoryItem.dataType !== this.queryFieldDataType.OrderedGroup
            ? 0
            : this.payload.id;
        payloadOrdered.internalId = this.payload.internalId;
        payloadOrdered.parentInternalId = this.payload.parentInternalId;
        payloadOrdered.queryId = this.payload.queryId;

        this.payload = payloadOrdered;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.OrderedGroup;
        break;
      case OrderedGroupQueryType.OrderedGroupWithRange:
        let payloadOrderedWithRange = new OrderedGroupWithRangeQueryItemDto();

        payloadOrderedWithRange.categoryId = this.payload.categoryId;
        payloadOrderedWithRange.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_ORDERED_INTERVAL;
        payloadOrderedWithRange.id =
          this.node.categoryItem.dataType !== this.queryFieldDataType.OrderedGroupWithRange
            ? 0
            : this.payload.id;
        payloadOrderedWithRange.internalId = this.payload.internalId;
        payloadOrderedWithRange.parentInternalId = this.payload.parentInternalId;
        payloadOrderedWithRange.queryId = this.payload.queryId;

        payloadOrderedWithRange.startTime = initial ? this.payload.startTime : this.formStartTime;
        payloadOrderedWithRange.endTime = initial ? this.payload.endTime : this.formEndTime;
        payloadOrderedWithRange.rangeUnitId = this.formRangeUnitId;

        this.payload = payloadOrderedWithRange;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.OrderedGroupWithRange;
        break;
    }
  }
}
