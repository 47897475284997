import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { SimpleTermQueryItemDto } from 'src/core/models/query/simple-term-query-item.dto';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { first, map, mergeMap } from 'rxjs/operators';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { QueryRangeUnit } from 'src/core/models/generic-lookup-type/query/query-range-unit.glt';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimpleTermValidator } from 'src/core/validators/query/simple-term.validator';
import { Operators } from 'src/core/models/request/operator.enum';
import { SimpleTermWithRangeQueryItemDto } from 'src/core/models/query/simple-term-with-range-query-item.dto';
import { SimpleTermWithOccurenceQueryItemDto } from 'src/core/models/query/simple-term-with-occurence-query-item.dto';
import { SimpleTermWithNotQueryItemDto } from 'src/core/models/query/simple-term-with-not-query-item.dto';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { QueryConstants } from 'src/core/constants/query-constant';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';

export enum SimpleQueryType {
  Basic = 1,
  Interval,
  Occurence,
  Not,
}

export enum SimpleQueryIntervalType {
  Opening = '1',
  Closing = '2',
}
@Component({
  selector: 'ca-query-tree-node-simple-term',
  templateUrl: './query-tree-node-simple-term.component.html',
  styleUrls: ['./query-tree-node-simple-term.component.scss'],
})
export class QueryTreeNodeSimpleTermComponent extends QueryEditorBase implements OnInit {
  payload: any;
  conversationSides$: Observable<GenericLookupDto[]>;
  rangeUnits$: Observable<GenericLookupDto[]>;
  queryForm: FormGroup = null;
  currentConversationSide: string;
  conversationSideAny: number = ConversationSide.any;

  SimpleQueryType = SimpleQueryType;
  SimpleQueryIntervalType = SimpleQueryIntervalType;
  private currentSimpleQueryTypeInternal: SimpleQueryType = SimpleQueryType.Basic;
  private currentSimpleQueryIntervalTypeInternal = SimpleQueryIntervalType.Opening;
  readonly RANGE_SECONDS = QueryConstants.RANGE_OPENING_END_SECOND.toString();

  rangeUnitSecondsId: number;
  occurenceMin = 1;
  occurenceMax = Number.MAX_SAFE_INTEGER;

  formOperator: number;
  formIsNotQuery: boolean;
  formSideId: number;
  formTerm: string;
  formRangeUnitId: number;
  formStartTime: number;
  formEndTime: number;
  formOccurence: number;

  inputPhrases$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  conversationType = ConversationType;

  intervalAgentQueryFirstNWords: string = '';
  intervalAgentQueryLastNWords: string = '';
  intervalCustomerQueryFirstNWords: string = '';
  intervalCustomerQueryLastNWords: string = '';

  get currentSimpleQueryType(): SimpleQueryType {
    return this.currentSimpleQueryTypeInternal;
  }

  get currentSimpleQueryIntervalType(): SimpleQueryIntervalType {
    return this.currentSimpleQueryIntervalTypeInternal;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder,
    private store: Store,
    public operators: Operators,
    private queryFieldDataType: QueryFieldDataType,
    private genericLookupService: GenericLookupTypeService,
    private localizationService: LocalizationService,
    private config: ConfigStateService
  ) {
    super();

    this.intervalAgentQueryFirstNWords = this.config.getSetting(
      'UserDefinedCategory.AgentBeginningWordCount'
    );
    this.intervalAgentQueryLastNWords = this.config.getSetting(
      'UserDefinedCategory.AgentEndingWordCount'
    );
    this.intervalCustomerQueryFirstNWords = this.config.getSetting(
      'UserDefinedCategory.CustomerBeginningWordCount'
    );
    this.intervalCustomerQueryLastNWords = this.config.getSetting(
      'UserDefinedCategory.CustomerEndingWordCount'
    );

    this.conversationSideAny = ConversationSide.any;

    this.rangeUnits$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(QueryRangeUnit)));

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.rangeUnits$
      .pipe(
        first(),
        mergeMap(x => x.filter(y => y.code == QueryConstants.RANGE_UNIT_SECONDS_CODE))
      )
      .subscribe(data => {
        this.rangeUnitSecondsId = data.id;
      });

    this.queryForm = fb.group(
      {
        term: [
          null,
          {
            validators: [Validators.required, SimpleTermValidator.Validator],
          },
        ],
        conversationSide: [null],
        occurence: [
          1,
          [Validators.required, IntegerValidator.minMax(this.occurenceMin, this.occurenceMax)],
        ],
        isNotQuery: [false],
        intervalType: [SimpleQueryIntervalType.Opening],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('term').valueChanges.subscribe(value => {
      this.queryForm.updateValueAndValidity();

      if (this.queryForm.valid) {
        const newValue = this.queryForm.get('term').value;
        this.formOperator = newValue.endsWith('*') ? operators.Contains : operators.Equals;
        this.formTerm = newValue.endsWith('*')
          ? newValue.substring(0, newValue.length - 1)
          : newValue;

        this.payload.term = this.formTerm.trim();
        this.payload.operator = this.formOperator;
      }
    });

    this.queryForm.get('isNotQuery').valueChanges.subscribe(value => {
      this.formIsNotQuery = this.queryForm.get('isNotQuery').value;
      if (this.currentSimpleQueryTypeInternal == SimpleQueryType.Interval) {
        this.payload.not = this.formIsNotQuery;
      } else {
        this.formIsNotQuery
          ? this.changeQueryType(SimpleQueryType.Not, false)
          : this.changeQueryType(SimpleQueryType.Basic, false);
      }
    });

    this.queryForm.get('occurence').valueChanges.subscribe(value => {
      this.formOccurence = this.queryForm.get('occurence').value;
      this.payload.occurence = this.formOccurence;
    });

    this.queryForm.get('conversationSide').valueChanges.subscribe(value => {
      this.formSideId = this.queryForm.get('conversationSide').value;
      this.payload.sideId = this.formSideId;
      this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
        this.payload.sideId
      )?.code;
    });

    this.queryForm.get('intervalType').valueChanges.subscribe(value => {
      const intervalType: SimpleQueryIntervalType = this.queryForm.get('intervalType').value;
      this.formRangeUnitId = this.rangeUnitSecondsId;
      this.payload.rangeUnitId = this.formRangeUnitId;

      if (intervalType === SimpleQueryIntervalType.Opening) {
        this.formStartTime = QueryConstants.RANGE_OPENING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_OPENING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      } else {
        this.formStartTime = QueryConstants.RANGE_CLOSING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_CLOSING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      }
    });
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload;
    let dtoType = this.node.categoryItem.dataType;

    this.formTerm =
      this.payload.operator === this.operators.Contains
        ? this.payload.term + '*'
        : this.payload.term;
    this.formOperator = this.payload.operator;
    this.formSideId = this.payload.sideId;

    switch (dtoType) {
      case this.queryFieldDataType.SimpleTerm:
        this.currentSimpleQueryTypeInternal = SimpleQueryType.Basic;
        this.queryForm.get('term').patchValue(this.formTerm);
        this.queryForm.get('conversationSide').patchValue(this.payload.sideId);
        this.changeQueryType(SimpleQueryType.Basic, true);
        break;
      case this.queryFieldDataType.SimpleTermWithNot:
        this.currentSimpleQueryTypeInternal = SimpleQueryType.Not;
        this.queryForm.get('isNotQuery').patchValue(true);
        this.queryForm.get('term').patchValue(this.formTerm);
        this.queryForm.get('conversationSide').patchValue(this.payload.sideId);
        this.changeQueryType(SimpleQueryType.Not, true);
        break;
      case this.queryFieldDataType.SimpleTermWithOccurence:
        this.currentSimpleQueryTypeInternal = SimpleQueryType.Occurence;
        this.queryForm.get('term').patchValue(this.formTerm);
        this.queryForm.get('conversationSide').patchValue(this.payload.sideId);
        this.queryForm.get('occurence').patchValue(this.payload.occurence);
        this.changeQueryType(SimpleQueryType.Occurence, true);
        break;
      case this.queryFieldDataType.SimpleTermWithRange:
        this.currentSimpleQueryTypeInternal = SimpleQueryType.Interval;
        this.queryForm.get('term').patchValue(this.formTerm);
        this.queryForm.get('conversationSide').patchValue(this.payload.sideId);
        this.queryForm.get('isNotQuery').patchValue(this.payload.not);

        if (
          this.payload.startTime === QueryConstants.RANGE_OPENING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_OPENING_END_SECOND
        ) {
          this.currentSimpleQueryIntervalTypeInternal = SimpleQueryIntervalType.Opening;
          this.queryForm.get('intervalType').patchValue(SimpleQueryIntervalType.Opening);
        } else if (
          this.payload.startTime === QueryConstants.RANGE_CLOSING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_CLOSING_END_SECOND
        ) {
          this.currentSimpleQueryIntervalTypeInternal = SimpleQueryIntervalType.Closing;
          this.queryForm.get('intervalType').patchValue(SimpleQueryIntervalType.Closing);
        }
        this.changeQueryType(SimpleQueryType.Interval, true);
        break;
    }

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      let conversationSide = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationSide = conversationSide.name;
      this.payload.sideCode = conversationSide.code;
    });
    this.updateInputPhrases();
  }

  onQueryTypeMenuItemClicked(queryType: SimpleQueryType) {
    this.changeQueryType(queryType, false);
  }

  getSiblingNodePhrases(): string[] {
    return this.queryBuilder.getSiblingNodePhrasesOfNode(this.node);
  }

  updateInputPhrases() {
    this.inputPhrases$.next(this.getSiblingNodePhrases());
  }

  private changeQueryType(queryType: SimpleQueryType, initial: boolean) {
    this.currentSimpleQueryTypeInternal = queryType;
    let sideCode = initial
      ? this.genericLookupService.findGenericLookupWithId(this.payload.sideId)?.code
      : this.payload.sideCode;
    switch (queryType) {
      case SimpleQueryType.Basic:
        this.queryForm.get('occurence').disable();
        let payloadSimple = new SimpleTermQueryItemDto();

        payloadSimple.categoryId = this.payload.categoryId;
        payloadSimple.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_SIMPLE;
        payloadSimple.id = initial ? this.payload.id : 0;
        payloadSimple.internalId = this.payload.internalId;
        payloadSimple.operator = this.payload.operator;
        payloadSimple.parentInternalId = this.payload.parentInternalId;
        payloadSimple.queryId = this.payload.queryId;

        payloadSimple.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadSimple.sideCode = sideCode;
        payloadSimple.term = initial ? this.payload.term : this.formTerm;
        payloadSimple.operator = initial ? this.payload.operator : this.formOperator;

        this.payload = payloadSimple;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.SimpleTerm;
        break;
      case SimpleQueryType.Not:
        this.queryForm.get('occurence').disable();
        let payloadNot = new SimpleTermWithNotQueryItemDto();

        payloadNot.categoryId = this.payload.categoryId;
        payloadNot.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_SIMPLE_NOT;
        payloadNot.id = initial ? this.payload.id : 0;
        payloadNot.internalId = this.payload.internalId;
        payloadNot.operator = this.payload.operator;
        payloadNot.parentInternalId = this.payload.parentInternalId;
        payloadNot.queryId = this.payload.queryId;

        payloadNot.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadNot.sideCode = sideCode;
        payloadNot.term = initial ? this.payload.term : this.formTerm;
        payloadNot.operator = initial ? this.payload.operator : this.formOperator;

        this.payload = payloadNot;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.SimpleTermWithNot;
        break;
      case SimpleQueryType.Interval:
        this.queryForm.get('occurence').disable();
        if (initial == false) {
          this.queryForm.get('intervalType').patchValue(SimpleQueryIntervalType.Opening);
        }
        let payloadInterval = new SimpleTermWithRangeQueryItemDto();

        payloadInterval.categoryId = this.payload.categoryId;
        payloadInterval.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_SIMPLE_INTERVAL;
        payloadInterval.id = initial ? this.payload.id : 0;
        payloadInterval.internalId = this.payload.internalId;
        payloadInterval.operator = this.payload.operator;
        payloadInterval.parentInternalId = this.payload.parentInternalId;
        payloadInterval.queryId = this.payload.queryId;

        payloadInterval.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadInterval.sideCode = sideCode;
        payloadInterval.term = initial ? this.payload.term : this.formTerm;
        payloadInterval.operator = initial ? this.payload.operator : this.formOperator;
        payloadInterval.not = initial ? this.payload.not : this.formIsNotQuery;

        payloadInterval.startTime = initial ? this.payload.startTime : this.formStartTime;
        payloadInterval.endTime = initial ? this.payload.endTime : this.formEndTime;
        payloadInterval.rangeUnitId = this.formRangeUnitId;

        this.payload = payloadInterval;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.SimpleTermWithRange;
        break;
      case SimpleQueryType.Occurence:
        this.queryForm.get('occurence').enable();
        let payloadOccurence = new SimpleTermWithOccurenceQueryItemDto();

        payloadOccurence.categoryId = this.payload.categoryId;
        payloadOccurence.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_SIMPLE_OCCURENCE;
        payloadOccurence.id = initial ? this.payload.id : 0;
        payloadOccurence.internalId = this.payload.internalId;
        payloadOccurence.operator = this.payload.operator;
        payloadOccurence.parentInternalId = this.payload.parentInternalId;
        payloadOccurence.queryId = this.payload.queryId;

        payloadOccurence.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadOccurence.sideCode = sideCode;
        payloadOccurence.term = initial ? this.payload.term : this.formTerm;
        payloadOccurence.operator = initial ? this.payload.operator : this.formOperator;

        payloadOccurence.occurence = initial ? this.payload.occurence : this.formOccurence;

        this.payload = payloadOccurence;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.SimpleTermWithOccurence;
        break;
    }

    if (!initial) {
      this.node.isDirty = true;
    }
  }

  getOpeningIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
    } else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryFirstNWords);
    } else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideFirstQueryNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryFirstNWords);
    }
    return tooltip;
  }

  getClosingIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
    } else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryLastNWords);
    } else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryLastNWords);
    }
    return tooltip;
  }
}
