<span
  container="body"
  ngbTooltip="{{ 'Conversation::NoRecordsToExport' | abpLocalization }}"
  [disableTooltip]="!isDisabled"
>
  <button
    *ngIf="canExportConversation"
    [disabled]="isDisabled"
    class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
    (click)="export()"
  >
    <i class="far fa-file-export"></i>
    <span class="me-1">{{ 'Conversation::ConversationExport.Regular' | abpLocalization }}</span>
  </button>
</span>
